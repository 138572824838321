import useAsync from "../../useAsync";

import * as enrollmentApi from "../../../services/enrollmentApi";
import useToken from "../../useToken";

export default function useAdddress() {
  const token = useToken();

  const {
    data: address,
    loading: addressLoading,
    error: addressError,
    act: getAddress,
  } = useAsync((data) => enrollmentApi.getAddress(data, token), false);

  return {
    address,
    addressLoading,
    addressError,
    getAddress,
  };
}
