import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;

  .submit,
  .cancel {
    position: absolute;
    bottom: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit {
    right: 0;
    width: 220px;
  }

  .cancel {
    right: 230px;
    width: 200px;
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  .infinite-scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    margin-top: 40px;

    .submit,
    .cancel {
      width: calc(50% - 5px);
    }

    .cancel {
      left: 0;
    }
  }
`;

export const Edit = styled.button`
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  font-size: 16px;
  position: absolute;
  background-color: transparent;
  border: none;
  right: 10%;
  top: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const Form = styled.form`
  min-width: 400px;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;

  .reports {
    flex-direction: column !important;
  }

  .request-data {
    flex-direction: row !important;
    display: flex;
  }

  .request-data > div {
    width: 100%;

    & > span {
      position: relative;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column !important;
    min-width: 0;
    width: 100%;
    margin-top: 0;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;

  & > span {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    height: 108px;
  }

  span {
    width: 100%;
    flex-direction: column;
    margin-left: 10px;
  }

  span:first-child {
    margin-left: 0;
  }

  .textarea {
    height: 300px;
  }

  @media (max-width: 767px) {
    & > span {
      height: 94px;
    }
  }
`;

export const FormsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  & > div {
    width: 100%;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 1px !important;
  height: inherit;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 0 50px;

  @media (max-width: 767px) {
    width: 100% !important;
    height: 1px;
    margin: 30px 0;
  }
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 50px 0;

  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const ServiceListItem = styled.div`
  svg {
    font-size: 22px;
    margin-right: 10px;

    &:hover {
      font-size: 24px;
      margin-right: 9px;
    }
  }

  img {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50%;
    margin-right: 10px;
  }
`;
