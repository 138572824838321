import { TailSpin } from "react-loader-spinner";

import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function LoadingMessage({ loading, message }) {
  return (
    <Container loading={loading} theme={theme}>
      <h5>{message}</h5>

      <TailSpin
        height="80"
        width="80"
        color={theme.COLORS.ORANGE}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass=""
        visible={true}
      />
    </Container>
  );
}
