import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useScheduleInspection() {
  const token = useToken();

  const {
    loading: scheduleInspectionLoading,
    error: scheduleInspectionError,
    act: scheduleInspection,
  } = useAsync((data) => inspectionApi.scheduleInspection(data, token), false);

  return {
    scheduleInspectionLoading,
    scheduleInspectionError,
    scheduleInspection,
  };
}
