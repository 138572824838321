import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function useSignOut() {
  const {
    loading: signOutLoading,
    error: signOutError,
    act: signOut,
  } = useAsync((data) => authenticationApi.signOut(data), false);

  return {
    signOutLoading,
    signOutError,
    signOut,
  };
}
