import { Outlet } from "react-router-dom";
import React from "react";

import { Container } from "../styles";
import theme from "../../../styles/theme";

export default function SignUp() {
  return (
    <Container theme={theme}>
      <Outlet />
    </Container>
  );
}
