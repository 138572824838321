import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BiFilter } from "react-icons/bi";
import { CgReorder } from "react-icons/cg";
import { IoChevronForward } from "react-icons/io5";

import { Section } from "../../../components/Section";
import { Container, Directory } from "../styles";
import { ParamsWrapper } from "./styles";
import theme from "../../../styles/theme";
import useUsersData from "../../../hooks/api/user/useUsersData";
import toastUtils from "../../../utils/toast-utils";
import noPicProfile from "../../../assets/no-pic-profile.png";
import enrollmentUtils from "../../../utils/enrollment-utils";
import ListWrapper from "../../../components/ListWrapper";
import Loader from "../../../components/Loader";
import useEnrollmentFilters from "../../../hooks/api/enrollment/useEnrollmentFilters";
import Select from "../../../components/forms/enrollment-form/Select";
import FilterSelector from "../../../components/FilterSelector";

export default function Profiles() {
  const { getUsersData, usersDataLoading } = useUsersData();
  const { getEnrollmentFilters } = useEnrollmentFilters();
  const responseLength = 40;
  const [profiles, setProfiles] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const search = useLocation().search.replace("?search=", "");
  const [filters, setFilters] = useState(enrollmentUtils.userEmptyFilters);
  const [displayParams, setDisplayParams] = useState(
    enrollmentUtils.userEmptyDisplayParams
  );
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadProfiles();
    loadFilters();
  }, []);

  useEffect(() => {
    orderProfiles();
  }, [displayParams.order]);

  useEffect(() => {
    handleFilter();
    handleProfileSearch();
  }, [
    search,
    profiles,
    displayParams.role,
    displayParams.department,
    displayParams.permission,
  ]);

  async function loadFilters() {
    try {
      const response = await getEnrollmentFilters();

      setFilters({
        ...filters,
        departments: filters.departments.concat(response.departments),
        roles: filters.roles.concat(response.roles),
        permissions: filters.permissions.concat(response.permissions),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadProfiles() {
    let skip = profiles.length;
    let take = responseLength;

    try {
      const response = await getUsersData({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
      });

      if (response) {
        setProfiles(profiles.concat(response));
        setFilteredList(profiles.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderProfiles() {
    const skip = 0;
    const take = responseLength;

    try {
      const response = await getUsersData({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
      });

      if (response) {
        setProfiles(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleProfileSearch() {
    if (!search) return handleFilter();
    const result = filteredList.filter((user) =>
      user.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredList(result);
  }

  function handleFilter() {
    let result = profiles;

    if (displayParams.role.value)
      result = result.filter(
        (user) => user.enrollment?.role === displayParams.role.value
      );

    if (displayParams.department.value)
      result = result.filter(
        (user) => user.enrollment?.department === displayParams.department.value
      );

    if (displayParams.permission.value)
      result = result.filter(
        (user) => user.enrollment?.permission === displayParams.permission.value
      );

    setFilteredList(result);
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  return (
    <Section title="Cadastro/Usuários">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <Select
            id="order"
            icon={CgReorder}
            placeholder={"Ordenar por..."}
            enabled={true}
            list={enrollmentUtils.userOrderList}
            selected={displayParams.order}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
          />

          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="department"
            icon={BiFilter}
            placeholder={"Setor"}
            enabled={true}
            list={filters.departments}
            selected={displayParams.department}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="role"
            icon={BiFilter}
            placeholder={"Função"}
            enabled={true}
            list={filters.roles}
            selected={displayParams.role}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="permission"
            icon={BiFilter}
            placeholder={"Nível de Acesso"}
            enabled={true}
            list={filters.permissions}
            selected={displayParams.permission}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        {usersDataLoading ? (
          <Loader />
        ) : (
          <ListWrapper
            offset={responseLength}
            list={profiles}
            loadList={loadProfiles}
            loading={usersDataLoading}
          >
            {filteredList.map((user) => (
              <UserWrapper
                id={user.id}
                name={user.name}
                avatar={user.avatar}
                key={user.id}
              />
            ))}
          </ListWrapper>
        )}
      </Container>
    </Section>
  );
}

function UserWrapper({ id, name, avatar }) {
  const navigate = useNavigate();

  return (
    <Directory theme={theme} onClick={() => navigate(`${id}`)}>
      <div>
        <img src={avatar ? avatar : noPicProfile} alt="Foto do usuário" />

        <h5>{name}</h5>

        <IoChevronForward />
      </div>
    </Directory>
  );
}
