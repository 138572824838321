import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useDeleteDailyReportDetail() {
  const token = useToken();

  const {
    loading: deleteDailyReportDetailLoading,
    error: deleteDailyReportDetailError,
    act: deleteDailyReportDetail,
  } = useAsync((data) => reportApi.deleteDailyReportDetail(data, token), false);

  return {
    deleteDailyReportDetailLoading,
    deleteDailyReportDetailError,
    deleteDailyReportDetail,
  };
}
