import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { Container } from "./styles";
import theme from "../../styles/theme";
import generalUtils from "../../utils/general-utils";

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

export default function DateRangePicker({
  icon: Icon,
  form,
  setForm,
  ...rest
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateHashtable = generalUtils.generateDateHashtable(
    rest.markedDates || []
  );

  useEffect(() => {
    setForm({
      ...form,
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  function handleSelect(dates) {
    const [start, end] = dates;
    const selectedDates = generalUtils.getDatesInterval(start, end);

    setStartDate(start);
    setEndDate(end);

    if (selectedDates.find((date) => dateHashtable[date.getTime()]))
      rest.setDateConflictWarning(true);
  }

  return (
    <Container theme={theme} type={rest.type} disabled={rest.disabled}>
      {Icon && <Icon size={20} />}
      <DatePicker
        selected={startDate}
        onChange={handleSelect}
        showMonthDropdown
        showYearDropdown
        timeInputLabel="Hora Precisa:"
        timeCaption="Hora"
        dateFormat={"dd/MM/yyyy"}
        className="calendar"
        id="date"
        placeholderText="Selecione as datas..."
        minDate={rest.minDate}
        highlightDates={rest.markedDates}
        locale="pt-BR"
        {...rest}
      />
    </Container>
  );
}
