import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionHistory() {
  const token = useToken();

  const {
    data: inspectionHistory,
    loading: inspectionHistoryLoading,
    error: inspectionHistoryError,
    act: getInspectionHistory,
  } = useAsync(
    (data) => inspectionApi.getInspectionHistory(data, token),
    false
  );

  return {
    inspectionHistory,
    inspectionHistoryLoading,
    inspectionHistoryError,
    getInspectionHistory,
  };
}
