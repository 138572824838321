import { MdOutlineClass } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";
import { useState } from "react";

import theme from "../../../../styles/theme";
import { CloseButton, Container, Wrapper } from "./styles";
import Input from "../Input";
import Button from "../Button";
import toastUtils from "../../../../utils/toast-utils";

export default function AddType({
  contacts,
  setContacts,
  types,
  setTypes,
  addingType,
  setAddingType,
}) {
  const [form, setForm] = useState({
    type: "",
  });

  function handleSubmit(e) {
    e.preventDefault();

    if (types.find((type) => type === form.type)) {
      toastUtils.toaster({
        message: "Este tipo de contato já existe!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }

    const newType = { label: form.type, value: 0 };

    setTypes([...types, newType]);
    setContacts({ ...contacts, type: newType });
    setAddingType(false);
    setForm({
      type: "",
    });

    toastUtils.toaster({
      message: "Função criada com sucesso!",
      type: toastUtils.type.success,
      position: toastUtils.position.topCenter,
      theme: toastUtils.theme.colored,
    });
  }

  return (
    <Container theme={theme} addingType={addingType}>
      <Wrapper theme={theme}>
        <CloseButton
          theme={theme}
          onClick={() => {
            setForm({ ...form, type: "" });
            setAddingType(false);
          }}
        >
          <IoCloseCircle />
        </CloseButton>

        <h5>Criar Tipo de Contato</h5>

        <form onSubmit={handleSubmit}>
          <Input
            placeholder="Novo Tipo"
            type="text"
            id="type"
            value={form.type}
            required
            icon={MdOutlineClass}
            pattern="^[A-ZÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÇ\s]+$"
            onChange={(e) =>
              setForm({
                ...form,
                type: e.target.value.toUpperCase(),
              })
            }
          />
          <Button title="Adicionar" editing={true} disabled={!form.type} />
        </form>
      </Wrapper>
    </Container>
  );
}
