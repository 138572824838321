import { Link, useLocation, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useState } from "react";

import { Background } from "../styles";

import theme from "../../../styles/theme";
import { Button } from "../../../components/forms/authentication-form/Button";
import { Form } from "../styles";
import ChangeEmail from "../../../components/forms/authentication-form/ChangeEmail";
import useConfirmationEmail from "../../../hooks/api/authentication/useConfirmationEmail";
import toastUtils from "../../../utils/toast-utils";
import Title from "../../../components/forms/authentication-form/Title";

export default function ConfirmationMessage() {
  const { userId } = useParams();
  const location = useLocation();
  const { getConfirmationEmail } = useConfirmationEmail();
  const [editingEmail, setEditingEmail] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(
    new URLSearchParams(location.search).get("email")
  );

  useEffect(() => {
    sendConfirmationLink();
  }, []);

  async function sendConfirmationLink(e) {
    if (e) e.preventDefault();

    try {
      await getConfirmationEmail(userId);

      toastUtils.toaster({
        message: "E-mail de confirmação enviado!",
        type: toastUtils.type.success,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function editEmail(e) {
    e.preventDefault();
    setEditingEmail(true);
  }

  function exitEditEmail() {
    if (editingEmail) setEditingEmail(false);
    return;
  }

  return (
    <>
      <Form theme={theme} onClick={exitEditEmail}>
        <Title />

        <h2> Confirmação de email pendente </h2>

        <p>Um link de confirmação foi enviado para o email cadastrado: </p>
        <h5>{currentEmail}</h5>
        <p>
          Não recebeu? Clique no botão abaixo para reenviar o email de
          confirmação. Não esqueça de checar sua caixa de span.{" "}
        </p>

        <span>
          <Button title="Reenviar E-mail" onClick={sendConfirmationLink} />
          <Button title="Editar E-mail" onClick={editEmail} />
        </span>

        <Link to="/">Voltar para login</Link>
      </Form>

      <ChangeEmail
        editingEmail={editingEmail}
        setEditingEmail={setEditingEmail}
        setCurrentEmail={setCurrentEmail}
      />

      <Background theme={theme} onClick={exitEditEmail} />
    </>
  );
}
