import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { Section } from "../../../components/Section";
import { Container } from "../styles";
import theme from "../../../styles/theme";
import UserContext from "../../../contexts/UserContext";
import useCompanyTypes from "../../../hooks/api/company/useCompanyTypes";
import toastUtils from "../../../utils/toast-utils";
import generalUtils from "../../../utils/general-utils";
import Directories from "../../../components/Directories";

export default function Enrollment() {
  const search = useLocation().search.replace("?search=", "");
  const { userData } = useContext(UserContext);
  const { getCompanyTypes } = useCompanyTypes();
  const [companyTypes, setCompanyTypes] = useState({});
  const [directories, setDirectories] = useState([]);
  const [dirList, setDirList] = useState([]);

  useEffect(() => {
    loadCompanyTypes();
  }, []);

  useEffect(() => {
    const list = [
      { text: "CLIENTES", route: `clientes/${companyTypes["CLIENTE"]}` },
      {
        text: "FORNECEDORES",
        route: `fornecedores/${companyTypes["FORNECEDOR"]}`,
      },
      { text: "EMBARCAÇÕES", route: "embarcacao" },
      { text: "EQUIPAMENTOS", route: "equipamento" },
      { text: "PRODUTOS", route: "produto" },
    ];

    if (
      userData.permission.label === "MASTER" ||
      userData.permission.label === "APROVADOR"
    )
      list.push({
        text: "USUÁRIOS",
        route: "perfil",
      });

    setDirectories(list);
    setDirList(list);
  }, [companyTypes]);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  async function loadCompanyTypes() {
    try {
      const response = await getCompanyTypes();

      if (response) setCompanyTypes(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title="Cadastro">
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
