import { useNavigate } from "react-router-dom";

import { Directory } from "../../pages/Home/styles";
import theme from "../../styles/theme";
import { Container } from "./styles";

export default function Directories({ dirList }) {
  const navigate = useNavigate();

  return (
    <Container>
      {dirList.map((item, index) => (
        <Directory
          key={index}
          theme={theme}
          onClick={() => navigate(item.route)}
        >
          {item.text}
        </Directory>
      ))}
    </Container>
  );
}
