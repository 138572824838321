import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toastUtils from "../../../../utils/toast-utils";
import { Section } from "../../../../components/Section";
import Loader from "../../../../components/Loader";
import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function PdfViewer() {
  const location = useLocation();
  const { fileSource } = location.state || { fileSource: "" };
  const title = "Relatórios/Diário de Operações/Visualizar PDF";
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setFile(fileSource);

      setLoading(false);
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível localizar o arquivo!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }, []);

  return (
    <Section title={title}>
      {loading ? (
        <Loader theme={theme} />
      ) : (
        <Container>
          <iframe src={file}>
            Seu navegador não suporta visualização de PDF.
          </iframe>
        </Container>
      )}
    </Section>
  );
}
