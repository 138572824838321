import api from "./api";

export async function postJasperDailyReport(body, token) {
  const response = await api.post("/jasper/rdo", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
