import { useContext } from "react";

import { Section } from "../../components/Section";
import ServiceList from "./Services/ServiceList";
import UserContext from "../../contexts/UserContext";

export default function Commercial() {
  const types = "1";
  const { userData } = useContext(UserContext);
  const allowed =
    userData.department.label === "COMERCIAL" ||
    userData.department.label === "DIRETORIA";

  return (
    <Section title="Comercial">
      <ServiceList allowed={allowed} types={types} />
    </Section>
  );
}
