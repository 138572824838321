import useAsync from "../../useAsync";

import * as companyApi from "../../../services/companyApi";
import useToken from "../../useToken";

export default function useReceitaws() {
  const token = useToken();

  const {
    data: receitaws,
    loading: receitawsLoading,
    error: receitawsError,
    act: getReceitaws,
  } = useAsync((data) => companyApi.getReceitaws(data, token), false);

  return {
    receitaws,
    receitawsLoading,
    receitawsError,
    getReceitaws,
  };
}
