import useAsync from "../../useAsync";

import * as companyApi from "../../../services/companyApi";
import useToken from "../../useToken";

export default function useDeleteCredential() {
  const token = useToken();

  const {
    loading: deleteCredentialLoading,
    error: deleteCredentialError,
    act: deleteCredential,
  } = useAsync((data) => companyApi.deleteCredential(data, token), false);

  return {
    deleteCredentialLoading,
    deleteCredentialError,
    deleteCredential,
  };
}
