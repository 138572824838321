import { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import { GoDotFill } from "react-icons/go";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Container, ItemWrapper, Users } from "./styles";

import useInspectorInspections from "../../../../hooks/api/inspection/useInspectorInspections";
import toastUtils from "../../../../utils/toast-utils";
import noPicProfile from "../../../../assets/no-pic-profile.png";
import theme from "../../../../styles/theme";

export default function InspectorTimeline({ inspectorId }) {
  const [items, setItems] = useState([]);
  const { getInspectorInspections } = useInspectorInspections();

  const timelineTheme = {
    primary: theme.COLORS.GRAY_100,
    secondary: theme.COLORS.ORANGE,
    cardBgColor: theme.COLORS.BACKGROUND_700,
    titleColor: theme.COLORS.GRAY_100,
    titleColorActive: theme.COLORS.BACKGROUND_700,
  };

  useEffect(() => {
    loadItems();
  }, []);

  async function loadItems() {
    try {
      const response = await getInspectorInspections(inspectorId);

      if (response) {
        const timelineItems = response.map((inspection, index) => ({
          title: dayjs(inspection.scheduledFor)
            .locale("pt-br")
            .format("dddd, DD/MM/YYYY (HH:mm)"),
          timelineContent: <TimelineItem item={inspection} key={index} />,
        }));

        setItems(timelineItems);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <h3>Vistorias Agendadas</h3>
      {items.length === 0 ? (
        <h5>Este usuário não possui vistorias agendadas no momento.</h5>
      ) : (
        <Chrono
          items={items}
          mode="VERTICAL"
          theme={timelineTheme}
          disableToolbar
          allowDynamicUpdate={true}
          scrollable={{ scrollbar: false }}
          disableClickOnCircle
          activeItemIndex={0}
        />
      )}
    </Container>
  );
}

function TimelineItem({ item }) {
  const startDate = dayjs(item.startDate).locale("pt-br").format("DD/MM/YYYY");
  const endDate = dayjs(item.endDate).locale("pt-br").format("DD/MM/YYYY");

  return (
    <>
      <ItemWrapper theme={theme} selected={item.selected}>
        <h1>{`${item.vessel} (${item.company})`}</h1>

        <h2>Status:</h2>

        <span className="li">
          <GoDotFill />
          <p>{item.status}</p>
        </span>

        <h2>Serviços Incluídos:</h2>

        {item.products.map((product, index) => (
          <span className="li" key={index}>
            <GoDotFill />
            <p>{product}</p>
          </span>
        ))}

        <h2>Dia(s) Reservado(s):</h2>

        <span className="li">
          <GoDotFill />
          <p>
            {startDate === endDate
              ? `${startDate}`
              : `${startDate} a ${endDate}`}
          </p>
        </span>

        <h2>Responsáveis:</h2>

        <Users>
          <div>
            <img src={item.coordinator.avatar || noPicProfile} alt="usuário" />

            <p>{item.coordinator.name}</p>

            <h3>Coordenador</h3>
          </div>

          <div>
            <img src={item.inspector.avatar || noPicProfile} alt="usuário" />

            <p>{item.inspector.name}</p>

            <h3>Vistoriador</h3>
          </div>
        </Users>
      </ItemWrapper>
    </>
  );
}
