import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 75px);
  width: 400px;
  min-height: 200px;
  padding: 5px;
  background-color: ${({ theme, color }) => theme.COLORS[color]};
  color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  border-radius: 10px;
  border-left: 10px solid ${({ theme }) => theme.COLORS.GRAY_100};
  box-shadow: 5px 5px 10px rgba(0, 0, 0.5, 0.5);
  z-index: 6;

  .buttons {
    display: flex;
    justify-content: end;
  }

  .buttons button {
    width: 30px;
    height: 30px;
    background-color: ${({ theme, color }) => theme.COLORS[color]};
    color: ${({ theme }) => theme.COLORS.WHITE};
    cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
    border: 0;
    margin-left: 5px;
    border-radius: 50%;
    font-size: 20px;
    justify-content: center;
    align-items: center;

    &:disabled {
      opacity: 0.5 !important;
      cursor: default;
    }

    &:hover {
      background-color: ${({ theme, disabled }) =>
        theme.COLORS.GRAY_100 + disabled ? "FF" : "50"};
    }

    &:last-child:hover {
      background-color: ${({ theme }) => theme.COLORS.GRAY_100 + "50"};
    }

    svg {
      width: 80%;
      height: 80%;
      margin: 10%;
    }
  }

  .title,
  .extra {
    color: ${({ theme }) => theme.COLORS.WHITE};
    display: flex;
    margin: 10px;
    padding-right: 35px;

    p {
      margin: 0;
      font-size: 14px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }

  .title {
    margin-bottom: 30px;
    width: 360px;

    h3 {
      margin: 0 0 10px 0;
      font-size: 20px;
    }

    svg {
      margin-top: 2px;
    }

    .details {
      width: 100%;
    }
  }

  .extra {
    flex-direction: column;
  }

  .extra > span {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .guests > svg {
    margin-top: 5px;
  }

  .guest {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 50px;
    flex-wrap: nowrap;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    img,
    svg {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.COLORS.WHITE};
    }

    svg {
      cursor: pointer;
    }

    p {
      font-size: 10px;
      text-align: center;
      margin-top: 5px;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .date {
    display: flex;
    align-items: flex-end;

    p {
      margin: 0 10px;
    }
  }

  .add-guest svg {
    margin-right: 0;
  }

  .cancel-save {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 5px;

    #cancel {
      margin-right: 10px;
      background-color: transparent;
      color: ${({ theme }) => theme.COLORS.WHITE};
      box-shadow: none;
    }
  }

  .allDay {
    margin: 10px 5px 10px 0;
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      -webkit-appearance: none; /* Remove a aparência padrão do checkbox no Safari/Chrome */
      -moz-appearance: none; /* Remove a aparência padrão do checkbox no Firefox */
      appearance: none; /* Remove a aparência padrão do checkbox em outros navegadores */
      margin: 0 5px 0 0;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      border: 2px solid ${({ theme }) => theme.COLORS.WHITE};
      background-color: ${({ theme, color }) => theme.COLORS[color]};
      position: relative;
    }

    input[type="checkbox"]:checked {
      background-color: ${({ theme }) => theme.COLORS.WHITE};
    }

    input[type="checkbox"]:checked::before {
      content: "✓"; /* Símbolo de check */
      position: absolute;
      left: 50%;
      top: calc(50% - 1px);
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: 700;
      color: ${({ theme, color }) => theme.COLORS[color]};
    }

    label {
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }
`;

export const Button = styled.input`
  width: 80px;
  height: 26px;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  color: ${({ theme, color }) => theme.COLORS[color]};
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  border: 0;
  padding: 0 10px;
  margin: 16px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  justify-content: center;
  margin: 5px 0;

  &:disabled {
    opacity: 0.5 !important;
  }

  &:hover {
    opacity: 80%;
  }
`;
