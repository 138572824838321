import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    min-height: 30px;
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_600};

    span {
      font-size: 15px;
      align-items: center;
    }
  }

  @media (max-width: 1023px) {
    .title {
      span {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    .title {
      span {
        font-size: 12px;
      }
    }
  }
`;

export const Row = styled.span`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  border-top: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};
  color: ${({ theme }) => theme.COLORS.WHITE};

  span {
    display: flex;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
    justify-content: center;
    padding: 5px;
    font-size: 14px;
    flex-wrap: wrap;
    word-break: break-word;

    &:first-child {
      border-left: none;
    }
  }

  .index {
    width: 4%;
    justify-content: left;
    padding: 2px;
  }

  .description {
    width: 64%;
    justify-content: left;
    align-items: baseline;
    position: relative;

    p {
      font-size: 14px;
      margin: 0;
      max-width: 94%;
    }

    span {
      flex-wrap: nowrap;
      width: ${({ enabled }) => (enabled ? "90%" : "100%")};
      padding-left: ${({ isSubitem }) => (isSubitem ? "20px" : "5")};
      margin-top: 7px;
      justify-content: space-between;
    }

    svg {
      margin-left: 10px;
      margin-top: 2px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }

  .reference {
    width: 18%;
    text-align: center;
  }

  .check-box {
    width: 6%;
  }

  .status {
    width: 14%;
    padding: 0;
  }

  .add-item {
    align-items: center;

    cursor: pointer;

    svg {
      margin: 0 5px 0 0;
    }

    p {
      margin: 0;
      font-weight: 700;
    }
  }

  @media (max-width: 1023px) {
    .description {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    min-height: 52px;

    span {
      font-size: 12px;
    }

    .reference {
      font-size: 10px;
      width: 16%;
      min-width: 16%;
      max-width: 16%;
    }

    .description {
      font-size: 13px;

      span {
        flex-wrap: nowrap;
      }
    }

    .status {
      width: 16%;
      min-width: 16%;
      max-width: 16%;
      padding: 0;
    }

    .index {
      width: 8%;
      min-width: 8%;
      max-width: 8%;
      padding: 2px;
      justify-content: center;
      font-size: 11px;
    }
  }
`;
