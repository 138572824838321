import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.COLORS.WHITE};

  input {
    width: 100%;
    text-overflow: ellipsis;
    margin: 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background: transparent;
    border: 0;
    font-size: 14px;
    border-bottom: ${({ disabled }) => (disabled ? "0" : "1px")} solid
      ${({ theme, error }) => (error ? theme.COLORS.RED : theme.COLORS.WHITE)};

    &:placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_300};
    }

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &:autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 0 ${({ theme }) => theme.COLORS.BACKGROUND_900}
        inset;
      -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0;
    }
  }

  #title {
    margin: 0 0 10px 0;
    font-size: 20px;
  }

  /* @media (max-width: 767px) {
    margin-bottom: 10px;

    input {
      height: 46px;
      padding: 12px;
      font-size: 14px;
    }
  } */
`;
