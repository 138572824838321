import ServiceList from "./ServiceList";
import { Section } from "../../../components/Section";

export default function AuthorizedServices() {
  const types = "2,3";
  const allowed = false;

  return (
    <Section title="Serviços/Serviços Autorizados">
      <ServiceList allowed={allowed} types={types} />
    </Section>
  );
}
