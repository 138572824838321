import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RxIdCard } from "react-icons/rx";
import { MdOutlineFactCheck } from "react-icons/md";
import {
  BsBriefcase,
  BsCalendarEvent,
  BsClockHistory,
  BsHouse,
} from "react-icons/bs";
import { GoWorkflow } from "react-icons/go";
import { FiUser } from "react-icons/fi";
import { RiGraduationCapLine } from "react-icons/ri";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { LuText } from "react-icons/lu";
import { IoAddCircleSharp } from "react-icons/io5";
import { AiFillMinusCircle } from "react-icons/ai";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Section } from "../../../../components/Section";
import {
  AddSubitem,
  Container,
  Divider,
  Edit,
  Form,
  FormsWrapper,
  Row,
} from "../styles";
import theme from "../../../../styles/theme";
import TextSelect from "../../../../components/forms/report-form/TextSelect";
import toastUtils from "../../../../utils/toast-utils";
import reportUtils from "../../../../utils/report-utils";
import useInspectionsByResponsibles from "../../../../hooks/api/inspection/useInspectionsByResponsibles";
import Label from "../../../../components/forms/report-form/Label";
import Input from "../../../../components/forms/report-form/Input";
import useInspectionData from "../../../../hooks/api/inspection/useInspectionData";
import DateTimePicker from "../../../../components/DateTimePicker";
import UserContext from "../../../../contexts/UserContext";
import Textarea from "../../../../components/forms/report-form/Textarea";
import TimePicker from "../../../../components/TimePicker";
import Button from "../../../../components/forms/report-form/Button";
import Select from "../../../../components/forms/inspection-form/Select";
import MultiSelect from "../../../../components/forms/report-form/MultiSelect";
import { ActivityWrapper } from "./styles";
import DeleteItem from "../../../../components/forms/report-form/DeleteItem";
import useDeleteDailyReportDetail from "../../../../hooks/api/report/useDeleteDailyReportDetail";
import useDailyReport from "../../../../hooks/api/report/useDailyReport";
import useSaveDailyReport from "../../../../hooks/api/report/useSaveDailyReport";
import Loader from "../../../../components/Loader";
import DownloadButton from "../../../../components/forms/report-form/DonwloadButton";
import useJasperDailyReport from "../../../../hooks/api/jasper/useJasperDailyReport";

export default function DailyReport() {
  const { reportId } = useParams();
  const newReport = isNaN(reportId);
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const { getInspectionsByResponsibles } = useInspectionsByResponsibles();
  const { getInspectionData, inspectionDataLoading } = useInspectionData();
  const { getDailyReport, dailyReportLoading } = useDailyReport();
  const { saveDailyReport, saveDailyReportLoading } = useSaveDailyReport();
  const { saveJasperDailyReport, jasperDailyReportLoading } =
    useJasperDailyReport();
  const [title, setTitle] = useState("Relatórios/Diário de Operações");
  const [editing, setEditing] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [form, setForm] = useState(reportUtils.emptyDailyReportForm);
  const [report, setReport] = useState({});
  const [inspections, setInspections] = useState(reportUtils.emptyList);
  const [services, setServices] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [activities, setActivities] = useState([reportUtils.emptyActivity]);

  useEffect(() => {
    loadReportData();
  }, []);

  useEffect(() => {
    if (form.inspection.value) loadInspectionData();
  }, [form.inspection.value]);

  async function loadReportData() {
    try {
      loadInspections();

      if (newReport) {
        setTitle(`${title}/Novo Relatório`);
        setEditing(true);
        return;
      }

      const response = await getDailyReport(reportId);

      if (response) {
        const name = `${response.form.vessel.label}(${
          response.form.company.label
        }) - ${dayjs(response.form.date).format("DD-MM-YYYY")}`;

        if (
          userData.permission.label === "MASTER" ||
          userData.enrollmentId === response.form.user.value
        )
          setAllowed(true);

        const formData = {
          ...response.form,
          date: new Date(response.form.date),
          additionalInfoStart: response.form.additionalInfoStart
            ? new Date(response.form.additionalInfoStart)
            : null,
          additionalInfoEnd: response.form.additionalInfoEnd
            ? new Date(response.form.additionalInfoEnd)
            : null,
        };

        const activitiesData = response.activities.map((activity) => ({
          ...activity,
          activityStart: new Date(activity.activityStart),
          activityEnd: new Date(activity.activityEnd),
        }));

        setReport(formData);
        setForm(formData);
        setActivities(activitiesData);
        setTitle(`${title.replace("/Novo Cadastro", "")}/${name}`);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadInspectionData() {
    try {
      const response = await getInspectionData(form.inspection.value);

      if (response) {
        setServices(
          response.inProgressInspections.map((item) => ({
            value: item.id,
            label: `${item.report.name} (${item.report.formObs})`,
            acronym: item.report.formObs,
            inspectorId: item.inspector.id,
          }))
        );

        if (newReport)
          setForm({
            ...form,
            company: {
              label: response.company.name,
              value: response.company.id,
            },
            vessel: {
              label: response.vessel.name,
              value: response.vessel.id,
            },
            role: userData.role,
            user: { label: userData.fullName, value: userData.id },
            requestId: response.request.value,
            requestStatus: response.requestStatus,
            coordinatorId: response.coordinator.id,
          });
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadInspections() {
    try {
      const response = await getInspectionsByResponsibles();

      if (response) setInspections(reportUtils.emptyList.concat(response));
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadPDF() {
    const body = {
      ID: Number(reportId),
      DTATUAL: dayjs(form.date).format("DD/MM/YYYY"),
      ESCOPO: form.scope,
    };

    try {
      const response = await saveJasperDailyReport(body);

      navigate(`/painel/relatorios/pdf-preview/${response.fileName}`, {
        state: { fileSource: response.fileSource },
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);

    try {
      const body = {
        reportId: newReport ? 0 : Number(reportId),
        inspectionId: form.inspection.value,
        requestId: form.requestId,
        requestStatus: form.requestStatus,
        companyId: form.company.value,
        vesselId: form.vessel.value,
        coordinatorId: form.coordinatorId,
        location: form.location,
        date: form.date,
        scope: form.scope,
        order: form.order,
        qualification: form.qualification,
        additionalInfo: form.additionalInfo,
        additionalInfoStart: form.additionalInfoStart,
        additionalInfoEnd: form.additionalInfoEnd,
        obs: form.obs,
        activities: activities.map((activity) => ({
          description: activity.description,
          activityStart: activity.activityStart,
          activityEnd: activity.activityEnd,
          status: activity.status.value,
          services: activity.services.map((service) => ({
            detailId: service.value,
            inspectorId: service.inspectorId,
          })),
        })),
      };

      const response = await saveDailyReport(body);

      const name = `${form.vessel.label}(${form.company.label}) - ${dayjs(
        response.date
      ).format("DD-MM-YYYY")}`;

      setEditing(false);
      setTitle(
        `${title
          .replace("/Novo Relatório", "")
          .replace(`/${name}`, "")}/${name}`
      );
      navigate(`/painel/relatorios/rdo/${response.reportId}`);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        {dailyReportLoading ? (
          <Loader />
        ) : (
          <FormsWrapper>
            {!newReport && (
              <>
                <DownloadButton
                  loading={jasperDailyReportLoading}
                  action={loadPDF}
                />

                <Edit
                  theme={theme}
                  onClick={(e) => {
                    e.preventDefault();

                    if (editing) setForm(report);
                    else if (!allowed) {
                      toastUtils.toaster({
                        message:
                          "Você não tem permissão para editar os dados deste relatório",
                        type: toastUtils.type.error,
                        position: toastUtils.position.topCenter,
                        theme: toastUtils.theme.colored,
                      });

                      return;
                    }

                    setEditing(!editing);
                  }}
                >
                  {editing ? "Cancelar" : "Editar"}
                </Edit>
              </>
            )}

            <Form className="dailyReportForm" onSubmit={handleSubmit}>
              <div>
                <h3>Vistoria</h3>

                <Row>
                  <span className="inspection no-label">
                    <TextSelect
                      id="inspection"
                      placeholder="Selecione..."
                      icon={MdOutlineFactCheck}
                      enabled={newReport}
                      list={inspections}
                      selected={form.inspection}
                      required
                      form={form}
                      setForm={setForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>
              </div>

              <Divider theme={theme} />

              {form.inspection.value !== 0 && !inspectionDataLoading && (
                <>
                  <div>
                    <h3>Detalhes</h3>

                    <Row>
                      <span className="company">
                        <Label id="company" text="Cliente" />
                        <Input
                          id="company"
                          placeholder="Cliente"
                          type="text"
                          icon={RxIdCard}
                          disabled
                          value={form.company.label}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>

                      <span className="order">
                        <Label id="order" text="Ordem de Compra" />
                        <Input
                          id="order"
                          placeholder="Ordem de Compra"
                          type="text"
                          icon={RxIdCard}
                          disabled={!editing}
                          value={form.order}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>
                    </Row>

                    <Row>
                      <span className="location">
                        <Label id="location" text="Local de Trabalho*" />
                        <Input
                          id="location"
                          placeholder="Local de Trabalho"
                          type="text"
                          icon={BsHouse}
                          disabled={!editing}
                          required
                          submitted={submitted}
                          value={form.location}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>

                      <span className="date">
                        <Label id="date" text="Data*" />
                        <DateTimePicker
                          id="date"
                          type="date"
                          icon={BsCalendarEvent}
                          disabled={!editing}
                          required
                          submitted={submitted}
                          maxDate={new Date()}
                          value={
                            form.date
                              ? dayjs(form.date).format("DD/MM/YYYY")
                              : null
                          }
                          form={form}
                          setForm={setForm}
                        />
                      </span>
                    </Row>

                    <Row>
                      <span className="scope">
                        <Label
                          id="scope"
                          text="Escopo Principal de Trabalho*"
                        />
                        <Input
                          id="scope"
                          placeholder="Escopo Principal de Trabalho"
                          type="text"
                          icon={GoWorkflow}
                          disabled={!editing}
                          required
                          submitted={submitted}
                          value={form.scope}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>
                    </Row>
                  </div>

                  <Divider theme={theme} />

                  <div>
                    <h3>Responsável</h3>

                    <Row>
                      <span className="user">
                        <Label id="user" text="Colaborador" />
                        <Input
                          id="user"
                          placeholder="Colaborador"
                          type="text"
                          icon={FiUser}
                          disabled
                          required
                          submitted={submitted}
                          value={form.user.label}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>
                    </Row>

                    <Row>
                      <span className="role">
                        <Label id="role" text="Função" />
                        <Input
                          id="role"
                          placeholder="Função"
                          type="text"
                          icon={BsBriefcase}
                          disabled
                          required
                          submitted={submitted}
                          value={form.role.label}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>

                      <span className="qualification">
                        <Label id="qualification" text="Qualificação*" />
                        <Input
                          id="qualification"
                          placeholder="Qualificação"
                          type="text"
                          icon={RiGraduationCapLine}
                          disabled={!editing}
                          required
                          submitted={submitted}
                          value={form.qualification}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>
                    </Row>
                  </div>

                  <Divider theme={theme} />

                  <div>
                    <h3>Pessoal e Equipamentos Adicionais</h3>

                    <Row>
                      <span className="additionalInfo">
                        <Label id="additionalInfo" text="Descrição" />
                        <Textarea
                          id="additionalInfo"
                          placeholder="Descrição..."
                          disabled={!editing}
                          value={form.additionalInfo}
                          form={form}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>

                      <Row className="reverse">
                        <span className="additionalInfoStart time">
                          <Label id="additionalInfoStart" text="Início" />
                          <TimePicker
                            id="additionalInfoStart"
                            icon={BsClockHistory}
                            disabled={
                              !editing || !form.date || !form.additionalInfo
                            }
                            date={form.date}
                            value={
                              form.additionalInfoStart
                                ? dayjs(form.additionalInfoStart).format(
                                    "HH:mm"
                                  )
                                : null
                            }
                            handleForm={handleForm}
                            minDate={
                              new Date(
                                form.date?.getFullYear(),
                                form.date?.getMonth(),
                                form.date?.getDate(),
                                0,
                                0
                              )
                            }
                            maxDate={
                              new Date(
                                form.date?.getFullYear(),
                                form.date?.getMonth(),
                                form.date?.getDate(),
                                23,
                                59
                              )
                            }
                          />
                        </span>

                        <span className="additionalInfoEnd time">
                          <Label id="additionalInfoEnd" text="Término" />
                          <TimePicker
                            id="additionalInfoEnd"
                            icon={BsClockHistory}
                            disabled={!editing || !form.additionalInfoStart}
                            date={form.date}
                            value={
                              form.additionalInfoEnd
                                ? dayjs(form.additionalInfoEnd).format("HH:mm")
                                : null
                            }
                            handleForm={handleForm}
                            minDate={form.additionalInfoStart}
                            maxDate={
                              new Date(
                                form.date?.getFullYear(),
                                form.date?.getMonth(),
                                form.date?.getDate(),
                                23,
                                59
                              )
                            }
                          />
                        </span>
                      </Row>
                    </Row>
                  </div>

                  <Divider theme={theme} />

                  <div>
                    <h3>Atividades Desempenhadas</h3>

                    {activities.map((item, index) => (
                      <ActivityItem
                        key={index}
                        detailIds={item.detailIds}
                        index={index}
                        form={form}
                        activities={activities}
                        setActivities={setActivities}
                        submitted
                        editing={editing}
                        services={services}
                      />
                    ))}

                    {editing && (
                      <AddSubitem
                        theme={theme}
                        disabled={!editing}
                        onClick={() =>
                          setActivities([
                            ...activities,
                            reportUtils.emptyActivity,
                          ])
                        }
                      >
                        <IoAddCircleSharp />
                        <p>Adicionar atividade</p>
                      </AddSubitem>
                    )}
                  </div>

                  <Divider theme={theme} />

                  <div>
                    <h3>Observações</h3>
                    <Row>
                      <span className="rdo-obs">
                        <Textarea
                          id="obs"
                          placeholder="Observações..."
                          disabled={!editing}
                          value={form.obs}
                          form={form}
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                            })
                          }
                        />
                      </span>
                    </Row>
                  </div>

                  <Button
                    className="submit"
                    title="Salvar"
                    enabled={editing && !saveDailyReportLoading}
                  />

                  {!newReport && (
                    <Button
                      className="cancel"
                      type="button"
                      title="Cancelar"
                      enabled={editing && !saveDailyReportLoading}
                      onClick={() => navigate(-1)}
                    />
                  )}
                </>
              )}
            </Form>
          </FormsWrapper>
        )}
      </Container>
    </Section>
  );
}

function ActivityItem({
  index,
  detailIds,
  form,
  activities,
  setActivities,
  submitted,
  editing,
  services,
}) {
  const { deleteDailyReportDetail } = useDeleteDailyReportDetail();
  const [deleting, setDeleting] = useState(false);

  function handleChange({ name, value }) {
    const result = [...activities];

    result[index] = { ...result[index], [name]: value };

    setActivities(result);
  }

  return (
    <>
      <ActivityWrapper theme={theme} index={index}>
        {index > 0 && (
          <div className="divider">
            <Divider theme={theme} />
            {editing && <AiFillMinusCircle onClick={() => setDeleting(true)} />}
          </div>
        )}

        <h4>{`Atividade ${index + 1}`}</h4>

        <Row>
          <span className="description">
            <Label id="description" text="Descrição*" />
            <Input
              id="description"
              placeholder="Descrição"
              type="text"
              icon={LuText}
              disabled={!editing}
              required
              submitted={submitted}
              value={activities[index].description}
              onChange={(e) =>
                handleChange({
                  name: e.target.id,
                  value: e.target.value,
                })
              }
            />
          </span>
        </Row>

        <Row>
          <span className="services">
            <Label id="services" text="Serviço(s) Relacionado(s)*" />
            <MultiSelect
              id="services"
              placeholder="Selecione..."
              icon={HiOutlineWrenchScrewdriver}
              enabled={editing}
              list={services}
              selected={activities[index].services}
              required
              form={activities}
              setForm={setActivities}
              handleForm={handleChange}
            />
          </span>
        </Row>

        <Row>
          <span className="activityStart time">
            <Label id="activityStart" text="Início*" />
            <TimePicker
              id="activityStart"
              icon={BsClockHistory}
              disabled={!editing || !form.date}
              date={form.date}
              value={
                activities[index].activityStart
                  ? dayjs(activities[index].activityStart).format("HH:mm")
                  : null
              }
              handleForm={handleChange}
              minDate={
                new Date(
                  form.date?.getFullYear(),
                  form.date?.getMonth(),
                  form.date?.getDate(),
                  0,
                  0
                )
              }
              maxDate={
                new Date(
                  form.date?.getFullYear(),
                  form.date?.getMonth(),
                  form.date?.getDate(),
                  23,
                  59
                )
              }
            />
          </span>

          <span className="activityEnd time">
            <Label id="activityEnd" text="Término*" />
            <TimePicker
              id="activityEnd"
              icon={BsClockHistory}
              disabled={!editing || !activities[index].activityStart}
              date={form.date}
              value={
                activities[index].activityEnd
                  ? dayjs(activities[index].activityEnd).format("HH:mm")
                  : null
              }
              handleForm={handleChange}
              minDate={activities[index].activityStart}
              maxDate={
                new Date(
                  form.date?.getFullYear(),
                  form.date?.getMonth(),
                  form.date?.getDate(),
                  23,
                  59
                )
              }
            />
          </span>

          <span className="status">
            <Label id="status" text="Siutação*" />
            <Select
              id="status"
              placeholder="Selecione..."
              icon={FaRegCalendarCheck}
              enabled={editing}
              list={[
                { label: "Concluído", value: "SIM" },
                { label: "Pendente", value: "NAO" },
              ]}
              selected={activities[index].status}
              required
              form={activities}
              setForm={setActivities}
              handleForm={handleChange}
            />
          </span>
        </Row>
      </ActivityWrapper>

      {deleting && (
        <DeleteItem
          itemId={detailIds.join(",")}
          text="Tem certeza que deseja excluir esta atividade?"
          list={activities}
          setList={setActivities}
          index={index}
          deleting={deleting}
          setDeleting={setDeleting}
          action={deleteDailyReportDetail}
        />
      )}
    </>
  );
}
