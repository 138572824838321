import useAsync from "../../useAsync";

import * as companyApi from "../../../services/companyApi";
import useToken from "../../useToken";

export default function useCompanies() {
  const token = useToken();

  const {
    data: companies,
    loading: companiesLoading,
    error: companiesError,
    act: getCompanies,
  } = useAsync((data) => companyApi.getCompanies(data, token), false);

  return {
    companies,
    companiesLoading,
    companiesError,
    getCompanies,
  };
}
