import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useChecklists() {
  const token = useToken();

  const {
    data: checklists,
    loading: checklistsLoading,
    error: checklistsError,
    act: getChecklists,
  } = useAsync((data) => reportApi.getChecklists(data, token), false);

  return {
    checklists,
    checklistsLoading,
    checklistsError,
    getChecklists,
  };
}
