import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 200px;

  @media (max-width: 767px) {
    margin-top: 35vh;

    div {
      justify-content: center;
    }

    svg {
      width: 50px;
      height: 50px;
    }
  }
`;
