import { useParams } from "react-router-dom";
import { useState } from "react";

import theme from "../../../../styles/theme";
import { Container } from "./styles";
import { Button } from "../Button";
import { Input } from "../Input";
import useChangeEmail from "../../../../hooks/api/authentication/useChangeEmail";
import { FiMail } from "react-icons/fi";
import toastUtils from "../../../../utils/toast-utils";

export default function ChangeEmail({
  editingEmail,
  setEditingEmail,
  setCurrentEmail,
}) {
  const { userId } = useParams();
  const { changeEmail } = useChangeEmail();
  const [newEmail, setNewEmail] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await changeEmail({ userId, email: newEmail });
      setEditingEmail(false);
      setCurrentEmail(newEmail);
      setNewEmail("");

      toastUtils.toaster({
        message: "E-mail alterado com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });

      setNewEmail("");
    }
  }

  return (
    <Container editingEmail={editingEmail} theme={theme}>
      <h5>Alterar E-mail</h5>

      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Novo e-mail"
          type="email"
          name="email"
          value={newEmail}
          required
          icon={FiMail}
          onChange={(e) => setNewEmail(e.target.value)}
        />

        <Button title="Confirmar" />
      </form>
    </Container>
  );
}
