import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useChecklistAnswers() {
  const token = useToken();

  const {
    data: checklistAnswers,
    loading: checklistAnswersLoading,
    error: checklistAnswersError,
    act: getChecklistAnswers,
  } = useAsync((data) => reportApi.getChecklistAnswers(data, token), false);

  return {
    checklistAnswers,
    checklistAnswersLoading,
    checklistAnswersError,
    getChecklistAnswers,
  };
}
