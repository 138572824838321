import useAsync from "../../useAsync";

import * as companyApi from "../../../services/companyApi";
import useToken from "../../useToken";

export default function useCompanyTypes() {
  const token = useToken();

  const {
    data: companyTypes,
    loading: companyTypesLoading,
    error: companyTypesError,
    act: getCompanyTypes,
  } = useAsync(() => companyApi.getCompanyTypes(token), false);

  return {
    companyTypes,
    companyTypesLoading,
    companyTypesError,
    getCompanyTypes,
  };
}
