import useAsync from "../../useAsync";

import * as jasperApi from "../../../services/jasperApi";
import useToken from "../../useToken";

export default function useJasperDailyReport() {
  const token = useToken();

  const {
    loading: jasperDailyReportLoading,
    error: jasperDailyReportError,
    act: saveJasperDailyReport,
  } = useAsync((data) => jasperApi.postJasperDailyReport(data, token), false);

  return {
    jasperDailyReportLoading,
    jasperDailyReportError,
    saveJasperDailyReport,
  };
}
