import ServiceList from "./ServiceList";
import { Section } from "../../../components/Section";

export default function InterruptedServices() {
  const types = "4";
  const allowed = false;

  return (
    <Section title="Serviços/Serviço Interrompido">
      <ServiceList allowed={allowed} types={types} />
    </Section>
  );
}
