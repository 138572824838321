import { useState } from "react";
import Company from ".";
import { Section } from "../../../../components/Section";
import theme from "../../../../styles/theme";
import { Container } from "../styles";

export default function Client() {
  const [title, setTitle] = useState("Cadastro/Clientes");

  return (
    <Container theme={theme}>
      <Section title={title}>
        <Company title={title} setTitle={setTitle} />
      </Section>
    </Container>
  );
}
