import useAsync from "../../useAsync";

import * as enrollmentApi from "../../../services/enrollmentApi";
import useToken from "../../useToken";

export default function useEnrollmentFilters() {
  const token = useToken();

  const {
    data: enrollmentFilters,
    loading: enrollmentFiltersLoading,
    error: enrollmentFiltersError,
    act: getEnrollmentFilters,
  } = useAsync(() => enrollmentApi.getEnrollmentFilters(token), false);

  return {
    enrollmentFilters,
    enrollmentFiltersLoading,
    enrollmentFiltersError,
    getEnrollmentFilters,
  };
}
