import dayjs from "dayjs";
import "dayjs/locale/pt-br";

const eventFilters = [
  { label: "Todos", value: "" },
  { label: "Vistorias", value: "inspection" },
  { label: "Feriados", value: "holliday" },
  { label: "Aniversários", value: "birthday" },
  { label: "Gerais", value: "generic" },
];

const calendarEmptyDisplayParams = {
  type: { label: "", value: "" },
  state: { label: "", value: "" },
};

const emptyEvent = {
  id: 0,
  table: "",
  tableId: 0,
  userId: 0,
  title: "",
  start: null,
  end: null,
  color: "",
  type: "",
  allDay: false,
  location: "",
  obs: "",
  guests: [],
};

function formatEventDate(event) {
  dayjs.locale("pt-br");
  let dateString = "";
  const startDate = dayjs(event.start).format("dddd, D");
  const endDate = dayjs(event.end).format("dddd, D");

  if (!event.allDay)
    dateString = `${
      startDate.charAt(0).toUpperCase() + startDate.slice(1)
    } de ${dayjs(event.start).format("MMMM")} - ${dayjs(event.start).format(
      "HH:mm"
    )} até ${dayjs(event.end).format("HH:mm")}`;
  else if (startDate === endDate)
    dateString = `${
      startDate.charAt(0).toUpperCase() + startDate.slice(1)
    } de ${dayjs(event.start).format("MMMM")}`;
  else
    dateString = `${
      startDate.charAt(0).toUpperCase() + startDate.slice(1)
    } de ${dayjs(event.start).format("MMMM")} - ${
      endDate.charAt(0).toUpperCase() + endDate.slice(1)
    } de ${dayjs(event.end).format("MMMM")}`;

  return dateString;
}

const calendarUtils = {
  eventFilters,
  calendarEmptyDisplayParams,
  emptyEvent,
  formatEventDate,
};

export default calendarUtils;
