import { FaArrowRight } from "react-icons/fa";

import { Container } from "./styles";
import theme from "../../../../styles/theme";

export default function NextButton({ text, setSubmiting }) {
  return (
    <Container theme={theme}>
      <h6>{text}</h6>
      <button type="button" onClick={() => setSubmiting(true)}>
        <FaArrowRight />
      </button>
    </Container>
  );
}
