import { BsPlusCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import theme from "../../../styles/theme";
import { AddListItem, ListItem } from "../styles";
import useInspections from "../../../hooks/api/inspection/useInspections";
import useInspectionFilters from "../../../hooks/api/inspection/useInspectionFilters";
import toastUtils from "../../../utils/toast-utils";
import { useEffect, useState } from "react";
import ListWrapper from "../../../components/ListWrapper";
import { IoChevronForward } from "react-icons/io5";
import inspectionUtils from "../../../utils/inspection-utils";
import { ParamsWrapper } from "../Enrollment/styles";
import Select from "../../../components/forms/inspection-form/Select";
import { CgReorder } from "react-icons/cg";
import { BiFilter } from "react-icons/bi";
import FilterSelector from "../../../components/FilterSelector";

export default function InspectionList({ statusId }) {
  const responseLength = 20;
  const navigate = useNavigate();
  const { getInspections, inspectionsLoading } = useInspections();
  const { getInspectionFilters } = useInspectionFilters();
  const [inspections, setInspections] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(
    inspectionUtils.inspectionEmptyFilters
  );
  const [displayParams, setDisplayParams] = useState(
    inspectionUtils.inspectionEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadInspections();
    loadFilters();
  }, []);

  useEffect(() => {
    orderInspections();
  }, [displayParams.order]);

  useEffect(() => {
    handleFilter();
    handleInspectionSearch();
  }, [
    search,
    inspections,
    displayParams.company,
    displayParams.vessel,
    displayParams.inspector,
  ]);

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleInspectionSearch() {
    if (!search) return handleFilter();

    const newList = inspections.filter(
      (inspection) =>
        inspection.id === Number(search) ||
        inspection.vessel.name.toLowerCase().includes(search.toLowerCase()) ||
        inspection.company.name.toLowerCase().includes(search.toLowerCase())
    );

    setFilteredList(newList);
  }

  function handleFilter() {
    let result = inspections;

    if (displayParams.company.value)
      result = result.filter(
        (inspection) => inspection.company.id === displayParams.company.value
      );

    if (displayParams.vessel.value)
      result = result.filter(
        (inspection) => inspection.vessel.id === displayParams.vessel.value
      );

    if (displayParams.coordinator.value)
      result = result.filter(
        (inspection) =>
          inspection.coordinator === displayParams.coordinator.value
      );

    setFilteredList(result);
  }

  async function loadFilters() {
    try {
      const response = await getInspectionFilters();

      setFilters({
        ...filters,
        companies: filters.companies.concat(response.companies),
        vessels: filters.vessels.concat(response.vessels),
        coordinators: filters.coordinators.concat(response.coordinators),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadInspections() {
    const skip = inspections.length;
    const take = responseLength;

    try {
      const response = await getInspections({
        order: displayParams.order.value ? displayParams.order.value : "",
        statusId,
        skip,
        take,
      });

      if (response) {
        setInspections(inspections.concat(response));
        setFilteredList(inspections.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderInspections() {
    const skip = 0;
    const take = responseLength;

    try {
      const response = await getInspections({
        order: displayParams.order.value ? displayParams.order.value : "",
        statusId,
        skip,
        take,
      });

      if (response) {
        setInspections(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <>
      <ParamsWrapper displayFilters={displayFilters}>
        <Select
          id="order"
          icon={CgReorder}
          placeholder={"Ordenar por..."}
          enabled={true}
          list={inspectionUtils.inspectionOrderList}
          selected={displayParams.order}
          form={displayParams}
          setForm={setDisplayParams}
          handleForm={handleForm}
        />

        <FilterSelector
          displayFilters={displayFilters}
          setDisplayFilters={setDisplayFilters}
        />

        <Select
          id="company"
          icon={BiFilter}
          placeholder={"Empresa"}
          enabled={true}
          list={filters.companies}
          selected={displayParams.company}
          form={displayParams}
          setForm={setDisplayParams}
          handleForm={handleForm}
          className="filter"
        />

        <Select
          id="vessel"
          icon={BiFilter}
          placeholder={"Embarcação"}
          enabled={true}
          list={
            displayParams.company.value
              ? inspectionUtils.inspectionEmptyFilters.vessels.concat(
                  filters.vessels.filter(
                    (vessel) => vessel.companyId === displayParams.company.value
                  )
                )
              : filters.vessels
          }
          selected={displayParams.vessel}
          form={displayParams}
          setForm={setDisplayParams}
          handleForm={handleForm}
          className="filter"
        />

        <Select
          id="coordinator"
          icon={BiFilter}
          placeholder={"Coordenador"}
          enabled={true}
          list={filters.coordinators}
          selected={displayParams.coordinator}
          form={displayParams}
          setForm={setDisplayParams}
          handleForm={handleForm}
          className="filter"
        />
      </ParamsWrapper>

      {statusId === inspectionUtils.status.AGUARDANDO_ANALISE_VISTORIADOR && (
        <AddListItem
          theme={theme}
          onClick={() => navigate(`/painel/vistorias/agendadas/novo`)}
        >
          <BsPlusCircleFill />
          <h5>Agendar Vistoria</h5>
        </AddListItem>
      )}

      <ListWrapper
        offset={responseLength}
        list={inspections}
        loadList={loadInspections}
        loading={inspectionsLoading}
      >
        {filteredList.map((inspection, index) => (
          <InspectionWrapper
            key={index}
            id={inspection.id}
            company={inspection.company.name}
            vessel={inspection.vessel.name}
          />
        ))}
      </ListWrapper>
    </>
  );
}

function InspectionWrapper({ id, company, vessel }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}`)}>
      <div>
        <h5>{`${id} - ${vessel} (${company})`}</h5>
        <IoChevronForward />
      </div>
    </ListItem>
  );
}
