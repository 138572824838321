import styled from "styled-components";

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .checklist {
    margin: 30px 0;
    border: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};
    border-top: none;
  }

  .summary {
    margin: 30px 0 50px 0;
    border: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};
    border-top: none;

    .index {
      justify-content: center;
    }
  }

  form > div {
    position: relative;
  }
`;
