import styled from "styled-components";

export const Container = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  button {
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    font-weight: 700;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 80%;
    }
  }

  svg {
    font-size: 26px;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  h6 {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    width: calc(50% - 10px);
    flex-direction: column-reverse;
    position: absolute;
    right: 0;
    bottom: -18px;

    h6 {
      font-size: 16px;
      margin: 10px 0;
      margin-right: 0;
      text-align: center;
    }
  }
`;
