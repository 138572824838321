import useAsync from "../../useAsync";

import * as productApi from "../../../services/productApi";
import useToken from "../../useToken";

export default function useSaveProduct() {
  const token = useToken();

  const {
    loading: saveProductLoading,
    error: saveProductError,
    act: saveProduct,
  } = useAsync((data) => productApi.postProduct(data, token), false);

  return {
    saveProductLoading,
    saveProductError,
    saveProduct,
  };
}
