import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function usePasswordChangeEmail() {
  const {
    data: passwordChangeEmail,
    loading: passwordChangeEmailLoading,
    error: passwordChangeEmailError,
    act: getPasswordChangeEmail,
  } = useAsync(
    (data) => authenticationApi.sendPasswordChangeEmail(data),
    false
  );

  return {
    passwordChangeEmail,
    passwordChangeEmailLoading,
    passwordChangeEmailError,
    getPasswordChangeEmail,
  };
}
