import api from "./api";

export async function getEvents({ currentYear, state, type }, token) {
  const response = await api.get(
    `/calendar/event/many?year=${currentYear}&state=${state}&type=${type}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function postEvent(body, token) {
  const response = await api.post("/calendar/event", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteEvent(eventId, token) {
  const response = await api.delete(`/calendar/event/${eventId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
