import useAsync from "../../useAsync";

import * as requestApi from "../../../services/requestApi";
import useToken from "../../useToken";

export default function useRequestHistory() {
  const token = useToken();

  const {
    data: requestHistory,
    loading: requestHistoryLoading,
    error: requestHistoryeError,
    act: getRequestHistory,
  } = useAsync((data) => requestApi.getRequestHistory(data, token), false);

  return {
    requestHistory,
    requestHistoryLoading,
    requestHistoryeError,
    getRequestHistory,
  };
}
