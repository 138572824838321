import styled from "styled-components";

export const ServiceContainer = styled.div`
  width: 100%;
  display: flex;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 30px 0 0 0;
    width: 100%;
    position: relative;
    margin-bottom: 130px;
  }

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .subitem > div {
    margin-left: 30px;
    position: relative;
  }

  .product {
    margin-left: 20px !important;
  }

  @media (max-width: 767px) {
    & > div {
      flex-direction: column;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
      margin: 20px 0;
    }
  }
`;
