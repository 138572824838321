import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiUser } from "react-icons/fi";

import { Section } from "../../../../components/Section";
import { RequestDisplayer } from "../ServiceUnderReview/styles";
import { Container, Form, Row } from "../styles";
import { Divider } from "./styles";
import RequestInfo from "../../../../components/forms/service-form/RequestInfo";
import theme from "../../../../styles/theme";
import Reviewer from "../../../../components/forms/service-form/Reviewer";
import { ServiceContainer } from "../RequestedService/styles";
import toastUtils from "../../../../utils/toast-utils";
import serviceUtils from "../../../../utils/service-utils";
import ConfirmationMessage from "../../../../components/ConfirmationMessage";
import { Textarea } from "../../../../components/forms/service-form/Textarea";
import Label from "../../../../components/forms/service-form/Label";
import useSaveRequest from "../../../../hooks/api/request/useSaveRequest";
import useRequest from "../../../../hooks/api/request/useRequest";
import useRequestOptions from "../../../../hooks/api/request/useRequestOptions";
import UserContext from "../../../../contexts/UserContext";
import Timeline from "../../../../components/forms/service-form/Timeline";
import NextButton from "../../../../components/forms/service-form/NextButton";
import InterruptButton from "../../../../components/forms/service-form/InterruptButton";
import Select from "../../../../components/forms/service-form/Select";
import useInspectors from "../../../../hooks/api/inspection/useInspectors";
import Loader from "../../../../components/Loader";

export default function AuthorizedService({ type }) {
  const { userData } = useContext(UserContext);
  const { serviceId } = useParams();
  const status = Number(useLocation().search.replace("?status=", ""));
  const navigate = useNavigate();
  const { getRequestOptions } = useRequestOptions();
  const { getRequest } = useRequest();
  const { saveRequest } = useSaveRequest();
  const { getInspectors, inspectorsLoading } = useInspectors();
  const [title, setTitle] = useState("Serviços/Serviços Autorizados");
  const [options, setOptions] = useState(serviceUtils.emptyServiceOptions);
  const [form, setForm] = useState(serviceUtils.emptyServiceForm);
  const [requestInfo, setRequestInfo] = useState(serviceUtils.emptyService);
  const [reviewInfo, setReviewInfo] = useState(serviceUtils.emptyService);
  const [displayRequest, setDisplayRequest] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [interrupting, setInterrupting] = useState(false);
  const [coordinators, setCoordinators] = useState([]);

  useEffect(() => {
    loadRequestData();

    if (status >= serviceUtils.status.AGUARDANDO_ANALISE_INTERNA)
      loadReviewData();
  }, [status]);

  async function loadRequestData() {
    try {
      const response = await getRequestOptions();

      if (response) {
        setOptions({
          ...options,
          certificationOptions: response.certificationOptions,
          projectOptions: response.projectOptions,
        });

        const service = await getRequest({
          serviceId,
          status: serviceUtils.status.SOLICITADO,
        });

        if (service) {
          setRequestInfo(service);

          if (status < serviceUtils.status.AGUARDANDO_ANALISE_INTERNA)
            setTitle(
              `Serviços/Serviços Autorizados/${service.id} - ${service.type.typeName} - ${service.company.label} - ${service.vessel.label}`
            );
        }
      }

      loadCoordinators();
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadReviewData() {
    try {
      const response = await getRequest({
        serviceId,
        status,
      });

      if (response) {
        setReviewInfo(response);
        setForm({
          ...response,
          obs: "",
          coordinator: form.coordinator,
        });
        setTitle(
          `Serviços/Serviços Autorizados/${response.id} - ${response.type.typeName} - ${response.company.label} - ${response.vessel.label}`
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadCoordinators() {
    let skip = 0;
    let take = 1000;

    try {
      const response = await getInspectors({
        skip,
        take,
      });

      if (response) {
        setCoordinators(
          response.map((inspector) => ({
            label: inspector.name,
            value: inspector.id,
          }))
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit() {
    try {
      const body = {
        id: Number(serviceId),
        company: form.company.value,
        vessel: form.vessel.value,
        obs: form.obs,
        status: status + 1,
        type: status <= 3 ? 2 : type,
        coordinatorId: form.coordinator.value,
        forms: form.reports,
      };

      await saveRequest(body);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      setSubmiting(false);

      await loadReviewData();

      navigate(
        `/painel/servicos/autorizados/${serviceId}?status=${status + 1}`
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleInterrupt() {
    try {
      const body = {
        id: Number(serviceId),
        company: form.company.value,
        vessel: form.vessel.value,
        obs: form.obs,
        status: serviceUtils.status.FINALIZADO,
        type: 4,
        forms: form.reports,
      };

      await saveRequest(body);

      toastUtils.toaster({
        message: "Serviço interrompido com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      setInterrupting(false);

      await loadReviewData();

      navigate(
        `/painel/servicos/interrompidos/${serviceId}?status=${serviceUtils.status.FINALIZADO}`
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <Section title={title}>
        <RequestDisplayer theme={theme}>
          <h5>Detalhamento da Solicitação</h5>

          {displayRequest ? (
            <AiOutlineCaretUp onClick={() => setDisplayRequest(false)} />
          ) : (
            <AiOutlineCaretDown onClick={() => setDisplayRequest(true)} />
          )}
        </RequestDisplayer>

        {displayRequest && (
          <>
            <RequestInfo info={requestInfo} options={options} />
            <Divider theme={theme} />
          </>
        )}

        <Reviewer newReview={false} requestInfo={requestInfo} status={status} />

        <ServiceContainer theme={theme}>
          <RequestInfo info={reviewInfo} options={options} />
        </ServiceContainer>

        <Divider theme={theme} />

        <ServiceContainer theme={theme}>
          <Timeline serviceId={serviceId} status={status} />
        </ServiceContainer>

        {status >= serviceUtils.status.ANALISE_INTERNA_EXECUTADA &&
          status < serviceUtils.status.FINALIZADO &&
          ((userData.permission.label === "APROVADOR" &&
            userData.department.label === "ADMINISTRATIVO") ||
            userData.user.id === requestInfo.user.userId) && (
            <NextButton
              text={serviceUtils.confirmationMessages[status]}
              setSubmiting={setSubmiting}
            />
          )}

        {status < serviceUtils.status.FINALIZADO &&
          (userData.permission.label === "APROVADOR" ||
            userData.permission.label === "MASTER" ||
            userData.department.label === "ADMINISTRATIVO" ||
            userData.user.id === requestInfo.user.userId) && (
            <InterruptButton
              text="Interromper serviço"
              setSubmiting={setInterrupting}
            />
          )}

        {inspectorsLoading ? (
          <Loader theme={theme} />
        ) : (
          <ConfirmationMessage
            text={serviceUtils.confirmationMessages[status]}
            action={handleSubmit}
            confirming={submiting}
            setConfirming={setSubmiting}
          >
            <Form>
              <div>
                {status === serviceUtils.status.AUTORIZADO_PELO_CLIENTE && (
                  <Row>
                    <span className="coordinator">
                      <Label id="coordinator" text="Atribua um coordenador*" />
                      <Select
                        id="coordinator"
                        placeholder="Selecione..."
                        icon={FiUser}
                        enabled={true}
                        list={coordinators}
                        selected={form.coordinator}
                        required
                        form={form}
                        setForm={setForm}
                        handleForm={handleForm}
                      />
                    </span>
                  </Row>
                )}

                <Row>
                  <span className="textarea">
                    <Label id="obs" text="Observações*" />
                    <Textarea
                      id="obs"
                      placeholder="Observações..."
                      disabled={false}
                      value={form.obs}
                      required
                      form={form}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>
              </div>
            </Form>
          </ConfirmationMessage>
        )}

        <ConfirmationMessage
          text="Tem certeza que deseja interromper este serviço?"
          action={handleInterrupt}
          confirming={interrupting}
          setConfirming={setInterrupting}
        >
          <Form>
            <div>
              <Row>
                <span className="textarea">
                  <Label id="obs" text="Observações*" />
                  <Textarea
                    id="obs"
                    placeholder="Observações..."
                    disabled={false}
                    value={form.obs}
                    required
                    form={form}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </div>
          </Form>
        </ConfirmationMessage>
      </Section>
    </Container>
  );
}
