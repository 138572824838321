import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.COLORS.GRAY_300};
  border: none;
  font-size: 15px;
  white-space: pre-wrap;

  & > div {
    width: 100%;
  }

  .css-ejchsg-singleValue,
  .placeholder {
    text-align: center;
  }

  .css-qbdosj-Input,
  .placeholder {
    max-width: 80%;
  }

  .select {
    text-overflow: ellipsis;

    span {
      margin: auto 0;
      height: 60%;
      background-color: transparent;
      border-left: 1px solid
        ${({ theme, enabled }) =>
          enabled ? theme.COLORS.GRAY_100 : "transparent"};
      padding: 0;
    }
  }

  .placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input[type="text"] {
    color: ${({ theme }) => theme.COLORS.GRAY_100} !important;
  }

  @media (max-width: 1023px) {
    .placeholder {
      max-width: 70%;
    }

    .css-1mchyhp-indicatorContainer,
    .css-1by1pg0-indicatorContainer {
      padding: 4px;
    }
  }

  @media (max-width: 767px) {
    font-size: 10px;

    .placeholder {
      width: 50px !important;
    }

    .select {
      svg {
        margin: 0 auto;
        width: 14px;
        height: 14px;
      }

      span {
        background-color: transparent;
        display: none !important;
      }

      div {
        width: 100%;
        text-align: center;
        padding: 2px;
        margin: 0;
      }
    }
  }
`;
