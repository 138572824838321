import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useDeleteDetails() {
  const token = useToken();

  const {
    loading: deleteDetailsLoading,
    error: deleteDetailsError,
    act: deleteDetails,
  } = useAsync(
    (data) => inspectionApi.deleteInspectionDetails(data, token),
    false
  );

  return {
    deleteDetailsLoading,
    deleteDetailsError,
    deleteDetails,
  };
}
