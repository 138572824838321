import theme from "../../../../styles/theme";
import { Textarea } from "./styles";

export default function ChecklistComment({
  answers,
  handleComment,
  item,
  enabled,
  required,
}) {
  return (
    <Textarea
      theme={theme}
      type="text"
      placeholder="Detalhamento..."
      value={answers[item.id].obs}
      onChange={(e) => handleComment(e.target.value)}
      disabled={answers[item.id]?.status === 0 || !enabled}
      rows={4}
      required={required}
    />
  );
}
