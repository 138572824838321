import { toast } from "react-toastify";

function toaster({ message, type, position, theme }) {
  if (type === toastUtils.type.default)
    return toast(message, {
      position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: theme.box },
      progressStyle: { backgroundColor: theme.progressBar },
    });

  return toast[type](message, {
    position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme,
  });
}

const type = {
  info: "info",
  error: "error",
  success: "success",
  warning: "warning",
  default: "",
};

const position = {
  topLeft: "top-left",
  topRight: "top-right",
  topCenter: "top-center",
  bottomLeft: "bottom-left",
  bottomRight: "bottom-right",
  bottomCenter: "bottomCenter",
};

const theme = {
  light: "light",
  dark: "dark",
  colored: "colored",
};

const toastUtils = {
  toaster,
  type,
  position,
  theme,
};

export default toastUtils;
