const theme = {
  COLORS: {
    BACKGROUND_1000: "#121114",
    BACKGROUND_900: "#232129",
    BACKGROUND_800: "#312E38",
    BACKGROUND_700: "#3E3B47",
    BACKGROUND_600: "#4b4854",

    WHITE: "#F4EDE8",
    ORANGE: "#FF9000",
    BLUE: "#003366",
    GRAY: "#333333",
    GRAY_TRANSLUCENT: "#333333bf",

    GRAY_50: "#d6cfc7",
    GRAY_100: "#999591",
    GRAY_200: "#72716F",
    GRAY_300: "#666360",

    RED: "#FF002E",
  },
};

export default theme;
