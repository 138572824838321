import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 15px;
  bottom: 30px;

  @media (max-width: 767px) {
    bottom: 26px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
