import { FiMail, FiUser } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { useState } from "react";

import { Background, Form } from "../styles";
import { Input } from "../../../components/forms/authentication-form/Input";
import { Button } from "../../../components/forms/authentication-form/Button";
import theme from "../../../styles/theme";
import useSignUp from "../../../hooks/api/authentication/useSignUp";
import toastUtils from "../../../utils/toast-utils";
import PasswordInput from "../../../components/forms/PasswordInput";
import Title from "../../../components/forms/authentication-form/Title";

export default function SignUp() {
  const navigate = useNavigate();
  const { signUp } = useSignUp();
  const [typingPassword, setTypingPassword] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const user = await signUp(form);
      navigate(`/sign-up/confirmacao/${user.id}?email=${user.email}`);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });

      setForm({ ...form, password: "", confirmPassword: "" });
    }
  }

  return (
    <>
      <Form theme={theme} onSubmit={handleSubmit}>
        <Title />

        <h2> Faça seu cadastro </h2>

        <Input
          placeholder="Nome"
          type="text"
          name="name"
          value={form.name}
          required
          icon={FiUser}
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
        />

        <Input
          placeholder="E-mail"
          type="email"
          name="email"
          value={form.email}
          required
          icon={FiMail}
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
        />

        <PasswordInput
          placeholder="Senha"
          name="password"
          value={form.password}
          required
          onFocus={() => setTypingPassword(true)}
          onBlur={() => setTypingPassword(false)}
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
          minLength="8"
          pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
          typingPassword={typingPassword}
          password={form.password}
        />

        <PasswordInput
          placeholder="Confirme sua senha"
          name="confirmPassword"
          value={form.confirmPassword}
          required
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
          minLength="8"
          pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
        />

        <Button title="Cadastrar" />

        <Link to="/">Já possui cadastro? Entrar</Link>
      </Form>
      <Background theme={theme} />
    </>
  );
}
