import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 30px 0 0 0;
    width: 100%;
    position: relative;
    margin-bottom: 130px;
  }

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  @media (max-width: 767px) {
    margin-top: 20px;

    h3 {
      font-size: 22px;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 30px 0;
`;
