import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useChecklistFilters() {
  const token = useToken();

  const {
    data: checklistFilters,
    loading: checklistFiltersLoading,
    error: checklistFiltersError,
    act: getChecklistFilters,
  } = useAsync(() => reportApi.getChecklistFilters(token), false);

  return {
    checklistFilters,
    checklistFiltersLoading,
    checklistFiltersError,
    getChecklistFilters,
  };
}
