import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 15;
  background-color: ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT};
  align-items: center;
  justify-content: center;

  .confirm,
  .cancel {
    position: static !important;
    display: flex !important;
    margin: 0 10px;
    height: 40px;
    font-size: 18px;
    width: auto;
  }

  .confirm {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  .cancel {
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 20px 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  label {
    text-align: left;
  }

  .textarea {
    top: -20px;
  }

  .coordinator {
    margin-bottom: 20px;
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 612px;
  padding-left: 16px;
  margin: auto;
  padding: 30px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  position: relative;

  @media (max-width: 767px) {
    width: 80%;
  }
`;

export const CloseButton = styled.button`
  width: 18px;
  height: 18px;
  position: absolute;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 28px;
    min-width: 22px;
    min-height: 28px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    transition: 0s;
  }

  svg:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const PictureList = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`;

export const PictureWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.COLORS.WHITE};
  background-color: ${({ theme }) => theme.COLORS.GRAY_100};
  position: relative;
  margin: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
  }
`;

export const PicturePreview = styled.div`
  position: fixed;
  max-height: 80vh;
  max-width: 80vw;
  margin: auto;
  border-radius: 0;

  div {
    position: relative;
  }

  img {
    max-height: 80vh;
    max-width: 80vw;
    border-radius: 0;
  }
`;

export const AddButton = styled.label`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.COLORS.GRAY_100};
  position: relative;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  svg {
    width: 80%;
    height: 80%;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  input {
    display: none;
  }
`;

export const DeletePicture = styled.button`
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: transparent;
  border: 0;
  top: -8px;
  right: -8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    transition: 0s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};

    svg {
      color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    }
  }
`;

export const FormatWarning = styled.p`
  width: 100%;
  text-align: left;
  margin-top: 0;
  padding: 0 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.GRAY_100};
`;
