import theme from "../../../../styles/theme";
import { BoxWrapper } from "./styles";

export default function CheckBox({
  label,
  form,
  handleCheck,
  id,
  disabled,
  serviceId,
  serviceName,
  activityId,
}) {
  const checked = form[id] ? true : false;

  function handleChange() {
    handleCheck({
      name: label,
      value: id,
      serviceId,
      serviceName,
      activityId,
    });
  }

  return (
    <BoxWrapper theme={theme} checked={checked} disabled={disabled}>
      <input
        type="checkbox"
        value={checked}
        onChange={handleChange}
        disabled={disabled}
        checked={checked}
      />

      <label>{label}</label>
    </BoxWrapper>
  );
}
