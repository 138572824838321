import useAsync from "../../useAsync";

import * as calendarApi from "../../../services/calendarApi";
import useToken from "../../useToken";

export default function useEvents() {
  const token = useToken();

  const {
    data: events,
    loading: eventsLoading,
    error: eventsError,
    act: getEvents,
  } = useAsync((data) => calendarApi.getEvents(data, token), false);

  return {
    events,
    eventsLoading,
    eventsError,
    getEvents,
  };
}
