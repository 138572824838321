import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionData() {
  const token = useToken();

  const {
    data: inspectionData,
    loading: inspectionDataLoading,
    error: inspectionDataError,
    act: getInspectionData,
  } = useAsync((data) => inspectionApi.getInspectionData(data, token), false);

  return {
    inspectionData,
    inspectionDataLoading,
    inspectionDataError,
    getInspectionData,
  };
}
