import styled from "styled-components";

export const ActivityWrapper = styled.div`
  width: 100%;
  margin-top: ${({ index }) => (index === 0 ? "20px" : "0")};

  h4 {
    margin: 0 0 10px 0;
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .divider {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
      margin-left: 10px;

      &:hover {
        color: ${({ theme }) => theme.COLORS.WHITE};
      }
    }
  }
`;
