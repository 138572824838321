import styled from "styled-components";

export const Container = styled.div`
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_200};
    object-fit: cover;
  }

  h3 {
    font-size: 26px;
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  p {
    font-size: 20px;
    margin: 0 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  span {
    height: 60px;
    width: auto !important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
    border-radius: 30px;

    svg:last-child {
      color: ${({ theme }) => theme.COLORS.GRAY_100};
      font-size: 18px;
      cursor: pointer;
      transition: 0s;
      margin: 2px 10px 0 0;

      &:hover {
        color: ${({ theme }) => theme.COLORS.WHITE};
      }
    }

    svg:first-child {
      width: 50px;
      height: 50px;
      color: ${({ theme }) => theme.COLORS.BACKGROUND_600};
    }
  }

  @media (max-width: 767px) {
    img {
      width: 40px;
      height: 40px;
    }

    span {
      width: 100% !important;
      height: 50px;

      svg:first-child {
        width: 40px;
        height: 40px;
      }
    }

    h3 {
      font-size: 22px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      margin-right: auto;
    }
  }
`;
