import useAsync from "../../useAsync";

import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionsByResponsibles() {
  const token = useToken();

  const {
    data: inspectionsByResponsibles,
    loading: inspectionsByResponsiblesLoading,
    error: inspectionsByResponsiblesError,
    act: getInspectionsByResponsibles,
  } = useAsync(() => inspectionApi.getInspectionsByResponsibles(token), false);

  return {
    inspectionsByResponsibles,
    inspectionsByResponsiblesLoading,
    inspectionsByResponsiblesError,
    getInspectionsByResponsibles,
  };
}
