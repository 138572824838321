import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  /*Header and buttons*/
  .rbc-toolbar {
    height: 10vh;
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: 25px;
  }

  .rbc-btn-group > button {
    border: 0;
  }

  .rbc-toolbar button {
    padding: 2px 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_300};
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    border-width: 6px 1px;
    border-style: solid;
    border-left-color: ${({ theme }) => theme.COLORS.GRAY_50};
    border-right-color: ${({ theme }) => theme.COLORS.GRAY_50};
    border-top-color: transparent;
    border-bottom-color: transparent;
    transition-duration: 0ms;

    &:hover {
      background-color: ${({ theme }) => theme.COLORS.GRAY_50};
      cursor: pointer;
    }
  }

  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    border: 0;
    height: 31px;
    font-weight: 700;
  }

  /*Lines*/
  .rbc-month-view {
    border-color: ${({ theme }) => theme.COLORS.GRAY_100};
    border-radius: 5px;
  }

  .rbc-month-row {
    border-color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .rbc-day-bg {
    border-color: ${({ theme }) => theme.COLORS.GRAY_100};
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  }

  .rbc-day-bg:last-child {
    border-radius: 0 0 5px 0;
  }

  /*Month*/

  .rbc-calendar {
    min-height: 100vh;
  }

  .rbc-header {
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    border-color: ${({ theme }) => theme.COLORS.WHITE};
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border-radius: 0 4px 0 0;
    }
    &:first-child {
      border-radius: 4px 0 0 0;
    }
  }

  .rbc-header {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .rbc-off-range-bg {
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  }

  .rbc-today {
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_1000};
  }

  .rbc-button-link {
    margin: 0;
    font-size: 15px;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  .rbc-off-range .rbc-button-link {
    color: ${({ theme }) => theme.COLORS.GRAY_300};
  }

  .rbc-now .rbc-button-link {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    border-radius: 2px;
    padding-left: 2px;
    padding-right: 2px;
    margin: 5px;
    font-size: 16px;
    font-weight: 700;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
  }

  /*Week*/

  .rbc-time-view {
    border: 0 !important;

    .rbc-day-bg {
      background-color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
      border-color: ${({ theme }) => theme.COLORS.GRAY_300};
    }

    .rbc-today {
      background-color: ${({ theme }) => theme.COLORS.BACKGROUND_1000};
    }
  }

  .rbc-time-content {
    border: 0;
    overflow-y: visible;
  }

  .rbc-time-content > * + * > * {
    border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
  }

  .rbc-current-time-indicator {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
    height: 2px;
  }

  .rbc-current-time-indicator::before {
    content: "";
    position: absolute;
    top: -4.5px;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
  }

  .rbc-time-header-cell {
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    border-radius: 5px 5px 0 0;

    div {
      border-bottom: none !important;
    }
  }

  .rbc-header span {
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  }

  .rbc-header + .rbc-today {
    border-radius: 5px 5px 0 0;
    border: 1px solid ${({ theme }) => theme.COLORS.WHITE};

    span {
      color: ${({ theme }) => theme.COLORS.WHITE};
      font-weight: 700;
    }
  }

  .rbc-time-gutter {
    div {
      background-color: ${({ theme }) =>
        theme.COLORS.BACKGROUND_800} !important;
    }

    .rbc-time-slot {
      margin: -10px 8px 0 -13px;
      padding-right: 5px;
    }
  }

  .rbc-time-header-gutter {
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
  }

  .rbc-time-header-content {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
    border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
    border-radius: 5px 0 0 0;
  }

  .rbc-time-slot {
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
    color: ${({ theme }) => theme.COLORS.WHITE};

    &:nth-child(n) {
      border: none;
    }
  }

  .rbc-today {
    .rbc-time-slot {
      background-color: ${({ theme }) => theme.COLORS.BACKGROUND_1000};
    }
  }

  .rbc-overflowing {
    border-right: none;
  }

  /*Agenda*/
  thead {
    border-bottom: solid ${({ theme }) => theme.COLORS.WHITE};
  }

  .rbc-agenda-table {
    thead > tr {
      display: flex;

      .rbc-header {
        width: 120px;
        max-width: 120px;
        min-width: 120px;
        flex: none;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        border-width: 6px 1px 3px 1px;
        border-style: solid;
        border-left-color: ${({ theme }) => theme.COLORS.GRAY_50};
        border-right-color: ${({ theme }) => theme.COLORS.GRAY_50};
        border-top-color: transparent;
        border-bottom-color: transparent;
        box-sizing: border-box;
      }

      .rbc-header:first-child {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
      }

      .rbc-header:last-child {
        width: calc(100% - 220px);
        max-width: calc(100% - 220px);
        min-width: calc(100% - 220px);
      }
    }
  }

  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell,
  .rbc-agenda-event-cell {
    flex: none;
    padding: 5px 10px !important;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    vertical-align: middle !important;
  }

  .rbc-agenda-date-cell {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    border-right: 1px solid ${({ theme }) => theme.COLORS.WHITE};
  }

  .rbc-agenda-time-cell {
    width: 120px;
    max-width: 120px;
    min-width: 120px;
  }

  .rbc-agenda-event-cell {
    width: calc(100% - 220px) !important;
    max-width: calc(100% - 220px) !important;
    min-width: calc(100% - 220px) !important;
    word-wrap: break-word;
    box-sizing: border-box;
    position: relative;
    background-color: transparent;
  }

  .rbc-agenda-event-cell::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: inherit;
    z-index: -1;
  }

  .rbc-agenda-view > table.rbc-agenda-table {
    border-radius: 5px;
  }

  table.rbc-agenda-table {
    border: 0 !important;
  }

  tbody {
    border: 1px solid ${({ theme }) => theme.COLORS.WHITE};
  }

  .rbc-agenda-empty {
    color: ${({ theme }) => theme.COLORS.WHITE};
    margin: 100px auto;
  }

  /*Event*/
  .rbc-event {
    background-color: ${({ theme }) => theme.COLORS.BLUE};
    color: ${({ theme }) => theme.COLORS.WHITE};
    border: 0;
    border-radius: 3px;
    border-left: 3px solid ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .rbc-event-label {
    display: none;
  }
`;

export const EventWrapper = styled.div`
  .title {
    font-size: 14px;
  }

  .time {
    font-size: 12px;
  }
`;

export const ParamsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  z-index: 5;

  & > div {
    width: 240px;
    height: 50px;
    margin-right: 10px;
    border-radius: 20px;
    position: initial;

    .my-select__menu {
      z-index: 5;
    }

    .select {
      max-width: 190px;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }

  #service {
    width: 500px;

    .select {
      max-width: 450px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    & > div {
      width: 100%;
      margin-right: 0;

      .select {
        max-width: 100%;
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }
    }

    .filter {
      display: ${({ displayFilters }) => (displayFilters ? "flex" : "none")};
    }

    #service {
      width: 100%;

      .select {
        max-width: 100%;
      }
    }
  }
`;

export const LoaderWrapper = styled.div`
  width: calc(100vw - 250px);
  height: 100%;
  position: fixed;
  left: 250px;
  top: 0;
  padding-top: 300px;
  background-color: ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT};
  z-index: 5;
`;
