import useAsync from "../../useAsync";

import * as serviceApi from "../../../services/serviceApi";
import useToken from "../../useToken";

export default function useServiceFilters() {
  const token = useToken();

  const {
    data: serviceFilters,
    loading: serviceFiltersLoading,
    error: serviceFiltersError,
    act: getServiceFilters,
  } = useAsync((data) => serviceApi.getServiceFilters(data, token), false);

  return {
    serviceFilters,
    serviceFiltersLoading,
    serviceFiltersError,
    getServiceFilters,
  };
}
