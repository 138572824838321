import styled from "styled-components";

export const Container = styled.div`
  width: 700px;
  height: 990px;
  margin: 0 auto;

  iframe {
    width: 100%;
    height: 100%;
  }
`;
