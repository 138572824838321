import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  & > span {
    position: relative;
  }

  .extention {
    min-width: 200px;
    max-width: 200px;

    div {
      margin-right: 0 !important;
    }
  }

  .add-type {
    width: auto;
  }

  .divider {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
      margin-left: 10px;

      &:hover {
        color: ${({ theme }) => theme.COLORS.WHITE};
      }
    }
  }

  @media (max-width: 767px) {
    .extention {
      min-width: 100px;
      max-width: 100px;
    }
  }
`;
