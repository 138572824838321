import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { Container } from "./styles";
import theme from "../../styles/theme";

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

export default function TimePicker({ icon: Icon, handleForm, ...rest }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (!(value instanceof Date) || isNaN(value.getTime())) return;

    handleForm({
      name: rest.id,
      value,
    });
  }, [value]);

  function handleSelect(time) {
    const date = rest.date || new Date();

    setValue(
      new Date(
        date?.getFullYear(),
        date?.getMonth(),
        date?.getDate(),
        time.getHours(),
        time.getMinutes()
      )
    );
  }

  return (
    <Container theme={theme} type={rest.type} disabled={rest.disabled}>
      {Icon && <Icon size={20} />}
      <DatePicker
        selected={value}
        onChange={handleSelect}
        showTimeSelect
        showTimeSelectOnly
        timeInputLabel=""
        timeCaption="Hora"
        timeIntervals={5}
        dateFormat="HH:mm"
        input={false}
        className="calendar"
        id="time"
        placeholderText="Selecione um horário..."
        minTime={rest.minDate}
        maxTime={rest.maxDate}
        locale="pt-BR"
        {...rest}
      />
    </Container>
  );
}
