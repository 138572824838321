import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 105px;
  left: 0;
  width: 250px;
  height: 100vh;
  text-align: center;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY};
  z-index: 5;

  @media (max-width: 1023px) {
    border-right: 0;
    border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY};
    top: 210px;
    left: ${({ displayMenu }) =>
      displayMenu ? "calc(100vw - 250px)" : "calc(100vw)"};
  }

  @media (max-width: 767px) {
    top: 175px;
  }
`;

export const OptionWrapper = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY};
  background-color: ${({ theme, selected }) =>
    selected ? theme.COLORS.ORANGE : "transparent"};
  font-weight: ${({ selected }) => (selected ? 700 : 400)};

  a {
    width: 100%;
    height: 100%;
    line-height: 45px;
    text-decoration: none;
    color: ${({ theme, selected }) =>
      selected ? theme.COLORS.BACKGROUND_900 : theme.COLORS.WHITE};
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};

    a {
      color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
      font-weight: 700;
    }
  }
`;
