import { IoCloseCircle } from "react-icons/io5";

import { CloseButton, Container, Wrapper } from "./styles";
import theme from "../../styles/theme";
import Button from "../forms/service-form/Button";

export default function ConfirmationMessage({
  params,
  text,
  action,
  confirming,
  setConfirming,
  children,
  okMessage,
  hideCloseButton,
}) {
  return (
    <Container theme={theme} confirming={confirming}>
      <Wrapper theme={theme}>
        {!hideCloseButton && (
          <CloseButton
            theme={theme}
            onClick={() => setConfirming(false)}
            type="button"
          >
            <IoCloseCircle />
          </CloseButton>
        )}

        <h5>{text}</h5>

        {children}

        <span>
          {okMessage ? (
            <Button
              className="confirm"
              title="Ok"
              type="button"
              onClick={() => setConfirming(false)}
            />
          ) : (
            <>
              <Button
                className="cancel"
                title="Cancelar"
                type="button"
                onClick={() => setConfirming(false)}
              />
              <Button
                className="confirm"
                title="Confirmar"
                editing={true}
                type="button"
                onClick={() => action(params)}
              />
            </>
          )}
        </span>
      </Wrapper>
    </Container>
  );
}
