import styled from "styled-components";

export const ProductsReportsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    align-items: center;
    margin: 10px 0;

    svg {
      margin-left: 10px;
      cursor: pointer;
      width: 14px;
      height: 14px;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
    }
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    width: auto;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  p {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin-left: 20px;
  }
`;
