import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  color: ${({ theme }) => theme.COLORS.GRAY_300};

  margin-bottom: 8px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    height: 56px;
    width: 100%;

    padding: 12px;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background: transparent;
    border: 0;
    font-size: 18px;

    &:placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_300};
    }

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &:autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 10px 10px 0;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 10px 10px 0;
    }
  }

  svg {
    margin-left: 16px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    width: 22px;
    height: 22px;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;

    input[type="text"],
    input[type="password"],
    input[type="email"] {
      height: 46px;
      padding: 12px;
      font-size: 14px;
    }

    svg {
      margin-left: 12px;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }
  }
`;
