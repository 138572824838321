import ReactSelect from "react-select";

import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function Select({
  icon: Icon,
  placeholder,
  id,
  selected,
  enabled,
  list,
  form,
  setForm,
  handleForm,
  index,
  className,
}) {
  const customStyles = {
    singleValue: (styles) => ({
      ...styles,
      color: enabled ? theme.COLORS.WHITE : theme.COLORS.GRAY_100,
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.COLORS.BACKGROUND_900,
      className: "menu",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: theme.COLORS.WHITE,
      display: enabled ? "initial" : "none",
    }),
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      backgroundColor: "neutral10",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor:
        isSelected || isFocused ? theme.COLORS.ORANGE : "neutral10",
      color:
        isSelected || isFocused
          ? theme.COLORS.BACKGROUND_900
          : theme.COLORS.WHITE,
      fontWeight: isSelected || isFocused ? 700 : 400,
    }),
    value: (styles) => ({ ...styles, color: theme.COLORS.WHITE }),
  };

  return (
    <Container
      theme={theme}
      selected={selected}
      value={selected}
      disabled={!enabled}
      id={id}
      className={className}
    >
      {Icon && <Icon size={20} />}

      <div className="select" style={{ position: "relative" }}>
        <ReactSelect
          placeholder={
            <div
              className="placeholder"
              style={{
                color: `${
                  enabled ? theme.COLORS.GRAY_100 : theme.COLORS.GRAY_300
                }`,
              }}
            >
              {placeholder}
            </div>
          }
          value={
            selected.value || typeof selected.value === "boolean"
              ? selected
              : placeholder
          }
          options={list}
          styles={customStyles}
          classNamePrefix="my-select"
          isDisabled={!enabled}
          noOptionsMessage={() => "Nenhuma opção disponível"}
          onChange={(selectedOption) =>
            handleForm({
              name: id,
              value: selectedOption,
              form,
              setForm,
              index,
            })
          }
        />
      </div>
    </Container>
  );
}
