import api from "./api";

export async function postCompany(body, token) {
  const response = await api.post("/company", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getCompanyData(companyId, token) {
  const response = await api.get(`/company/${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getCompanies({ order, skip, take, typeId }, token) {
  const response = await api.get(
    `/company/many/${typeId}?order=${order}&skip=${skip}&take=${take}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getCompanyTypes(token) {
  const response = await api.get("/company/types", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getReceitaws(cnpj, token) {
  const response = await api.get(`/company/receitaws?cnpj=${cnpj}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteContact(contactId, token) {
  const response = await api.delete(`/company/contact/${contactId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteCredential(credentialId, token) {
  const response = await api.delete(
    `/company/external-credential/${credentialId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}
