import { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import { GoDotFill } from "react-icons/go";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Container, ItemWrapper, Users } from "./styles";
import noPicProfile from "../../../../assets/no-pic-profile.png";
import theme from "../../../../styles/theme";

export default function Timeline({ schedules }) {
  const [items, setItems] = useState([]);
  const timelineTheme = {
    primary: theme.COLORS.GRAY_100,
    secondary: theme.COLORS.ORANGE,
    cardBgColor: theme.COLORS.BACKGROUND_700,
    titleColor: theme.COLORS.GRAY_100,
    titleColorActive: theme.COLORS.BACKGROUND_700,
  };

  useEffect(() => {
    loadItems();
  }, [schedules]);

  async function loadItems() {
    const timelineItems = schedules.map((inspection, index) => ({
      title: dayjs(inspection.createdAt)
        .locale("pt-br")
        .format("dddd, DD/MM/YYYY (HH:mm)"),
      timelineContent: (
        <TimelineItem item={inspection} index={index} key={index} />
      ),
    }));

    setItems(timelineItems);
  }

  return (
    <Container theme={theme}>
      <h3>Hitórico da Vistoria</h3>
      <Chrono
        items={items}
        mode="VERTICAL"
        theme={timelineTheme}
        disableToolbar
        allowDynamicUpdate={true}
        scrollable={{ scrollbar: false }}
        disableClickOnCircle
        activeItemIndex={items.length - 1}
      />
    </Container>
  );
}

function TimelineItem({ item, index }) {
  return (
    <>
      {index === 0 ? (
        <ItemWrapper theme={theme} selected={item.selected}>
          <h1>SERVIÇO FINALIZADO</h1>

          <h2>Ordem de serviço emitida por:</h2>

          <div className="user">
            <img src={item.user.avatar || noPicProfile} alt="usuário" />

            <span>
              <h6>{item.user.name}</h6>
              <p>{item.user.role}</p>
            </span>
          </div>
        </ItemWrapper>
      ) : (
        <ItemWrapper theme={theme} selected={item.selected}>
          <h1>{item.status}</h1>

          <h2>Data da Vistoria:</h2>

          <p className="li">
            {dayjs(item.scheduledFor)
              .locale("pt-br")
              .format("dddd, DD/MM/YYYY (HH:mm)")}
          </p>

          <h2>Serviços Incluídos:</h2>

          {item.products.map((product, index) => (
            <span className="li" key={index}>
              <GoDotFill />
              <p>{product}</p>
            </span>
          ))}

          <h2>Responsáveis:</h2>

          <Users>
            <div>
              <img
                src={item.coordinator.avatar || noPicProfile}
                alt="usuário"
              />

              <p>{item.coordinator.name}</p>

              <h3>Coordenador</h3>
            </div>

            <div>
              <img src={item.inspector.avatar || noPicProfile} alt="usuário" />

              <p>{item.inspector.name}</p>

              <h3>Vistoriador</h3>
            </div>
          </Users>
        </ItemWrapper>
      )}
    </>
  );
}
