import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";

import theme from "../../../../styles/theme";
import { Container } from "./styles";
import noPicProfile from "../../../../assets/no-pic-profile.png";

export default function MultiSelect({
  icon: Icon,
  placeholder,
  id,
  selected,
  enabled,
  list,
  setForm,
  className,
}) {
  const customStyles = {
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.COLORS.BACKGROUND_900,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: theme.COLORS.WHITE,
      display: enabled ? "initial" : "none",
    }),
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      backgroundColor: "neutral10",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor:
        isSelected || isFocused ? theme.COLORS.ORANGE : "neutral10",
      color:
        isSelected || isFocused
          ? theme.COLORS.BACKGROUND_900
          : theme.COLORS.WHITE,
      fontWeight: isSelected || isFocused ? 700 : 400,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: theme.COLORS.ORANGE,
      color: theme.COLORS.WHITE,
      borderRadius: "10px",
      paddingLeft: "5px",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: theme.COLORS.WHITE,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: theme.COLORS.WHITE,
      cursor: "pointer",
      ":hover": { color: theme.COLORS.WHITE },
    }),
  };

  const animatedComponents = makeAnimated();

  return (
    <Container
      theme={theme}
      selected={selected}
      value={selected}
      disabled={!enabled}
      id={id}
      className={className}
    >
      {Icon && <Icon size={20} />}

      <div className="select" style={{ position: "relative" }}>
        <ReactSelect
          value={selected}
          placeholder={
            <div
              className="placeholder"
              style={{
                color: `${
                  enabled ? theme.COLORS.GRAY_100 : theme.COLORS.GRAY_300
                }`,
              }}
            >
              {placeholder}
            </div>
          }
          options={list}
          styles={customStyles}
          isDisabled={!enabled}
          noOptionsMessage={() => "Nenhuma opção disponível"}
          isMulti
          closeMenuOnSelect={false}
          components={animatedComponents}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          formatOptionLabel={(option, { context }) =>
            context === "menu" ? (
              <div className="option">
                <img src={option.avatar || noPicProfile} alt="convidado" />
                <p>{option.name}</p>
              </div>
            ) : (
              option.name
            )
          }
          onChange={(selectedOption) => setForm(selectedOption)}
        />
      </div>
    </Container>
  );
}
