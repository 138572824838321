import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin: 10px auto 58px;
  width: 254px !important;
  height: 254px !important;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  label {
    width: 48px;
    height: 48px;
    background-color: ${({ theme, enabled }) =>
      enabled ? theme.COLORS.ORANGE : theme.COLORS.GRAY_300};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 7px;
    right: 7px;
    cursor: ${({ enabled }) => (enabled ? "pointer" : "initial")};

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  label:hover {
    background-color: ${({ theme, enabled }) =>
      enabled ? theme.COLORS.WHITE : theme.COLORS.GRAY_300};

    svg {
      color: ${({ theme, enabled }) =>
        enabled ? theme.COLORS.ORANGE : theme.COLORS.WHITE};
    }
  }
`;
