import { useEffect, useState } from "react";
import { IoWarning } from "react-icons/io5";

import theme from "../../../../styles/theme";
import { Container, Row } from "./styles";
import ChecklistSelect from "../../../../components/forms/report-form/ChecklistSelect";

export default function ChecklistSummary({
  sections,
  options,
  answers,
  setAnswers,
  enabled,
}) {
  return (
    <Container theme={theme}>
      <Row className="title" theme={theme}>
        <span className="index">Item</span>
        <span className="description">Planos / Documentos</span>
        <span className="review desktop">REV.</span>
        <span className="review mobile">REV.</span>
        <span className="status desktop">SITUAÇÃO</span>
        <span className="status mobile">SIT.</span>
      </Row>

      {sections.map((item, index) => {
        return (
          <ChecklistItem
            key={index}
            item={item}
            index={index + 1}
            options={options}
            answers={answers}
            setAnswers={setAnswers}
            enabled={enabled}
          />
        );
      })}
    </Container>
  );
}

function ChecklistItem({ item, index, options, answers, setAnswers, enabled }) {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(answers[item.id]?.status);
  }, [answers[item.id]?.status.value]);

  function handleSelect({ name, value, form, setForm }) {
    setSelected(value);
    setForm({ ...form, [name]: { ...form[name], status: value } });
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: { ...form[name], rev: value } });
  }

  return (
    <>
      <Row key={index} theme={theme} enabled={enabled}>
        <span className="index">{index}</span>
        <span className="description">
          <span>
            <p>{item.name}</p>
            {answers[item.id]?.warning && (
              <IoWarning
                title={`${answers[item.id].warning} ite${
                  answers[item.id].warning > 1 ? "ns" : "m"
                } em exigência`}
              />
            )}
          </span>
        </span>
        <span className="review">
          <input
            id={item.id}
            type="text"
            value={answers[item.id]?.rev}
            onChange={(e) =>
              handleForm({
                name: e.target.id,
                value: e.target.value,
                form: answers,
                setForm: setAnswers,
              })
            }
          />
        </span>
        <span className="status">
          <ChecklistSelect
            id={item.id}
            placeholder="Selecione..."
            enabled={enabled}
            list={options}
            selected={selected}
            required
            form={answers}
            setForm={setAnswers}
            handleForm={handleSelect}
          />
        </span>
      </Row>
    </>
  );
}
