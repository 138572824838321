import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function useChangePassword() {
  const {
    loading: changePasswordLoading,
    error: changePasswordError,
    act: changePassword,
  } = useAsync((data) => authenticationApi.changePasssword(data), false);

  return {
    changePasswordLoading,
    changePasswordError,
    changePassword,
  };
}
