import styled from "styled-components";

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 50px 0;

  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;
