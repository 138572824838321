import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";
import React from "react";
import { useState } from "react";

import { Background, Form } from "../styles";
import { Input } from "../../../components/forms/authentication-form/Input";
import { Button } from "../../../components/forms/authentication-form/Button";
import theme from "../../../styles/theme";
import usePasswordChangeEmail from "../../../hooks/api/authentication/usePasswordChangeEmail";
import toastUtils from "../../../utils/toast-utils";
import Title from "../../../components/forms/authentication-form/Title";

export default function EmailVerification() {
  const { getPasswordChangeEmail } = usePasswordChangeEmail();
  const [email, setEmail] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await getPasswordChangeEmail(email);
      setEmail("");
      toastUtils.toaster({
        message: "Link enviado para e-mail!",
        type: toastUtils.type.success,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      setEmail("");
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <>
      <Form theme={theme} onSubmit={handleSubmit}>
        <Title />

        <h2> Altere sua senha </h2>

        <span>
          <p>
            Insira seu e-mail abaixo para receber o link de alteração de senha.
          </p>
        </span>

        <Input
          placeholder="E-mail"
          type="email"
          name="email"
          value={email}
          required
          icon={FiMail}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button title="Enviar E-mail" />

        <Link to="/">Voltar para o login</Link>
        <Link to="/sign-up">Primeiro login? Faça o cadastro aqui</Link>
      </Form>
      <Background theme={theme} />
    </>
  );
}
