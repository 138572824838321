import { useState } from "react";

import { Section } from "../../../../components/Section";
import { Container } from "../styles";
import Product from "./Product";
import theme from "../../../../styles/theme";

export default function Certification() {
  const [title, setTitle] = useState("Cadastro/Produtos/Certificações");

  return (
    <Container theme={theme}>
      <Section title={title}>
        <Product title={title} setTitle={setTitle} />
      </Section>
    </Container>
  );
}
