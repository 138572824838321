import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useInspectionChecklists() {
  const token = useToken();

  const {
    data: inspectionChecklists,
    loading: inspectionChecklistsLoading,
    error: inspectionChecklistsError,
    act: getInspectionChecklists,
  } = useAsync((data) => reportApi.getInspectionChecklists(data, token), false);

  return {
    inspectionChecklists,
    inspectionChecklistsLoading,
    inspectionChecklistsError,
    getInspectionChecklists,
  };
}
