import useAsync from "../../useAsync";

import * as companyApi from "../../../services/companyApi";
import useToken from "../../useToken";

export default function useSaveCompany() {
  const token = useToken();

  const {
    loading: saveCompanyLoading,
    error: saveCompanyError,
    act: saveCompany,
  } = useAsync((data) => companyApi.postCompany(data, token), false);

  return {
    saveCompanyLoading,
    saveCompanyError,
    saveCompany,
  };
}
