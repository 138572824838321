import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ServiceContainer } from "./styles";
import theme from "../../../../styles/theme";
import toastUtils from "../../../../utils/toast-utils";
import { Container } from "../styles";
import { Section } from "../../../../components/Section";
import serviceUtils from "../../../../utils/service-utils";
import useRequestOptions from "../../../../hooks/api/request/useRequestOptions";
import useSaveRequest from "../../../../hooks/api/request/useSaveRequest";
import useRequest from "../../../../hooks/api/request/useRequest";
import RequestForm from "../../../../components/forms/service-form/ServiceForm";
import RequestInfo from "../../../../components/forms/service-form/RequestInfo";

export default function RequestedService({ type }) {
  const { serviceId } = useParams();
  const status = Number(useLocation().search.replace("?status=", ""));
  const path = useLocation().pathname;
  const baseTitle = path.includes("comercial")
    ? "Comercial"
    : "Serviços/Serviços Solicitados";
  const newRequest = isNaN(serviceId);
  const allowed = newRequest;
  const navigate = useNavigate();
  const { getRequestOptions } = useRequestOptions();
  const { getRequest } = useRequest();
  const { saveRequest } = useSaveRequest();
  const [title, setTitle] = useState(baseTitle);
  const [options, setOptions] = useState(serviceUtils.emptyServiceOptions);
  const [allOptions, setAllOptions] = useState(
    serviceUtils.emptyServiceOptions
  );
  const [form, setForm] = useState(serviceUtils.emptyServiceForm);
  const [info, setInfo] = useState(serviceUtils.emptyService);
  const [companyList, setCompanyList] = useState([]);
  const [vesselList, setVesselList] = useState([]);
  const [checked, setChecked] = useState({});
  const [checklists, setChecklists] = useState({});

  useEffect(() => {
    loadRequestData();
  }, []);

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  function handleCheck({ name, value, serviceId, serviceName, activityId }) {
    let result = [...form.products];

    if (serviceId === value) {
      if (checked[value]) {
        result = result.filter((product) => product.serviceId !== value);

        let toUncheck = { ...checked, [value]: false };

        const optionList =
          activityId === options.certificationOptions.activityId
            ? options.certificationOptions.services
            : options.projectOptions.services;

        const currentServiceProducts = optionList.filter(
          (service) => service.serviceId === serviceId
        )[0].products;

        currentServiceProducts.forEach(
          (product) =>
            (toUncheck = { ...toUncheck, [product.productId]: false })
        );

        setChecked(toUncheck);
      } else {
        setChecked({ ...checked, [value]: name });
      }
    } else {
      if (checked[value]) {
        result = result.filter((product) => product.productId !== value);

        setChecked({ ...checked, [value]: false });
      } else {
        result = [
          ...result,
          {
            productId: value,
            productName: name,
            serviceId,
            serviceName,
            obs: "",
          },
        ];

        setChecked({ ...checked, [value]: name });
      }
    }

    setForm({ ...form, products: result });
  }

  async function loadRequestData() {
    try {
      const response = await getRequestOptions();

      if (response) {
        setCompanyList(response.companies);
        setVesselList(response.vessels);
        setOptions({
          ...options,
          certificationOptions: response.certificationOptions,
          projectOptions: response.projectOptions,
        });

        setAllOptions({
          ...options,
          certificationOptions: response.certificationOptions,
          projectOptions: response.projectOptions,
        });

        if (newRequest) {
          setTitle(`${title}/Nova solicitação`);
          return;
        }

        const service = await getRequest({ serviceId, status });

        if (service) {
          setInfo(service);

          setTitle(
            `${baseTitle}/${service.id} - ${service.type.typeName} - ${service.company.label} - ${service.vessel.label}`
          );
        }
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const body = {
        obs: form.obs,
        forms: form.reports,
        products: form.products,
        company: form.company.value,
        vessel: form.vessel.value,
        status,
        type,
        id: newRequest ? 0 : serviceId,
      };

      await saveRequest(body);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      navigate("/painel/servicos/solicitados");
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function checkProducts() {
    let toCheck = {};

    form.products.forEach((item) => {
      if (!toCheck[item.serviceId]) {
        if (Math.trunc(item.serviceId / 100) === 1) {
          if (
            options.certificationOptions.services.find(
              (service) => service.serviceId === item.serviceId
            )?.products.length !== 1
          ) {
            toCheck = {
              ...toCheck,
              [item.serviceId]: item.serviceName,
            };
          }
        } else {
          if (
            options.projectOptions.services.find(
              (service) => service.serviceId === item.serviceId
            )?.products.length !== 1
          ) {
            toCheck = {
              ...toCheck,
              [item.serviceId]: item.serviceName,
            };
          }
        }
      }

      toCheck = {
        ...toCheck,
        [item.productId]: item.productName,
      };
    });

    setChecked(toCheck);
  }

  return (
    <Container theme={theme}>
      <Section title={title}>
        <ServiceContainer theme={theme}>
          {newRequest ? (
            <RequestForm
              handleSubmit={handleSubmit}
              handleCheck={handleCheck}
              checkProducts={checkProducts}
              handleForm={handleForm}
              form={form}
              setForm={setForm}
              allowed={allowed}
              companyList={companyList}
              vesselList={vesselList}
              options={options}
              setOptions={setOptions}
              allOptions={allOptions}
              checked={checked}
              setChecked={setChecked}
              newRequest={newRequest}
              checklists={checklists}
              setChecklists={setChecklists}
              type={type}
            />
          ) : (
            <RequestInfo info={info} options={options} />
          )}
        </ServiceContainer>
      </Section>
    </Container>
  );
}
