import { useEffect, useState } from "react";

import theme from "../../../../styles/theme";
import CheckBox from "../CheckBox";
import { Container, ProductComment } from "./styles";

export default function ProductPicker({
  label,
  id,
  form,
  setForm,
  checked,
  handleCheck,
  serviceId,
  serviceName,
  disabled,
}) {
  const isChecked = checked[id] ? true : false;
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    setIndex(form.products.findIndex((product) => product.productId === id));
  }, [form]);

  function handleComment(value) {
    const products = [...form.products];

    products[index] = {
      ...products[index],
      obs: value,
    };

    setForm({
      ...form,
      products: products,
    });
  }

  return (
    <Container>
      <CheckBox
        label={label}
        id={id}
        form={checked}
        handleCheck={handleCheck}
        serviceId={serviceId}
        serviceName={serviceName}
        disabled={disabled}
      />

      {isChecked && (
        <ProductComment
          theme={theme}
          type="text"
          placeholder="Observações..."
          value={form.products[index]?.obs || ""}
          onChange={(e) => handleComment(e.target.value)}
          disabled={disabled}
        />
      )}
    </Container>
  );
}
