import styled from "styled-components";

export const RequestDisplayer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin: 4px 0 0 10px;
    cursor: pointer;
  }

  h5 {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin: 0;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 50px 0;

  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;
