import api from "./api";

export async function getVessels({ order, skip, take }, token) {
  const response = await api.get(
    `/vessel/many?order=${order}&skip=${skip}&take=${take}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getVesselData(vesselId, token) {
  const response = await api.get(`/vessel/${vesselId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postVessel(body, token) {
  const response = await api.post("/vessel", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getVesselFilters(token) {
  const response = await api.get("/vessel/filters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteEngine(engineId, token) {
  const response = await api.delete(`/vessel/engine/${engineId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
