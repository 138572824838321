import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  .submit,
  .cancel {
    position: absolute;
    bottom: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit {
    right: 0;
    width: 220px;
  }

  .cancel {
    right: 230px;
    width: 200px;
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  .update {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -60px;
    right: 0;
  }

  .qualification-form {
    margin-bottom: ${({ editing }) => (editing ? "80px" : "10px")};
  }

  @media (max-width: 767px) {
    margin-top: 40px;

    .submit,
    .cancel {
      width: calc(50% - 5px);
    }

    .cancel {
      left: 0;
    }
  }
`;

export const InspectorContainer = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 30px 0 0 0;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .subitem > div {
    margin-left: 30px;
    position: relative;
  }

  .product {
    margin-left: 20px !important;
  }

  @media (max-width: 767px) {
    & > div {
      flex-direction: column;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
      margin: 20px 0;
    }
  }
`;

export const InspectorWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;

  img {
    width: 240px;
    height: 240px;
    border-radius: 50%;
  }

  h5 {
    margin: 5px 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    font-size: 16px;
  }

  h6 {
    margin: 0 0 30px 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    font-size: 14px;
    font-weight: 400;
  }

  div {
    max-width: 50%;
  }

  div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      margin: 30px 0 16px 0;
      width: 100%;
      text-align: center;
    }

    h6 {
      text-align: center;
    }
  }

  div:last-child {
    width: 40%;
  }

  @media (max-width: 767px) {
    flex-direction: column;

    div {
      width: 100% !important;
      max-width: 100%;
    }

    div:first-child {
      margin: 0;
      width: 100%;
    }
  }
`;
