import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Section } from "../../../../components/Section";
import { Container } from "../../styles";
import Directories from "../../../../components/Directories";
import generalUtils from "../../../../utils/general-utils";
import theme from "../../../../styles/theme";

export default function Products() {
  const search = useLocation().search.replace("?search=", "");
  const directories = [
    { text: "CERTIFICAÇÕES", route: "certificacao" },
    { text: "PROJETOS", route: "projeto" },
  ];
  const [dirList, setDirList] = useState(directories);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  return (
    <Section title="Cadastro/Produtos">
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
