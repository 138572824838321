import { Info, Container } from "./styles";
import theme from "../../../../styles/theme";

export default function ReportInfo({ info }) {
  return (
    <Container theme={theme}>
      {info.map((item, index) => (
        <Info key={index} theme={theme}>
          <h5>{`${item.label}:`}</h5>
          <p>{item.value}</p>
        </Info>
      ))}
    </Container>
  );
}
