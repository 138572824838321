import styled from "styled-components";

export const Container = styled.input`
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) => theme.COLORS.ORANGE};
  color: ${({ theme }) => theme.COLORS.WHITE};
  cursor: pointer;
  border: 0;
  padding: 0 16px;
  margin: 16px 0;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    opacity: 80%;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 42px;
    margin: 12px 0;
    font-size: 18px;
  }
`;
