import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    min-height: 30px;
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_600};

    span {
      font-size: 15px;
      align-items: center;
    }
  }

  @media (max-width: 1023px) {
    .title {
      span {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    .title {
      span {
        font-size: 12px;
      }
    }
  }
`;

export const Row = styled.span`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  border-top: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};
  color: ${({ theme }) => theme.COLORS.WHITE};

  span {
    display: flex;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
    justify-content: center;
    padding: 5px;
    font-size: 14px;
    flex-wrap: wrap;
    word-break: break-word;

    &:first-child {
      border-left: none;
    }
  }

  .index {
    width: 6%;
    justify-content: left;
    padding: 2px;
  }

  .description {
    width: 70%;
    justify-content: left;
    align-items: baseline;
    position: relative;

    p {
      font-size: 14px;
      margin: 0;
      max-width: 94%;
    }

    span {
      flex-wrap: nowrap;
      width: 100%;
      padding-left: ${({ isSubitem }) => (isSubitem ? "20px" : "5")};
      margin-top: 7px;
      justify-content: space-between;

      svg {
        width: 25px;
        height: 25px;
        margin: 0;
        color: ${({ theme }) => theme.COLORS.ORANGE};
        cursor: default;
      }
    }

    svg {
      margin-left: 10px;
      margin-top: 2px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }

  .review {
    width: 10%;
    text-align: center;

    input {
      width: 100%;
      background-color: transparent;
      border: none;
      text-align: center;
      font-size: 14px;
      color: ${({ theme }) => theme.COLORS.WHITE};

      &:placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_300};
      }

      &:focus {
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
      }

      &:autofill {
        box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900}
          inset;
        color: ${({ theme }) => theme.COLORS.WHITE}!important;
        border-radius: 0 10px 10px 0;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px
          ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
        -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .check-box {
    width: 6%;
  }

  .status {
    width: 18%;
    max-width: 18%;
    min-width: 18%;
    padding: 0;
  }

  @media (max-width: 1023px) {
    .description {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    min-height: 52px;

    span {
      font-size: 12px;
    }

    .review {
      font-size: 10px;
      width: 12%;
      min-width: 12%;
      max-width: 12%;
    }

    .description {
      font-size: 13px;

      span {
        flex-wrap: nowrap;
      }
    }

    .status {
      width: 18%;
      min-width: 18%;
      max-width: 18%;
      padding: 0;
    }

    .index {
      width: 10%;
      min-width: 10%;
      max-width: 10%;
      padding: 2px;
      justify-content: center;
      font-size: 11px;
    }
  }
`;
