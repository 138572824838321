import { Link, useLocation, useParams } from "react-router-dom";
import { useContext } from "react";

import theme from "../../styles/theme";
import { Container, OptionWrapper } from "./styles";
import UserContext from "../../contexts/UserContext";

export default function Menu({ displayMenu, setDisplayMenu }) {
  const { userData } = useContext(UserContext);

  return (
    <Container theme={theme} displayMenu={displayMenu}>
      {userData.permission && (
        <>
          <MenuOption
            name="COMERCIAL"
            path="comercial"
            setDisplayMenu={setDisplayMenu}
          />
          <MenuOption
            name="AGENDA"
            path="agenda"
            setDisplayMenu={setDisplayMenu}
          />
          <MenuOption
            name="SERVIÇOS"
            path="servicos"
            setDisplayMenu={setDisplayMenu}
          />
          <MenuOption
            name="CADASTRO"
            path="cadastro"
            setDisplayMenu={setDisplayMenu}
          />
          <MenuOption
            name="RELATÓRIOS"
            path="relatorios"
            setDisplayMenu={setDisplayMenu}
          />
          <MenuOption
            name="VISTORIAS"
            path="vistorias"
            setDisplayMenu={setDisplayMenu}
          />
        </>
      )}
    </Container>
  );
}

function MenuOption({ name, path, setDisplayMenu }) {
  const pathName = useLocation().pathname;
  const params = useParams();
  const { userData } = useContext(UserContext);

  return (
    <OptionWrapper
      onClick={() => setDisplayMenu(false)}
      theme={theme}
      selected={
        pathName.includes(`painel/${path}`) &&
        Number(params.userId) !== userData.user.id
      }
    >
      <Link to={path}>{name}</Link>
    </OptionWrapper>
  );
}
