import { BsBriefcase } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import { useState } from "react";

import theme from "../../../../styles/theme";
import { CloseButton, Container, Wrapper } from "./styles";
import Input from "../Input";
import Button from "../Button";
import toastUtils from "../../../../utils/toast-utils";

export default function AddRole({
  managerForm,
  setManagerForm,
  handleForm,
  roles,
  setRoles,
  addingRole,
  setAddingRole,
}) {
  const [form, setForm] = useState({
    newRole: "",
    roleObs: "",
  });

  function handleSubmit(e) {
    e.preventDefault();

    if (roles.find((role) => role.label === form.role)) {
      toastUtils.toaster({
        message: "Esta função já existe!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }

    const newRole = { label: form.newRole, value: "x" };

    setRoles([...roles, newRole]);
    setManagerForm({
      ...managerForm,
      role: newRole,
      roleObs: form.roleObs,
    });
    setAddingRole(false);
    setForm({
      role: "",
      roleObs: "",
    });

    toastUtils.toaster({
      message: "Função criada com sucesso!",
      type: toastUtils.type.success,
      position: toastUtils.position.topCenter,
      theme: toastUtils.theme.colored,
    });
  }

  return (
    <Container theme={theme} addingRole={addingRole}>
      <Wrapper theme={theme}>
        <CloseButton theme={theme} onClick={() => setAddingRole(false)}>
          <IoCloseCircle />
        </CloseButton>

        <h5>Adicionar Função</h5>

        <form onSubmit={handleSubmit}>
          <Input
            placeholder="Nova função"
            type="text"
            id="newRole"
            value={form.newRole}
            required
            icon={BsBriefcase}
            pattern="^[A-ZÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÇ\s]+$"
            onChange={(e) =>
              handleForm({
                name: e.target.id,
                value: e.target.value.toUpperCase(),
                form,
                setForm,
              })
            }
          />

          <textarea
            placeholder="Observações..."
            id="roleObs"
            value={form.roleObs}
            onChange={(e) =>
              handleForm({
                name: e.target.id,
                value: e.target.value,
                form,
                setForm,
              })
            }
          />

          <Button title="Adicionar" editing={true} enabled={true} />
        </form>
      </Wrapper>
    </Container>
  );
}
