import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  @media (max-width: 767px) {
    margin-top: 40px;

    div {
      width: 100%;
    }
  }
`;

export const InspectorContainer = styled.div`
  height: 90% !important;

  h5 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  img {
    margin-top: 14px;
  }

  @media (max-width: 767px) {
    img {
      margin-top: 0;
    }

    .mobile {
      margin: 0 10px;
    }
  }
`;

export const Form = styled.form`
  min-width: 400px;
  margin: 30px auto 0 !important;

  & > div {
    width: 100%;

    & > span {
      position: relative;
    }
  }

  @media (max-width: 1023px) {
    min-width: 300px;
    margin: 30px 0 0;
    width: 100% !important;
  }
`;

export const Edit = styled.button`
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  font-size: 16px;
  position: absolute;
  background-color: transparent;
  border: none;
  right: 0;
  top: 0;
  padding: 0;
  cursor: pointer;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 30px 0;
`;

export const FormsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  position: relative;
  flex-direction: column;

  @media (max-width: 1023px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;

  & > span {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    height: 108px;
  }

  .no-label {
    height: 80px;
  }

  span {
    width: 100%;
    flex-direction: column;
    margin-left: 10px;
  }

  span:first-child {
    margin-left: 0;
  }

  .number,
  .uf {
    max-width: 200px;
    min-width: 200px;
  }

  @media (max-width: 767px) {
    & > span {
      height: 94px;
    }

    .number,
    .uf {
      max-width: 120px;
      min-width: 120px;

      .select {
        width: 80px;
      }
    }
  }
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    position: relative;
  }
`;
