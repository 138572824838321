import { useParams } from "react-router-dom";
import { useState } from "react";

import { Section } from "../../../../components/Section";
import { Container } from "../styles";
import theme from "../../../../styles/theme";
import Checklist from "../../../../components/forms/report-form/Checklist";
import reportUtils from "../../../../utils/report-utils";

export default function ConsultChecklist() {
  const { formId } = useParams();
  const enabled = false;
  const [title, setTitle] = useState("Relatórios/Checklists");
  const [checklist, setChecklist] = useState(reportUtils.emptyChecklist);
  const [answers, setAnswers] = useState({});
  const [summary, setSummary] = useState({});

  return (
    <Section title={title}>
      <Container theme={theme}>
        <Checklist
          formId={formId}
          enabled={enabled}
          title={title}
          setTitle={setTitle}
          checklist={checklist}
          setChecklist={setChecklist}
          answers={answers}
          setAnswers={setAnswers}
          summary={summary}
          setSummary={setSummary}
        />
      </Container>
    </Section>
  );
}
