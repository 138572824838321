import CheckBox from "../CheckBox";
import { Container } from "./styles";

export default function QualificationPicker({
  label,
  id,
  checked,
  handleCheck,
  serviceId,
  serviceName,
  disabled,
}) {
  return (
    <Container>
      <CheckBox
        label={label}
        id={id}
        form={checked}
        handleCheck={handleCheck}
        serviceId={serviceId}
        serviceName={serviceName}
        disabled={disabled}
      />
    </Container>
  );
}
