import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .submit,
  .cancel {
    position: absolute;
    right: 0;
    bottom: -100px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    right: 210px;
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  @media (max-width: 767px) {
    margin-top: 40px;

    div {
      width: 100%;
    }

    h3 {
      font-size: 22px;
    }

    .submit,
    .cancel {
      width: calc(50% - 5px);
    }

    .cancel {
      left: 0;
    }
  }
`;

export const Edit = styled.button`
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  font-size: 16px;
  position: absolute;
  background-color: transparent;
  border: none;
  right: 0;
  top: 0;
  padding: 0;
  cursor: pointer;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 30px 0;
`;

export const Form = styled.form`
  min-width: 400px;
  margin-top: 30px;

  & > div {
    width: 100%;

    & > span {
      position: relative;
    }
  }

  @media (max-width: 1023px) {
    min-width: 300px;
    margin: 30px 0 0;
    width: 100% !important;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ className }) =>
    className === "reverse" ? "column" : "row"};

  & > span {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    height: 108px;
  }

  .no-label {
    height: 80px;
  }

  span {
    width: 100%;
    flex-direction: column;
    margin-left: ${({ className }) => (className === "reverse" ? "0" : "10px")};
  }

  span:first-child {
    margin-left: 0;
  }

  .date,
  .order {
    max-width: 200px;
    min-width: 200px;
  }

  .additionalInfo {
    height: 216px;
    margin-right: 10px;

    div:last-child {
      height: 166px;
    }
  }

  .rdo-obs {
    height: 170px;
  }

  @media (max-width: 767px) {
    & > span {
      height: 94px;
    }

    .number {
      max-width: 120px;
      min-width: 120px;

      .select {
        width: 80px;
      }
    }
  }
`;

export const FormsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  position: relative;
  flex-direction: column;
  margin-bottom: 100px;

  .reverse {
    max-width: 140px;
    min-width: 140px;
  }

  @media (max-width: 1023px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const AddSubitem = styled.div`
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;

  &:hover {
    svg,
    p {
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  p {
    font-size: 18px;
    margin: 0;
  }
`;
