import { FiCamera } from "react-icons/fi";

import theme from "../../../../styles/theme";
import { Container } from "./styles";
import noPicProfile from "../../../../assets/no-pic-profile.png";

export default function Avatar({ userForm, setUserForm, enabled }) {
  function handleFileChange(e) {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserForm({ ...userForm, avatar: reader.result });
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <Container theme={theme} enabled={enabled}>
      <img
        src={userForm.avatar ? userForm.avatar : noPicProfile}
        alt="Foto do usuário"
      />

      <label htmlFor="avatar">
        <FiCamera />

        <input
          id="avatar"
          name="avatar"
          type="file"
          accept="image/*"
          disabled={!enabled}
          onChange={handleFileChange}
        />
      </label>
    </Container>
  );
}
