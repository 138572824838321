import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useSaveDailyReport() {
  const token = useToken();

  const {
    loading: saveDailyReportLoading,
    error: saveDailyReportError,
    act: saveDailyReport,
  } = useAsync((data) => reportApi.postDailyReport(data, token), false);

  return {
    saveDailyReportLoading,
    saveDailyReportError,
    saveDailyReport,
  };
}
