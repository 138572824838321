import useAsync from "../../useAsync";

import * as calendarApi from "../../../services/calendarApi";
import useToken from "../../useToken";

export default function useDeleteEvent() {
  const token = useToken();

  const {
    loading: deleteEventLoading,
    error: deleteEventError,
    act: deleteEvent,
  } = useAsync((data) => calendarApi.deleteEvent(data, token), false);

  return {
    deleteEventLoading,
    deleteEventError,
    deleteEvent,
  };
}
