import useAsync from "../../useAsync";
import * as userApi from "../../../services/userApi";
import useToken from "../../useToken";

export default function useUserData() {
  const token = useToken();

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
    act: getUserData,
  } = useAsync((data) => userApi.getUserData(data, token), false);

  return {
    userData,
    userDataLoading,
    userDataError,
    getUserData,
  };
}
