import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function EventInput({ ...rest }) {
  return (
    <Container theme={theme} disabled={rest.disabled}>
      <input {...rest} />
    </Container>
  );
}
