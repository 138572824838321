import React, { useContext, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import UserContext from "../../contexts/UserContext";
import { FiSearch } from "react-icons/fi";
import { Container, Brand, Content } from "./styles";
import theme from "../../styles/theme";
import { Header } from "../../components/Header";
import { SearchBar } from "../../components/forms/search-bar";
import Menu from "../../components/Menu";
import noPicProfile from "../../assets/no-pic-profile.png";

export default function Home() {
  const [search, setSearch] = useState("");
  const [displayMenu, setDisplayMenu] = useState(false);
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const params = useParams();

  function handleSearch(e) {
    e.preventDefault();

    if (search) navigate(`?search=${search}`);
    else navigate(path);
  }

  return (
    <Container theme={theme} path={path}>
      <Brand
        displayMenu={displayMenu}
        theme={theme}
        selected={
          path.includes("perfil") && Number(params.userId) === userData.user.id
        }
      >
        <img
          alt="Profile"
          src={userData.user.avatar ? userData.user.avatar : noPicProfile}
          onClick={() => navigate(`perfil/${userData.user.id}`)}
        />
        <h2 onClick={() => navigate(`perfil/${userData.user.id}`)}>
          {userData.user.name}
        </h2>
      </Brand>

      <Header displayMenu={displayMenu} setDisplayMenu={setDisplayMenu} />

      <Menu displayMenu={displayMenu} setDisplayMenu={setDisplayMenu} />

      <SearchBar
        placeholder="Pesquisar"
        icon={FiSearch}
        search={search}
        setSearch={setSearch}
        handleSearch={handleSearch}
      />

      <Content path={path}>
        <Outlet />
      </Content>
    </Container>
  );
}
