import { useLocation } from "react-router-dom";

import theme from "../../../styles/theme";
import { Container } from "./styles";

export function SearchBar({
  icon: Icon,
  search,
  setSearch,
  handleSearch,
  ...rest
}) {
  const path = useLocation().pathname;

  return (
    <Container theme={theme} path={path}>
      {Icon && <Icon size={20} />}
      <form onSubmit={handleSearch}>
        <input
          type="text"
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          {...rest}
        />
      </form>
    </Container>
  );
}
