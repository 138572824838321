import { Chrono } from "react-chrono";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Container, ItemWrapper } from "./styles";
import { useEffect, useState } from "react";
import theme from "../../../../styles/theme";
import useRequestHistory from "../../../../hooks/api/request/useRequestHistory";
import toastUtils from "../../../../utils/toast-utils";
import serviceUtils from "../../../../utils/service-utils";
import noPicProfile from "../../../../assets/no-pic-profile.png";

export default function Timeline({ serviceId, status }) {
  const [items, setItems] = useState([]);
  const { getRequestHistory } = useRequestHistory();

  const timelineTheme = {
    primary: theme.COLORS.GRAY_100,
    secondary: theme.COLORS.ORANGE,
    cardBgColor: theme.COLORS.BACKGROUND_700,
    titleColor: theme.COLORS.GRAY_100,
    titleColorActive: theme.COLORS.BACKGROUND_700,
  };

  useEffect(() => {
    loadItems();
  }, [status]);

  async function loadItems() {
    try {
      const response = await getRequestHistory(serviceId);

      if (response) {
        const timelineItems = response.map((request, index) => ({
          title: dayjs(request.createdAt)
            .locale("pt-br")
            .format("dddd, DD/MM/YYYY (HH:mm)"),
          timelineContent: <TimelineItem item={request} key={index} />,
        }));

        setItems(timelineItems);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <h3>Hitórico da Solicitação</h3>
      <Chrono
        items={items}
        mode="VERTICAL"
        theme={timelineTheme}
        disableToolbar
        allowDynamicUpdate={true}
        scrollable={{ scrollbar: false }}
        disableClickOnCircle
        activeItemIndex={items.length - 1}
      />
    </Container>
  );
}

function TimelineItem({ item }) {
  return (
    <ItemWrapper theme={theme} selected={item.selected}>
      <h1>{item.status.name}</h1>

      <h2>
        {item.status.id === serviceUtils.status.SOLICITADO
          ? "Solicitante:"
          : "Responsável:"}
      </h2>

      <div className="user">
        <img src={item.user.avatar || noPicProfile} alt="usuário" />

        <span>
          <h6>{item.user.name}</h6>
          <p>{item.user.role}</p>
        </span>
      </div>
    </ItemWrapper>
  );
}
