import { MdAddAPhoto } from "react-icons/md";
import { IoMdPhotos } from "react-icons/io";

import { Container } from "./styles";
import theme from "../../../../styles/theme";

export default function AddPictureButton({ setAddingPhoto, isEmpty }) {
  return (
    <Container
      theme={theme}
      isEmpty={isEmpty}
      onClick={() => setAddingPhoto(true)}
    >
      {isEmpty ? <MdAddAPhoto /> : <IoMdPhotos />}
    </Container>
  );
}
