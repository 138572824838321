import { useEffect, useState } from "react";

import theme from "../../../../styles/theme";
import { Container } from "./styles";
import generalUtils from "../../../../utils/general-utils";

export default function Input({ icon: Icon, submitted, ...rest }) {
  const [error, setError] = useState(false);

  function handleLink() {
    if (!rest.disabled) return;
    window.open(
      `${generalUtils.urls[rest.id]}${rest.value
        .replace("https://", "")
        .replace("www.", "")
        .replace("instagram.com/", "")
        .replace("linkedin.com/in/", "")}`,
      "_blank"
    );
  }

  useEffect(() => {
    if (submitted) {
      if (
        rest.required &&
        (rest.value === "" || !rest.value?.toString().match(rest.pattern))
      )
        setError(true);
      else setError(false);
    } else {
      if (rest.value !== "" && !rest.value?.toString().match(rest.pattern))
        setError(true);
      else setError(false);
    }
  }, [submitted, rest.value]);

  return (
    <Container theme={theme} disabled={rest.disabled} error={error}>
      {Icon && <Icon size={20} onClick={handleLink} className={rest.id} />}
      <input {...rest} />
    </Container>
  );
}
