import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function useSignIn() {
  const {
    loading: signInLoading,
    error: signInError,
    act: signIn,
  } = useAsync((data) => authenticationApi.signIn(data), false);

  return {
    signInLoading,
    signInError,
    signIn,
  };
}
