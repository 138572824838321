import useAsync from "../../useAsync";

import * as requestApi from "../../../services/requestApi";
import useToken from "../../useToken";

export default function useRequestOptions() {
  const token = useToken();

  const {
    data: requestOptions,
    loading: requestOptionsLoading,
    error: requestOptionsError,
    act: getRequestOptions,
  } = useAsync(() => requestApi.getRequestOptions(token), false);

  return {
    requestOptions,
    requestOptionsLoading,
    requestOptionsError,
    getRequestOptions,
  };
}
