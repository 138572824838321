import api from "./api";

export async function updateUser(body, userId, token) {
  const response = await api.put(`/user/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getUserData(userId, token) {
  const response = await api.get(`/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getUsersData({ order, skip, take }, token) {
  const response = await api.get(
    `/user/many?order=${order}&skip=${skip}&take=${take}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}
