import styled from "styled-components";

export const Container = styled.div`
  display: ${({ confirming }) => (confirming ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 15;
  background-color: ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT};
  align-items: center;
  justify-content: center;

  .confirm,
  .cancel {
    position: static !important;
    display: flex !important;
    margin: 0 10px;
    height: 40px;
    font-size: 18px;
    width: auto;
  }

  .confirm {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  .cancel {
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  label {
    text-align: left;
  }

  .textarea {
    top: -20px;
  }

  .coordinator {
    margin-bottom: 20px;
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 550px;
  max-width: 60%;
  max-height: 80%;
  padding-left: 16px;
  margin: auto;
  padding: 30px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow-y: auto;

  @media (max-width: 767px) {
    width: 80%;
  }
`;

export const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: transparent;
  border: 0;
  top: 10px;
  right: 10px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    transition: 0s;
  }

  svg:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;
