import styled from "styled-components";

export const Container = styled.div`
  display: ${({ loading }) => (loading ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 268px;
  padding: 30px;
  position: absolute;
  left: calc(50% - 175px);
  top: calc(50% - 134px);
  background-color: ${({ theme }) => theme.COLORS.GRAY};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  z-index: 2;

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0 40px 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;
