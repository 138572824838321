import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { useState } from "react";

import { Background, Form } from "../styles";
import { Button } from "../../../components/forms/authentication-form/Button";
import theme from "../../../styles/theme";
import useChangePassword from "../../../hooks/api/authentication/useChangePassword";
import toastUtils from "../../../utils/toast-utils";
import PasswordInput from "../../../components/forms/PasswordInput";
import Title from "../../../components/forms/authentication-form/Title";

export default function NewPassword() {
  const { changePassword } = useChangePassword();
  const navigate = useNavigate();
  const location = useLocation();
  const activationKey = new URLSearchParams(location.search).get("key");
  const userId = new URLSearchParams(location.search).get("userId");
  const [typingPassword, setTypingPassword] = useState(false);
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await changePassword({
        userId,
        key: activationKey,
        password: form.password,
        confirmPassword: form.confirmPassword,
      });

      setForm({
        password: "",
        confirmPassword: "",
      });

      navigate("/");

      toastUtils.toaster({
        message: "Senha alterada com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      setForm({
        password: "",
        confirmPassword: "",
      });

      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <>
      <Form theme={theme} onSubmit={handleSubmit}>
        <Title />

        <h2> Altere sua senha </h2>

        <PasswordInput
          placeholder="Nova Senha"
          name="password"
          value={form.password}
          required
          onFocus={() => setTypingPassword(true)}
          onBlur={() => setTypingPassword(false)}
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
          minLength="8"
          pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
          typingPassword={typingPassword}
          password={form.password}
        />

        <PasswordInput
          placeholder="Confirme sua senha"
          name="confirmPassword"
          value={form.confirmPassword}
          required
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
          minLength="8"
          pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
        />

        <Button title="Alterar senha" />

        <Link to="/">Voltar para o login</Link>
        <Link to="/sign-up">Primeiro login? Faça o cadastro aqui</Link>
      </Form>

      <Background theme={theme} />
    </>
  );
}
