import useAsync from "../../useAsync";

import * as vesselApi from "../../../services/vesselApi";
import useToken from "../../useToken";

export default function useDeleteEngine() {
  const token = useToken();

  const {
    loading: deleteEngineLoading,
    error: deleteEngineError,
    act: deleteEngine,
  } = useAsync((data) => vesselApi.deleteEngine(data, token), false);

  return {
    deleteEngineLoading,
    deleteEngineError,
    deleteEngine,
  };
}
