import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectorInspections() {
  const token = useToken();

  const {
    data: inspectorInspections,
    loading: inspectorInspectionsLoading,
    error: inspectorInspectionsError,
    act: getInspectorInspections,
  } = useAsync(
    (data) => inspectionApi.getInspectorInspections(data, token),
    false
  );

  return {
    inspectorInspections,
    inspectorInspectionsLoading,
    inspectorInspectionsError,
    getInspectorInspections,
  };
}
