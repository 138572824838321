import useAsync from "../../useAsync";

import * as requestApi from "../../../services/requestApi";
import useToken from "../../useToken";

export default function useDeleteRequest() {
  const token = useToken();

  const {
    loading: deleteRequestLoading,
    error: deleteRequesteError,
    act: deleteRequest,
  } = useAsync((data) => requestApi.deleteRequest(data, token), false);

  return {
    deleteRequestLoading,
    deleteRequesteError,
    deleteRequest,
  };
}
