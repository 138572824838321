import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";

import theme from "../../styles/theme";
import { Container } from "./styles";

export default function FilterSelector({ displayFilters, setDisplayFilters }) {
  return (
    <Container theme={theme} onClick={() => setDisplayFilters(!displayFilters)}>
      <h6>Filtros</h6>

      {displayFilters ? <BiSolidUpArrow /> : <BiSolidDownArrow />}
    </Container>
  );
}
