import { IoChevronForwardSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { Container, Title } from "./styles";
import theme from "../../styles/theme";
import { useContext } from "react";
import LocationsContext from "../../contexts/locationsContext";

export function Section({ title, children }) {
  return (
    <Container theme={theme}>
      <SectionTitle title={title} />
      {children}
    </Container>
  );
}

function SectionTitle({ title }) {
  const location = useLocation();
  const navigate = useNavigate();
  const path = title.split("/");
  const [titleList, setTitleList] = useState([]);
  const { locations, setLocations } = useContext(LocationsContext);

  useEffect(() => {
    generateTitle();
  }, [title]);

  async function generateTitle() {
    const list = [];
    const lastItem = path[path.length - 1];
    const queryStrings = location.search.replace(
      `search=${new URLSearchParams(location.search).get("search")}`,
      ""
    );
    const fullPath = `${location.pathname}${
      queryStrings === "?" ? "" : queryStrings
    }`;

    let locs = locations;

    if (!locs[lastItem]) locs[lastItem] = fullPath;
    else if (locs[lastItem] === location.pathname) locs[lastItem] = fullPath;

    setLocations(locs);

    path.forEach((item, index) => {
      list.push({
        name: item,
        loc: locs[item],
      });

      if (index < path.length - 1) list.push(<IoChevronForwardSharp />);
    });

    setTitleList(list);
  }

  return (
    <Title theme={theme} className="desktop">
      {titleList.map((item, index) => (
        <span key={index}>
          {item.name ? (
            <h2
              onClick={
                item.loc
                  ? () => navigate(item.loc)
                  : () => {
                      return;
                    }
              }
            >
              {item.name}
            </h2>
          ) : (
            item
          )}
        </span>
      ))}
    </Title>
  );
}
