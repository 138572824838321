import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  .userForm {
    width: 40%;
  }

  .managerForm {
    width: 60%;
  }

  .companyForm {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0 0;
    width: 100%;
    position: relative;
    margin-bottom: 130px;
  }

  .submit,
  .cancel {
    position: absolute;
    right: 0;
    bottom: -100px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    right: 210px;
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  .infinite-scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .submit,
    .cancel {
      width: calc(50% - 5px);
    }

    .cancel {
      left: 0;
    }
  }
`;

export const Form = styled.form`
  min-width: 400px;
  margin: 30px auto 0;

  & > div {
    width: 100%;

    & > span {
      position: relative;
    }
  }

  @media (max-width: 1023px) {
    min-width: 300px;
    margin: 30px 0 0;
    width: 100% !important;
  }
`;

export const FullContact = styled.div`
  display: flex;
`;

export const Edit = styled.button`
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  font-size: 16px;
  position: absolute;
  background-color: transparent;
  border: none;
  right: 0;
  top: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  @media (max-width: 1023px) {
    right: 0;
  }
`;

export const FormsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: 1023px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 1px !important;
  height: inherit;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 0 50px;

  @media (max-width: 1023px) {
    width: 100% !important;
    height: 1px !important;
    margin: 30px 0;
  }
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    position: relative;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;

  & > span {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    height: 108px;
  }

  span {
    width: 100%;
    flex-direction: column;
    margin-left: 10px;
  }

  span:first-child {
    margin-left: 0;
  }

  .number,
  .uf,
  .creaUf,
  .gender,
  .year,
  .qualitySystemExpiration {
    max-width: 200px;
    min-width: 200px;
  }

  .add-role {
    max-width: 54px;
    min-width: 54px;
  }

  @media (max-width: 767px) {
    & > span {
      height: 94px;
    }

    .number,
    .uf,
    .creaUf,
    .gender,
    .year {
      max-width: 120px;
      min-width: 120px;

      .select {
        width: 80px;
      }
    }

    .add-role {
      max-width: 50px;
      min-width: 50px;
    }
  }
`;

export const AddButton = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 10px;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "initial")};
  border: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme, disabled }) =>
    !disabled ? theme.COLORS.GRAY_100 : theme.COLORS.BACKGROUND_700};
  margin-top: 37px;

  svg {
    width: 30px;
    height: 30px;
    margin-top: 8px;
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.WHITE : theme.COLORS.BACKGROUND_700};
  }

  @media (max-width: 767px) {
    width: 47px;
    height: 47px;
  }
`;

export const AddSubitem = styled.div`
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;

  &:hover {
    svg,
    p {
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  p {
    font-size: 18px;
    margin: 0;
  }
`;

export const ParamsWrapper = styled.span`
  display: flex;
  flex-direction: row;

  & > div {
    width: 240px;
    height: 50px;
    margin-right: 10px;
    border-radius: 20px;
    position: initial;

    .select {
      max-width: 190px !important;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }

  #service {
    width: 500px;

    .select {
      max-width: 450px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    & > div {
      width: 100%;
      margin-right: 0;

      .select {
        max-width: 100%;
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }
    }

    .filter {
      display: ${({ displayFilters }) => (displayFilters ? "flex" : "none")};
    }

    #service {
      width: 100%;

      .select {
        max-width: 100%;
      }
    }
  }
`;
