import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useFillChecklist() {
  const token = useToken();

  const {
    loading: fillChecklistLoading,
    error: fillChecklistError,
    act: fillChecklist,
  } = useAsync((data) => inspectionApi.fillChecklist(data, token), false);

  return {
    fillChecklistLoading,
    fillChecklistError,
    fillChecklist,
  };
}
