import useAsync from "../../useAsync";

import * as vesselApi from "../../../services/vesselApi";
import useToken from "../../useToken";

export default function useVessel() {
  const token = useToken();

  const {
    data: vessel,
    loading: vesselLoading,
    error: vesselError,
    act: getVessel,
  } = useAsync((data) => vesselApi.getVesselData(data, token), false);

  return {
    vessel,
    vesselLoading,
    vesselError,
    getVessel,
  };
}
