import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { BsHouse, BsClipboard2Check, BsCalendarEvent } from "react-icons/bs";
import { IoAddCircleSharp } from "react-icons/io5";
import { RxIdCard } from "react-icons/rx";
import { FiGlobe } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa6";
import { AiOutlineLinkedin } from "react-icons/ai";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import theme from "../../../../styles/theme";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import UserContext from "../../../../contexts/UserContext";
import { Container, Divider } from "./styles";
import { AddSubitem, Address, Edit, Form, FormsWrapper, Row } from "../styles";
import toastUtils from "../../../../utils/toast-utils";
import Label from "../../../../components/forms/enrollment-form/Label";
import Input from "../../../../components/forms/enrollment-form/Input";
import Button from "../../../../components/forms/enrollment-form/Button";
import Select from "../../../../components/forms/enrollment-form/Select";
import useAdddress from "../../../../hooks/api/enrollment/useAddress";
import useSaveCompany from "../../../../hooks/api/company/useSaveCompany";
import useCompany from "../../../../hooks/api/company/useCompany";
import Contact from "../../../../components/forms/enrollment-form/Contact";
import AddType from "../../../../components/forms/enrollment-form/AddType";
import RestrictAccess from "../../../../components/forms/enrollment-form/RestrictAccess";
import useReceitaws from "../../../../hooks/api/company/useReceitaws";
import InputLoader from "../../../../components/InputLoader";

export default function Company({ title, setTitle }) {
  const { getAddress } = useAdddress();
  const { companyId } = useParams();
  const [newCompany, setNewCompany] = useState(isNaN(companyId));
  const { userData } = useContext(UserContext);
  const location = useLocation();
  const typeId = Number(location.search.replace("?typeId=", ""));
  const { saveCompany, saveCompanyLoading } = useSaveCompany();
  const { getCompany } = useCompany();
  const { getReceitaws, receitawsLoading } = useReceitaws();
  const allowed =
    userData.department.label === "COMERCIAL" ||
    userData.department.label === "DIRETORIA" ||
    userData.department.label === "ADMINISTRATIVO" ||
    userData.department.label === "FINANCEIRO" ||
    userData.permission.label === "MASTER" ||
    newCompany;
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [enrollment, setEnrollment] = useState({});
  const [form, setForm] = useState(enrollmentUtils.emptyCompany);
  const [contacts, setContacts] = useState([enrollmentUtils.emptyContact]);
  const [credentials, setCredentials] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [qualitySystemOptions, setQualitySystemOptions] = useState([]);
  const [addingType, setAddingType] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  dayjs.locale("pt-br");

  useEffect(() => {
    setEnrollment(form);
    loadCompanyData();
  }, []);

  useEffect(() => {
    setNewCompany(isNaN(companyId));
  }, [companyId]);

  useEffect(() => {
    loadDataFromCNPJ(enrollmentUtils.numberUnmask(form.cnpj));
  }, [form.cnpj]);

  async function loadCompanyData() {
    try {
      const response = await getCompany(companyId);

      if (response) {
        setContactTypes(response.contactTypes);
        setQualitySystemOptions(response.qualitySystemOptions);

        if (response.company?.contacts.length > 0)
          setContacts(response.company.contacts);

        if (response.company?.credentials.length > 0)
          setCredentials(response.company.credentials);

        if (newCompany) {
          setTitle(`${title}/Novo Cadastro`);
          setEditing(true);
          setForm({
            ...form,
            typeId,
          });
          return;
        }

        const data = enrollmentUtils.editIncomingCompany(response);

        setContacts(data.contacts);
        setEnrollment(data);
        setForm(data);
        setTitle(`${title.replace("/Novo Cadastro", "")}/${data.tradeName}`);
      } else {
        setForm(enrollmentUtils.emptyCompany);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadDataFromCNPJ(cnpj) {
    if (cnpj.length < 14 || !editing) return;

    try {
      const data = await getReceitaws(cnpj);

      if (data)
        setForm({
          ...form,
          legalName: data.legalName,
          tradeName: data.tradeName,
          cep: data.cep ? enrollmentUtils.cepMask(data.cep) : "",
          uf: data.uf,
          city: data.city,
          neighborhood: data.neighborhood,
          street: data.street,
          number: data.number,
          complement: data.complement,
        });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    if (name === "cnpj") {
      const doc =
        enrollmentUtils.numberUnmask(value).length > 11
          ? enrollmentUtils.cnpjMask(value)
          : enrollmentUtils.cpfMask(value);
      return setForm({ ...form, [name]: doc });
    }

    if (name === "number")
      return setForm({
        ...form,
        [name]: enrollmentUtils.numberMask(value.toString()),
      });

    if (name === "qualitySystemExpiration")
      return setForm({ ...form, [name]: enrollmentUtils.dateMask(value) });

    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);

    try {
      const body = {
        ...form,
        companyId: !newCompany ? Number(companyId) : 0,
        cnpj: enrollmentUtils.numberUnmask(form.cnpj),
        cep: enrollmentUtils.numberUnmask(form.cep),
        uf: form.uf.value,
        qualitySystem: form.qualitySystem.value || "NAO",
        contacts: contacts.map((contact) => ({
          ...contact,
          type: { id: contact.type.value, name: contact.type.label },
          phone: enrollmentUtils.numberUnmask(contact.phone),
        })),
        credentials,
        qualitySystemExpiration: dayjs(
          enrollmentUtils.convertToDate(form.qualitySystemExpiration)
        ),
      };

      const response = await saveCompany(body);
      setEditing(false);
      setTitle(
        `${title
          .replace("/Novo Cadastro", "")
          .replace(`/${response.tradeName}`, "")}/${response.tradeName}`
      );
      navigate(
        `/painel/cadastro/${
          title.includes("Clientes") ? "cliente" : "fornecedor"
        }/${response.companyId}`
      );

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <AddType
        contacts={contacts}
        setContacts={setContacts}
        types={contactTypes}
        setTypes={setContactTypes}
        addingType={addingType}
        setAddingType={setAddingType}
      />

      <FormsWrapper>
        {!newCompany && allowed && (
          <Edit
            theme={theme}
            onClick={(e) => {
              e.preventDefault();

              if (editing) setForm(enrollment);
              else if (!allowed) {
                toastUtils.toaster({
                  message:
                    "Você não tem permissão para editar os dados desta empresa!",
                  type: toastUtils.type.error,
                  position: toastUtils.position.topCenter,
                  theme: toastUtils.theme.colored,
                });

                return;
              }

              setEditing(!editing);
            }}
          >
            {editing ? "Cancelar" : "Editar"}
          </Edit>
        )}

        <Form className="companyForm" onSubmit={handleSubmit}>
          <div>
            <h3>Dados Cadastrais</h3>

            <Row>
              <span className="cnpj">
                <Label id="cnpj" text="CNPJ/CPF*" />
                <Input
                  id="cnpj"
                  placeholder="CNPJ/CPF"
                  type="text"
                  icon={RxIdCard}
                  disabled={!(editing && allowed)}
                  required
                  submitted={submitted}
                  pattern="^\d+[\-\.\/\d]*$"
                  value={form.cnpj}
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                ></Input>
                {receitawsLoading && <InputLoader />}
              </span>

              {title.includes("Fornecedores") ? (
                <span className="qualitySystem">
                  <Label
                    id="qualitySystem"
                    text="Possui Sistema de Qualidade"
                  />
                  <Select
                    id="qualitySystem"
                    placeholder="Selecione..."
                    icon={BsClipboard2Check}
                    enabled={editing && allowed}
                    list={qualitySystemOptions}
                    selected={form.qualitySystem}
                    submitted={submitted}
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>
              ) : (
                <span></span>
              )}
            </Row>

            <Row>
              <span className="legalName">
                <Label id="legalName" text="Razão Social / Nome Completo*" />
                <Input
                  id="legalName"
                  placeholder="Razão Social / Nome Completo"
                  type="text"
                  icon={RxIdCard}
                  disabled={!(editing && allowed)}
                  required
                  submitted={submitted}
                  pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ@0-9,. ]+$"}
                  value={form.legalName}
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>

              <span className="tradeName">
                <Label id="tradeName" text="Nome Fantasia*" />
                <Input
                  id="tradeName"
                  placeholder="Nome Fantasia"
                  type="text"
                  icon={RxIdCard}
                  disabled={!(editing && allowed)}
                  required
                  submitted={submitted}
                  pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ@0-9 ]+$"}
                  value={form.tradeName}
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>
            </Row>

            <Row>
              <span className="stateRegistration">
                <Label id="stateRegistration" text="Inscrição Estadual" />
                <Input
                  id="stateRegistration"
                  placeholder="Inscrição Estadual"
                  type="text"
                  icon={RxIdCard}
                  disabled={!(editing && allowed)}
                  submitted={submitted}
                  value={form.stateRegistration}
                  pattern="^[0-9./-]+$"
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>

              <span className="municipalRegistration">
                <Label id="municipalRegistration" text="Inscrição Municipal" />
                <Input
                  id="municipalRegistration"
                  placeholder="Inscrição Municipal"
                  type="text"
                  icon={RxIdCard}
                  disabled={!(editing && allowed)}
                  submitted={submitted}
                  value={form.municipalRegistration}
                  pattern="^[0-9./-]+$"
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>
            </Row>
          </div>

          {form.qualitySystem.value === "SIM" && (
            <>
              <Divider theme={theme} />

              <div>
                <h3>Sistema de Qualidade</h3>

                <Row>
                  <span className="qualitySystemStandard">
                    <Label id="qualitySystemStandard" text="Norma Aplicada*" />
                    <Input
                      id="qualitySystemStandard"
                      placeholder="Norma Aplicada"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={form.qualitySystemStandard}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="qualitySystemEntity">
                    <Label
                      id="qualitySystemEntity"
                      text="Órgão Certificador*"
                    />
                    <Input
                      id="qualitySystemEntity"
                      placeholder="Órgão Certificador"
                      type="text"
                      icon={RxIdCard}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={form.qualitySystemEntity}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>

                  <span className="qualitySystemExpiration">
                    <Label id="qualitySystemExpiration" text="Validade*" />
                    <Input
                      id="qualitySystemExpiration"
                      placeholder="Validade"
                      type="text"
                      icon={BsCalendarEvent}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={form.qualitySystemExpiration}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </span>
                </Row>
              </div>
            </>
          )}

          <Divider theme={theme} />

          <div>
            <h3>Endereço</h3>

            <Address>
              <Row>
                <span className="cep">
                  <Label id="cep" text="CEP" />
                  <Input
                    id="cep"
                    placeholder="CEP"
                    type="text"
                    icon={BsHouse}
                    disabled={!(editing && allowed)}
                    submitted={submitted}
                    value={form.cep}
                    maxLength="9"
                    minLength="9"
                    pattern="\d{5}-\d{3}"
                    onChange={(e) =>
                      enrollmentUtils.handleCep(e, form, setForm, getAddress)
                    }
                  />
                </span>

                <span className="uf">
                  <Label id="uf" text="Estado*" />
                  <Select
                    id="uf"
                    placeholder="Selecione..."
                    icon={BsHouse}
                    enabled={editing && allowed}
                    list={enrollmentUtils.states}
                    selected={form.uf}
                    required
                    submitted={submitted}
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row>
                <span className="city">
                  <Label id="city" text="Cidade*" />
                  <Input
                    id="city"
                    placeholder="Cidade"
                    type="text"
                    icon={BsHouse}
                    disabled={!(editing && allowed)}
                    required
                    submitted={submitted}
                    pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ., ]+$"}
                    value={form.city}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>

                <span className="neighborhood">
                  <Label id="neighborhood" text="Bairro*" />
                  <Input
                    id="neighborhood"
                    placeholder="Bairro"
                    type="text"
                    icon={BsHouse}
                    disabled={!(editing && allowed)}
                    required
                    submitted={submitted}
                    pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ., ]+$"}
                    value={form.neighborhood}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>

              <Row>
                <span className="street">
                  <Label id="street" text="Logradouro*" />
                  <Input
                    id="street"
                    placeholder="Logradouro"
                    type="text"
                    icon={BsHouse}
                    disabled={!(editing && allowed)}
                    required
                    submitted={submitted}
                    value={form.street}
                    pattern={
                      "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9., ]+$"
                    }
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>

                <span className="number">
                  <Label id="number" text="Número" />
                  <Input
                    id="number"
                    placeholder="Número"
                    type="number"
                    icon={BsHouse}
                    disabled={!(editing && allowed)}
                    submitted={submitted}
                    value={form.number}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>

              <Row>
                <span className="complement">
                  <Label id="complement" text="Complemento (opcional)" />
                  <Input
                    id="complement"
                    placeholder="Complemento"
                    type="text"
                    icon={BsHouse}
                    disabled={!(editing && allowed)}
                    submitted={submitted}
                    value={form.complement}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </Address>

            <Divider theme={theme} />

            {contacts.map((item, index) => (
              <Contact
                key={index}
                contactId={item.contactId}
                contacts={contacts}
                setContacts={setContacts}
                index={index}
                disabled={!(editing && allowed)}
                types={contactTypes}
                setAddingType={setAddingType}
              />
            ))}

            {editing && allowed && (
              <AddSubitem
                theme={theme}
                disabled={!(editing && allowed)}
                onClick={() =>
                  setContacts([...contacts, enrollmentUtils.emptyContact])
                }
              >
                <IoAddCircleSharp />
                <p>Adicionar contato</p>
              </AddSubitem>
            )}
          </div>

          <Divider theme={theme} />

          <div>
            <h3>Acesso Remoto</h3>

            <Row>
              <span className="website">
                <Label id="website" text="Site da Empresa" />
                <Input
                  id="website"
                  placeholder="Site da Empresa"
                  type="text"
                  icon={FiGlobe}
                  disabled={!(editing && allowed)}
                  submitted={submitted}
                  value={form.website}
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>

              <span className="instagram">
                <Label id="instagram" text="Instagram" />
                <Input
                  id="instagram"
                  placeholder="Instagram"
                  type="text"
                  icon={FaInstagram}
                  disabled={!(editing && allowed)}
                  submitted={submitted}
                  value={form.instagram}
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>

              <span className="linkedin">
                <Label id="linkedin" text="LinkedIn" />
                <Input
                  id="linkedin"
                  placeholder="LinkedIn"
                  type="text"
                  icon={AiOutlineLinkedin}
                  disabled={!(editing && allowed)}
                  submitted={submitted}
                  value={form.linkedin}
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>
            </Row>

            {credentials.map((item, index) => (
              <RestrictAccess
                key={index}
                credentialId={item?.credentialId}
                credentials={credentials}
                setCredentials={setCredentials}
                index={index}
                disabled={!(editing && allowed)}
              />
            ))}

            {editing && allowed && (
              <AddSubitem
                theme={theme}
                disabled={!(editing && allowed)}
                onClick={() =>
                  setCredentials([
                    ...credentials,
                    enrollmentUtils.emptyCredential,
                  ])
                }
              >
                <IoAddCircleSharp />
                <p>Adicionar acesso restrito</p>
              </AddSubitem>
            )}
          </div>

          <Button
            className="submit"
            title="Salvar"
            enabled={editing && allowed && !saveCompanyLoading}
          />
          {newCompany && (
            <Button
              className="cancel"
              title="Cancelar"
              enabled={editing && allowed && !saveCompanyLoading}
              type="button"
              onClick={() => navigate(-1)}
            />
          )}
        </Form>
      </FormsWrapper>
    </Container>
  );
}
