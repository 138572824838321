import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import inspectionUtils from "../../../utils/inspection-utils";
import { Container } from "../styles";
import InspectionList from "./InspectionList";

export default function ScheduledInspections() {
  return (
    <Section title="Vistorias/Agendadas">
      <Container theme={theme}>
        <InspectionList
          statusId={inspectionUtils.status.AGUARDANDO_ANALISE_VISTORIADOR}
        />
      </Container>
    </Section>
  );
}
