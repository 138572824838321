import theme from "../../styles/theme";
import { Container } from "./styles";

export default function IncompleteEnrollment() {
  return (
    <Container theme={theme}>
      <h1>CADASTRO INCOMPLETO!</h1>
      <p>
        Para ter acesso a todas as funcionalidades do Mercurio, suas informações
        cadastrais devem estar completas. Entre em contato com seu gerente para
        preenchê-las.
      </p>
    </Container>
  );
}
