import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionFilters() {
  const token = useToken();

  const {
    data: inspectionFilters,
    loading: inspectionFiltersLoading,
    error: inspectionFiltersError,
    act: getInspectionFilters,
  } = useAsync(() => inspectionApi.getInspectionFilters(token), false);

  return {
    inspectionFilters,
    inspectionFiltersLoading,
    inspectionFiltersError,
    getInspectionFilters,
  };
}
