import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function useChangeEmail() {
  const {
    loading: changeEmailLoading,
    error: changeEmailError,
    act: changeEmail,
  } = useAsync((data) => authenticationApi.changeEmail(data), false);

  return {
    changeEmailLoading,
    changeEmailError,
    changeEmail,
  };
}
