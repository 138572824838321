import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .submit,
  .cancel {
    position: absolute;
    right: 0;
    bottom: -100px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    right: 210px;
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_800};
  }

  @media (max-width: 767px) {
    margin-top: 40px;

    div {
      width: 100%;
    }

    h3 {
      font-size: 22px;
    }
  }
`;
