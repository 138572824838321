import useAsync from "../../useAsync";

import * as enrollmentApi from "../../../services/enrollmentApi";
import useToken from "../../useToken";

export default function useEnrollment() {
  const newToken = useToken();

  const {
    data: enrollment,
    loading: enrollmentLoading,
    error: enrollmentError,
    act: getEnrollment,
  } = useAsync((data, givenToken) => {
    const token = givenToken ? givenToken : newToken;

    return enrollmentApi.getEnrollmentData(data, token);
  }, false);

  return {
    enrollment,
    enrollmentLoading,
    enrollmentError,
    getEnrollment,
  };
}
