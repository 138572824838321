import useAsync from "../../useAsync";

import * as productApi from "../../../services/productApi";
import useToken from "../../useToken";

export default function useCertifications() {
  const token = useToken();

  const {
    data: certifications,
    loading: certificationsLoading,
    error: certificationsError,
    act: getCertifications,
  } = useAsync((data) => productApi.getCertifications(data, token), false);

  return {
    certifications,
    certificationsLoading,
    certificationsError,
    getCertifications,
  };
}
