import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px 100px 0 100px;

  h1 {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    text-align: center;
  }

  p {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    margin: 80px 20px 0 20px;

    h1 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;
