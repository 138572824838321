import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { FiLock } from "react-icons/fi";
import { useState } from "react";

import { Container, VisibilitySwitch } from "./styles";
import PasswordInstructions from "../authentication-form/PasswordInstructions";
import theme from "../../../styles/theme";

export default function PasswordInput({ typingPassword, password, ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container theme={theme} disabled={rest.disabled}>
      <div>
        {<FiLock size={20} />}
        <input type={showPassword ? "text" : "password"} {...rest} />
        <Visibility
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />

        {(password || password === "") && (
          <PasswordInstructions
            typingPassword={typingPassword}
            password={password}
          />
        )}
      </div>
    </Container>
  );
}

export function Visibility({ showPassword, setShowPassword }) {
  return (
    <VisibilitySwitch
      theme={theme}
      type="button"
      onClick={() => setShowPassword(!showPassword)}
    >
      {showPassword ? (
        <MdOutlineVisibilityOff size={20} />
      ) : (
        <MdOutlineVisibility size={20} />
      )}
    </VisibilitySwitch>
  );
}
