import { IoCloseCircle } from "react-icons/io5";
import { BsPeople } from "react-icons/bs";

import theme from "../../../../styles/theme";
import { CloseButton, Container, Wrapper } from "./styles";
import MultiSelect from "../MultiSelect";
import Button from "../../calendar-form/Button";
import { useState } from "react";

export default function AddGuests({
  guestList,
  currentEvent,
  setCurrentEvent,
  setAddingGuests,
}) {
  const [guests, setGuests] = useState([...currentEvent.guests]);

  function handleSubmit() {
    setCurrentEvent({ ...currentEvent, guests });
    setAddingGuests(false);
  }

  return (
    <Container theme={theme}>
      <Wrapper theme={theme}>
        <CloseButton
          theme={theme}
          onClick={() => setAddingGuests(false)}
          type="button"
        >
          <IoCloseCircle />
        </CloseButton>

        <h5>Compartilhar evento</h5>

        <MultiSelect
          id="guests"
          placeholder="Selecione..."
          icon={BsPeople}
          enabled={true}
          list={guestList}
          selected={guests}
          required
          setForm={setGuests}
        />

        <span>
          <Button
            className="cancel"
            title="Cancelar"
            type="button"
            onClick={() => setAddingGuests(false)}
          />
          <Button
            className="confirm"
            title="Confirmar"
            editing={true}
            type="button"
            onClick={handleSubmit}
          />
        </span>
      </Wrapper>
    </Container>
  );
}
