const formEmptyFilters = {
  products: [{ value: "", label: "TODOS" }],
};

const formEmptyDisplayParams = {
  order: { label: "", value: 0 },
  product: { label: "", value: "" },
};

const formOrderList = [
  { label: "Ordenar por...", value: "" },
  { label: "Nome", value: "NMFORM" },
  { label: "Serviço", value: "OBS" },
];

const emptyChecklist = {
  id: 0,
  code: "",
  name: "",
  product: {
    id: 0,
    name: "",
    acronym: "",
  },
  normam: {
    id: 0,
    name: "",
    code: "",
  },
  navigationType: { id: 0, name: "" },
  sections: [],
};

const emptyList = [{ label: "Selecione...", value: 0 }];

const emptyDailyReportForm = {
  id: 0,
  requestId: 0,
  requestStatus: 0,
  inspection: { label: "", value: 0 },
  company: { label: "", value: 0 },
  vessel: { label: "", value: 0 },
  coordinatorId: 0,
  order: "",
  location: "",
  date: null,
  scope: "",
  user: { label: "", value: 0 },
  role: { label: "", value: 0 },
  qualification: "",
  additionalInfo: "",
  additionalInfoStart: null,
  additionalInfoEnd: null,
  obs: "",
};

const emptyActivity = {
  detailIds: [],
  description: "",
  activityStart: null,
  activityEnd: null,
  status: { label: "Selecione...", value: 0 },
  services: [],
};

function generateAddress(data) {
  let address = `${data.city} - ${data.uf}`;

  if (data.cep) address += `, ${data.cep}`;

  if (data.neighborhood) address = `${data.neighborhood}, ${address}`;

  if (data.number) address = `${data.number} - ${address}`;

  if (data.street) address = `${data.street}, ${address}`;

  return address;
}

const dailyReportEmptyFilters = {
  companies: [{ value: 0, label: "TODOS" }],
  vessels: [{ value: 0, label: "TODOS" }],
};

const dailyReportEmptyDisplayParams = {
  order: { label: "", value: 0 },
  company: { label: "", value: 0 },
  vessel: { label: "", value: 0, companyId: 0 },
};

const dailyReportOrderList = [
  { label: "Data", value: "DTATUAL" },
  { label: "Vistoria", value: "ID_VISTORIA" },
  { label: "Data de criação", value: "CRIADO_EM" },
  { label: "Última edição", value: "ATUALIZADO_EM" },
];

const reportUtils = {
  formEmptyFilters,
  formEmptyDisplayParams,
  formOrderList,
  emptyChecklist,
  emptyDailyReportForm,
  emptyList,
  emptyActivity,
  dailyReportEmptyFilters,
  dailyReportEmptyDisplayParams,
  dailyReportOrderList,
  generateAddress,
};

export default reportUtils;
