import api from "./api";

export async function getAddress(cep, token) {
  const response = await api.get(`/enroll/cep?cep=${cep}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getEnrollmentData(enrollmentUserId, token) {
  const response = await api.get(`/enroll/${enrollmentUserId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postEnrollment(body, token) {
  const response = await api.post("/enroll", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getEnrollmentFilters(token) {
  const response = await api.get("/enroll/filters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
