import { createContext, useMemo } from "react";

import useLocalStorage from "../hooks/useLocalStorage";

const EnrollmentContext = createContext();
export default EnrollmentContext;

export function EnrollmentProvider({ children }) {
  const [enrollmentData, setEnrollmentData] = useLocalStorage(
    "enrollmentData",
    {}
  );

  const enrollmentDataProviderValue = useMemo(
    () => ({ enrollmentData, setEnrollmentData }),
    [enrollmentData, setEnrollmentData]
  );

  return (
    <EnrollmentContext.Provider value={enrollmentDataProviderValue}>
      {children}
    </EnrollmentContext.Provider>
  );
}
