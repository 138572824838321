import useAsync from "../../useAsync";
import * as userApi from "../../../services/userApi";
import useToken from "../../useToken";

export default function useUsersData() {
  const token = useToken();

  const {
    data: usersData,
    loading: usersDataLoading,
    error: usersDataError,
    act: getUsersData,
  } = useAsync((data) => userApi.getUsersData(data, token), false);

  return {
    usersData,
    usersDataLoading,
    usersDataError,
    getUsersData,
  };
}
