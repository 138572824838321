import api from "./api";

export async function getServiceFilters(typeId, token) {
  const response = await api.get(`/service/filters?type=${typeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
