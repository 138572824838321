import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { RequestDisplayer, Divider } from "./styles";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

import toastUtils from "../../../../utils/toast-utils";
import { Container, Form, Row } from "../styles";
import theme from "../../../../styles/theme";
import { Section } from "../../../../components/Section";
import { ServiceContainer } from "../RequestedService/styles";
import RequestForm from "../../../../components/forms/service-form/ServiceForm";
import serviceUtils from "../../../../utils/service-utils";
import useRequestOptions from "../../../../hooks/api/request/useRequestOptions";
import useRequest from "../../../../hooks/api/request/useRequest";
import useSaveRequest from "../../../../hooks/api/request/useSaveRequest";
import useDeleteRequest from "../../../../hooks/api/request/useDeleteRequest";
import RequestInfo from "../../../../components/forms/service-form/RequestInfo";
import ConfirmationMessage from "../../../../components/ConfirmationMessage";
import UserContext from "../../../../contexts/UserContext";
import Reviewer from "../../../../components/forms/service-form/Reviewer";
import Label from "../../../../components/forms/service-form/Label";
import { Textarea } from "../../../../components/forms/service-form/Textarea";
import InterruptButton from "../../../../components/forms/service-form/InterruptButton";

export default function ServiceUnderReview({ type }) {
  const { userData } = useContext(UserContext);
  const { serviceId } = useParams();
  const status = Number(useLocation().search.replace("?status=", ""));
  const newReview = status === serviceUtils.status.SOLICITADO;
  const allowed = !newReview;
  const navigate = useNavigate();
  const { getRequestOptions } = useRequestOptions();
  const { getRequest } = useRequest();
  const { saveRequest } = useSaveRequest();
  const { deleteRequest } = useDeleteRequest();
  const [title, setTitle] = useState("Serviços/Análise Interna");
  const [options, setOptions] = useState(serviceUtils.emptyServiceOptions);
  const [allOptions, setAllOptions] = useState(
    serviceUtils.emptyServiceOptions
  );
  const [form, setForm] = useState(serviceUtils.emptyServiceForm);
  const [requestInfo, setRequestInfo] = useState(serviceUtils.emptyService);
  const [reviewInfo, setReviewInfo] = useState(serviceUtils.emptyService);
  const [checked, setChecked] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [vesselList, setVesselList] = useState([]);
  const [displayRequest, setDisplayRequest] = useState(false);
  const [applying, setApplying] = useState(false);
  const [abandoning, setAbandoning] = useState(false);
  const [checklists, setChecklists] = useState({});
  const [interrupting, setInterrupting] = useState(false);

  useEffect(() => {
    loadRequestData();

    if (status >= serviceUtils.status.AGUARDANDO_ANALISE_INTERNA)
      loadReviewData();
  }, [status]);

  useEffect(() => {
    checkProducts();
  }, [requestInfo]);

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  function handleCheck({ name, value, serviceId, serviceName, activityId }) {
    let result = [...form.products];

    if (serviceId === value) {
      if (checked[value]) {
        result = result.filter((product) => product.serviceId !== value);

        let toUncheck = { ...checked, [value]: false };

        const optionList =
          activityId === options.certificationOptions.activityId
            ? options.certificationOptions.services
            : options.projectOptions.services;

        const currentServiceProducts = optionList.filter(
          (service) => service.serviceId === serviceId
        )[0].products;

        currentServiceProducts.forEach(
          (product) =>
            (toUncheck = { ...toUncheck, [product.productId]: false })
        );

        setChecked(toUncheck);
      } else {
        setChecked({ ...checked, [value]: name });
      }
    } else {
      if (checked[value]) {
        result = result.filter((product) => product.productId !== value);

        setChecked({ ...checked, [value]: false });
      } else {
        result = [
          ...result,
          {
            productId: value,
            productName: name,
            serviceId,
            serviceName,
            obs: "",
          },
        ];

        setChecked({ ...checked, [value]: name });
      }
    }

    setForm({ ...form, products: result });
  }

  async function loadRequestData() {
    try {
      const response = await getRequestOptions();

      if (response) {
        setCompanyList(response.companies);
        setVesselList(response.vessels);

        setOptions({
          ...options,
          certificationOptions: response.certificationOptions,
          projectOptions: response.projectOptions,
        });

        setAllOptions({
          ...options,
          certificationOptions: response.certificationOptions,
          projectOptions: response.projectOptions,
        });

        const service = await getRequest({
          serviceId,
          status: serviceUtils.status.SOLICITADO,
        });

        if (service) {
          setRequestInfo(service);

          setForm({
            ...service,
            products: service.products.map((product) => ({
              obs: product.obs,
              productId: product.productId,
              productName: product.productName,
              serviceId: product.service.serviceId,
              serviceName: product.service.serviceName,
            })),
          });

          if (status < serviceUtils.status.AGUARDANDO_ANALISE_INTERNA) {
            setTitle(
              `Serviços/Análise Interna/${service.id} - ${service.type.typeName} - ${service.company.label} - ${service.vessel.label}`
            );
          }
        }
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function checkProducts() {
    let toCheck = {};

    form.products.forEach((item) => {
      if (!toCheck[item.serviceId]) {
        if (Math.trunc(item.serviceId / 100) === 1) {
          if (
            options.certificationOptions.services.find(
              (service) => service.serviceId === item.serviceId
            )?.products.length !== 1
          ) {
            toCheck = {
              ...toCheck,
              [item.serviceId]: item.serviceName,
            };
          }
        } else {
          if (
            options.projectOptions.services.find(
              (service) => service.serviceId === item.serviceId
            )?.products.length !== 1
          ) {
            toCheck = {
              ...toCheck,
              [item.serviceId]: item.serviceName,
            };
          }
        }
      }

      toCheck = {
        ...toCheck,
        [item.productId]: item.productName,
      };
    });

    setChecked(toCheck);
  }

  async function loadReviewData() {
    try {
      const response = await getRequest({
        serviceId,
        status,
      });

      if (response) {
        setReviewInfo(response);
        setForm(response);
        setTitle(
          `Serviços/Análise Interna/${response.id} - ${response.type.typeName} - ${response.company.label} - ${response.vessel.label}`
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      if (form.reports.length < form.products.length) {
        return toastUtils.toaster({
          message:
            "Você deve selecionar pelo menos um relatório para cada produto selecionado.",
          type: toastUtils.type.error,
          position: toastUtils.position.topCenter,
          theme: toastUtils.theme.colored,
        });
      }

      const body = {
        id: Number(serviceId),
        company: form.company.value,
        vessel: form.vessel.value,
        obs: form.obs,
        status: serviceUtils.status.ANALISE_INTERNA_EXECUTADA,
        type,
        forms: form.reports,
        products: form.products,
      };

      await saveRequest(body);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      await loadReviewData();

      navigate(
        `/painel/servicos/analise/${serviceId}?status=${serviceUtils.status.ANALISE_INTERNA_EXECUTADA}`
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function applyForReview() {
    try {
      const body = {
        id: Number(serviceId),
        company: form.company.value,
        vessel: form.vessel.value,
        obs: form.obs,
        status: serviceUtils.status.AGUARDANDO_ANALISE_INTERNA,
        type,
        forms: form.reports,
      };

      await saveRequest(body);

      toastUtils.toaster({
        message: "Você agora é responsável pela análise desta solicitação!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      navigate(
        `/painel/servicos/analise/${serviceId}?status=${serviceUtils.status.AGUARDANDO_ANALISE_INTERNA}`
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }

    setApplying(false);
  }

  async function abandonReview() {
    try {
      await deleteRequest({ serviceId, status });

      toastUtils.toaster({
        message: "Você não é mais responsável pela análise desta solicitação!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      navigate(
        `/painel/servicos/analise/${serviceId}?status=${serviceUtils.status.SOLICITADO}`
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }

    setAbandoning(false);
  }

  async function handleInterrupt() {
    try {
      const body = {
        id: Number(serviceId),
        company: form.company.value,
        vessel: form.vessel.value,
        obs: form.obs,
        status: serviceUtils.status.FINALIZADO,
        type: 4,
        forms: form.reports,
      };

      await saveRequest(body);

      toastUtils.toaster({
        message: "Serviço interrompido com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      setInterrupting(false);

      await loadReviewData();

      navigate(
        `/painel/servicos/interrompidos/${serviceId}?status=${serviceUtils.status.FINALIZADO}`
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <Section title={title}>
        <RequestDisplayer theme={theme}>
          <h5>Detalhamento da Solicitação</h5>

          {displayRequest ? (
            <AiOutlineCaretUp onClick={() => setDisplayRequest(false)} />
          ) : (
            <AiOutlineCaretDown onClick={() => setDisplayRequest(true)} />
          )}
        </RequestDisplayer>

        {displayRequest && (
          <>
            <RequestInfo info={requestInfo} options={options} />
            <Divider theme={theme} />
          </>
        )}

        <Reviewer
          newReview={newReview}
          requestInfo={requestInfo}
          setApplying={setApplying}
          setAbandoning={setAbandoning}
          status={status}
        />

        <ServiceContainer theme={theme}>
          {status >= serviceUtils.status.ANALISE_INTERNA_EXECUTADA ? (
            <RequestInfo info={reviewInfo} options={options} />
          ) : (
            <RequestForm
              handleSubmit={handleSubmit}
              handleCheck={handleCheck}
              checkProducts={checkProducts}
              handleForm={handleForm}
              form={form}
              setForm={setForm}
              allowed={allowed && requestInfo.user.userId === userData.user.id}
              companyList={companyList}
              vesselList={vesselList}
              options={options}
              setOptions={setOptions}
              allOptions={allOptions}
              checked={checked}
              setChecked={setChecked}
              newRequest={newReview}
              checklists={checklists}
              setChecklists={setChecklists}
              type={type}
            />
          )}
        </ServiceContainer>

        {(userData.permission.label === "APROVADOR" ||
          userData.department.label === "ADMINISTRATIVO" ||
          userData.user.id === requestInfo.user.userId) &&
          status < serviceUtils.status.ANALISE_INTERNA_EXECUTADA && (
            <InterruptButton
              text="Interromper serviço"
              setSubmiting={setInterrupting}
            />
          )}

        <ConfirmationMessage
          text="Deseja assumir a análise desta solicitação?"
          action={applyForReview}
          confirming={applying}
          setConfirming={setApplying}
        />

        <ConfirmationMessage
          text="Tem certeza que deseja abandonar a análise desta solicitação?"
          action={abandonReview}
          confirming={abandoning}
          setConfirming={setAbandoning}
        />

        <ConfirmationMessage
          text="Tem certeza que deseja interromper este serviço?"
          action={handleInterrupt}
          confirming={interrupting}
          setConfirming={setInterrupting}
        >
          <Form>
            <div>
              <Row>
                <span className="textarea">
                  <Label id="obs" text="Observações*" />
                  <Textarea
                    id="obs"
                    placeholder="Observações..."
                    disabled={false}
                    value={form.obs}
                    required
                    form={form}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </div>
          </Form>
        </ConfirmationMessage>
      </Section>
    </Container>
  );
}
