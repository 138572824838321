import useAsync from "../../useAsync";

import * as requestApi from "../../../services/requestApi";
import useToken from "../../useToken";

export default function useRequest() {
  const token = useToken();

  const {
    data: request,
    loading: requestLoading,
    error: requesteError,
    act: getRequest,
  } = useAsync((data) => requestApi.getRequest(data, token), false);

  return {
    request,
    requestLoading,
    requesteError,
    getRequest,
  };
}
