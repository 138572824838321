import useAsync from "../../useAsync";

import * as productApi from "../../../services/productApi";
import useToken from "../../useToken";

export default function useProduct() {
  const token = useToken();

  const {
    data: product,
    loading: productLoading,
    error: productError,
    act: getProduct,
  } = useAsync((data) => productApi.getProduct(data, token), false);

  return {
    product,
    productLoading,
    productError,
    getProduct,
  };
}
