import { IoCloseSharp } from "react-icons/io5";

import { Container } from "./styles";
import theme from "../../../../styles/theme";

export default function InterruptButton({ text, setSubmiting }) {
  return (
    <Container theme={theme}>
      <button type="button" onClick={() => setSubmiting(true)}>
        <IoCloseSharp />
      </button>
      <h6>{text}</h6>
    </Container>
  );
}
