import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionDetail() {
  const token = useToken();

  const {
    data: inspectionDetail,
    loading: inspectionDetailLoading,
    error: inspectionDetailError,
    act: getInspectionDetail,
  } = useAsync((data) => inspectionApi.getInspectionDetail(data, token), false);

  return {
    inspectionDetail,
    inspectionDetailLoading,
    inspectionDetailError,
    getInspectionDetail,
  };
}
