import api from "./api";

export async function getCertifications(order, token) {
  const response = await api.get(`/product/certification?order=${order}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getProjects(order, token) {
  const response = await api.get(`/product/project?order=${order}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getProduct(productId, token) {
  const response = await api.get(`/product/${productId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postProduct(body, token) {
  const response = await api.post("/product", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
