import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function useConfirmationEmail() {
  const {
    data: confirmationEmail,
    loading: confirmationEmailLoading,
    error: confirmationEmailError,
    act: getConfirmationEmail,
  } = useAsync((data) => authenticationApi.sendConfirmationEmail(data), false);

  return {
    confirmationEmail,
    confirmationEmailLoading,
    confirmationEmailError,
    getConfirmationEmail,
  };
}
