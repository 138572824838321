import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useChecklist() {
  const token = useToken();

  const {
    data: checklist,
    loading: checklistLoading,
    error: checklistError,
    act: getChecklist,
  } = useAsync((data) => reportApi.getChecklist(data, token), false);

  return {
    checklist,
    checklistLoading,
    checklistError,
    getChecklist,
  };
}
