import useAsync from "../../useAsync";

import * as vesselApi from "../../../services/vesselApi";
import useToken from "../../useToken";

export default function useVessels() {
  const token = useToken();

  const {
    data: vessels,
    loading: vesselsLoading,
    error: vesselsError,
    act: getVessels,
  } = useAsync((data) => vesselApi.getVessels(data, token), false);

  return {
    vessels,
    vesselsLoading,
    vesselsError,
    getVessels,
  };
}
