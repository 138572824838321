import { createContext, useMemo, useState } from "react";

const LocationsContext = createContext();
export default LocationsContext;

export function LocationsProvider({ children }) {
  const [locations, setLocations] = useState({});

  const locationsProviderValue = useMemo(
    () => ({ locations, setLocations }),
    [locations, setLocations]
  );

  return (
    <LocationsContext.Provider value={locationsProviderValue}>
      {children}
    </LocationsContext.Provider>
  );
}
