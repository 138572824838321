import { useContext } from "react";

import UserContext from "../../contexts/UserContext";
import IncompleteEnrollment from "../../components/IncompleteEnrollment";
import { Container, DashboardWrapper } from "./styles";
import mercurioLogo from "../../assets/mercurio.png";
import theme from "../../styles/theme";

export default function Dashboard() {
  const { userData } = useContext(UserContext);

  return (
    <>
      {!userData.permission ? (
        <IncompleteEnrollment />
      ) : (
        <div>
          <Container theme={theme}>
            <DashboardWrapper theme={theme}>
              <img src={mercurioLogo} alt="Mercurio Logo" />
              <h1> SISTEMA SALVAGE </h1>
            </DashboardWrapper>
          </Container>
        </div>
      )}
    </>
  );
}
