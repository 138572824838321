import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function useActivateAccount() {
  const {
    loading: activateAccountLoading,
    error: activateAccountError,
    act: activateAccount,
  } = useAsync((data) => authenticationApi.activateAccount(data), false);

  return {
    activateAccountLoading,
    activateAccountError,
    activateAccount,
  };
}
