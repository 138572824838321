import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectorData() {
  const token = useToken();

  const {
    data: inspectorData,
    loading: inspectorDataLoading,
    error: inspectorDataError,
    act: getInspectorData,
  } = useAsync((data) => inspectionApi.getInspectorData(data, token), false);

  return {
    inspectorData,
    inspectorDataLoading,
    inspectorDataError,
    getInspectorData,
  };
}
