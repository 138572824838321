import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { Section } from "../../../../components/Section";
import serviceUtils from "../../../../utils/service-utils";
import { Container, InspectorContainer, InspectorWrapper } from "./styles";
import CheckBox from "../../../../components/forms/inspection-form/CheckBox";
import QualificationPicker from "../../../../components/forms/inspection-form/QualificationPicker";
import toastUtils from "../../../../utils/toast-utils";
import UserContext from "../../../../contexts/UserContext";
import theme from "../../../../styles/theme";
import Button from "../../../../components/forms/inspection-form/Button";
import { Divider, Edit, FormsWrapper, Form } from "../styles";
import noPicProfile from "../../../../assets/no-pic-profile.png";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import inspectionUtils from "../../../../utils/inspection-utils";
import useInspectorData from "../../../../hooks/api/inspection/useInspectorData";
import useSaveQualifications from "../../../../hooks/api/inspection/useSaveQualifications";
import InspectorTimeline from "../../../../components/forms/inspection-form/InspectorTimeline";
import { ProductSearch } from "../../styles";

export default function Inspector() {
  const { inspectorId } = useParams();
  const { userData } = useContext(UserContext);
  const { getInspectorData } = useInspectorData();
  const { saveQualifications, saveQualificationsLoading } =
    useSaveQualifications();
  const [title, setTitle] = useState("Serviços/Serviços Autorizados");
  const allowed =
    userData.permission?.label === "MASTER" ||
    userData.permission?.label === "APROVADOR";
  const [editing, setEditing] = useState(false);
  const [inspectorData, setInspectorData] = useState({});
  const [options, setOptions] = useState(serviceUtils.emptyServiceOption);
  const [allOptions, setAllOptions] = useState(serviceUtils.emptyServiceOption);
  const [qualifications, setQualifications] = useState([]);
  const [form, setForm] = useState(inspectionUtils.emptyQualificationsForm);
  const [checked, setChecked] = useState({ [0]: false });
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadInspectorData();
  }, []);

  useEffect(() => {
    checkQualifications(qualifications);
  }, [qualifications]);

  useEffect(() => {
    const result = [];

    allOptions.services.forEach((service) => {
      service.products.forEach((product) =>
        result.push({
          ...product,
          serviceId: service.serviceId,
          serviceName: service.serviceName,
        })
      );
    });

    setProducts(result);
  }, [allOptions]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  function handleCheck({ name, value, serviceId, activityId, serviceName }) {
    let result = [...form.products];

    if (serviceId === value) {
      if (checked[value]) {
        result = result.filter((product) => product.serviceId !== value);

        let toUncheck = { ...checked, [value]: false };

        const optionList =
          activityId === options.activityId
            ? options.services
            : options.projectOptions.services;

        const currentServiceProducts = optionList.filter(
          (service) => service.serviceId === serviceId
        )[0].products;

        currentServiceProducts.forEach(
          (product) =>
            (toUncheck = { ...toUncheck, [product.productId]: false })
        );

        setChecked(toUncheck);
      } else {
        setChecked({ ...checked, [value]: name });
      }
    } else {
      if (checked[value]) {
        result = result.filter((product) => product.productId !== value);

        setChecked({ ...checked, [value]: false });
      } else {
        result = [
          ...result,
          {
            productId: value,
            productName: name,
            serviceId,
            serviceName,
            obs: "",
          },
        ];

        setChecked({ ...checked, [value]: name });
      }
    }

    setForm({ ...form, products: result });
  }

  function handleCheckAll() {
    if (checked[0]) {
      setChecked({ [0]: false });
      setForm({ ...form, products: [] });
    } else {
      let toCheck = { [0]: true };
      let result = [];

      options.services.forEach((service) => {
        if (
          !toCheck[service.serviceId] &&
          allOptions.services.find(
            (service) => service.serviceId === service.serviceId
          ).products.length !== 1
        ) {
          toCheck = {
            ...toCheck,
            [service.serviceId]: service.serviceName,
          };
        }

        service.products.forEach((product) => {
          toCheck = {
            ...toCheck,
            [product.productId]: product.productName,
          };

          result = [
            ...result,
            {
              productId: product.productId,
              productName: product.productName,
              serviceId: service.serviceId,
            },
          ];
        });
      });

      setChecked(toCheck);
      setForm({ ...form, products: result });
    }
  }

  function handleSearch() {
    const filteredProducts = products.filter(
      (product) =>
        product.productName.toLowerCase().includes(search.toLowerCase()) ||
        product.productId.toString().includes(search) ||
        product.serviceName.toLowerCase().includes(search.toLowerCase())
    );

    let filteredServices = {};

    filteredProducts.forEach((product) => {
      filteredServices[product.serviceId] = true;
    });

    const filteredOptions = allOptions.services.filter((option) => {
      return filteredServices[option.serviceId] ? true : false;
    });

    filteredOptions.forEach((option) => {
      if (
        allOptions.services.find(
          (service) => service.serviceId === option.serviceId
        ).products.length !== 1
      )
        setChecked((prevChecked) => ({
          ...prevChecked,
          [option.serviceId]: option.serviceName,
        }));
    });

    setOptions((prevOptions) => ({
      ...prevOptions,
      services: filteredOptions,
    }));

    if (!search) checkQualifications(form.products);
  }

  function checkQualifications(list) {
    let toCheck = { [0]: false };

    list.forEach((qualification) => {
      if (
        !toCheck[qualification.serviceId] &&
        allOptions.services.find(
          (service) => service.serviceId === qualification.serviceId
        ).products.length !== 1
      ) {
        toCheck = {
          ...toCheck,
          [qualification.serviceId]: qualification.serviceName,
        };
      }

      toCheck = {
        ...toCheck,
        [qualification.productId]: qualification.productName,
      };
    });

    setChecked(toCheck);
    setOptions(allOptions);
  }

  async function loadInspectorData() {
    try {
      const response = await getInspectorData(inspectorId);

      if (response) {
        setTitle(`Vistorias/Vistoriadores/${response.inspectorData.user.name}`);
        setInspectorData(response.inspectorData);
        setOptions(response.certificationOptions);
        setAllOptions(response.certificationOptions);
        setQualifications(response.qualifications);
        setForm({
          creaId: response.inspectorData.enrollment.creaId,
          products: response.qualifications.map((qualification) => ({
            productId: qualification.productId,
            productName: qualification.productName,
            serviceId: qualification.serviceId,
            serviceName: qualification.serviceName,
          })),
        });
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await saveQualifications(form);

      setEditing(false);
      loadInspectorData();

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme} editing={editing}>
      <Section title={title}>
        <InspectorContainer theme={theme}>
          <FormsWrapper>
            <div>
              <h3>Dados do Vistoriador</h3>

              <InspectorData
                avatar={inspectorData?.user?.avatar || noPicProfile}
                fullName={inspectorData?.enrollment?.fullName}
                crea={inspectorData?.enrollment?.crea}
                creaUf={inspectorData?.enrollment?.creaUf}
                cpf={enrollmentUtils.cpfMask(inspectorData?.enrollment?.cpf)}
                email={inspectorData?.user?.email}
                phone={enrollmentUtils.phoneMask(
                  inspectorData?.enrollment?.phone
                )}
                role={inspectorData?.enrollment?.role}
                locale={inspectorData?.enrollment?.locale}
              />
            </div>

            <Divider theme={theme} />

            <Form className="qualification-form" onSubmit={handleSubmit}>
              <Edit
                theme={theme}
                onClick={(e) => {
                  e.preventDefault();

                  if (editing) {
                    setForm({
                      ...form,
                      products: qualifications,
                    });
                    checkQualifications(qualifications);
                  } else if (!allowed) {
                    toastUtils.toaster({
                      message:
                        "Você não tem permissão para editar as competências deste vistoriador!",
                      type: toastUtils.type.error,
                      position: toastUtils.position.topCenter,
                      theme: toastUtils.theme.colored,
                    });

                    return;
                  }

                  setEditing(!editing);
                }}
              >
                {editing ? "Cancelar" : "Editar"}
              </Edit>

              <div>
                <h3>Competências</h3>

                {allowed && editing && (
                  <ProductSearch
                    type="text"
                    placeholder="Procurar..."
                    onChange={(e) => setSearch(e.target.value)}
                    theme={theme}
                  />
                )}

                {allowed && editing && !search && (
                  <CheckBox
                    className="check-all"
                    activityId={0}
                    label={`${checked[0] ? "Desmarcar" : "Marcar"} todos`}
                    id={0}
                    form={checked}
                    handleCheck={handleCheckAll}
                    serviceId={0}
                    serviceName=""
                    disabled={!allowed || !editing}
                  />
                )}

                {options.services.map((service) => (
                  <span key={service.serviceId}>
                    {service.products.length > 1 ? (
                      <CheckBox
                        activityId={options.activityId}
                        label={service.serviceName}
                        id={service.serviceId}
                        form={checked}
                        handleCheck={handleCheck}
                        serviceId={service.serviceId}
                        serviceName={service.serviceName}
                        disabled={!allowed || !editing}
                      />
                    ) : (
                      <QualificationPicker
                        label={service.products[0].productName}
                        id={service.products[0].productId}
                        form={form}
                        setForm={setForm}
                        checked={checked}
                        handleCheck={handleCheck}
                        handleForm={handleForm}
                        serviceId={service.serviceId}
                        serviceName={service.serviceName}
                        disabled={!allowed || !editing}
                      />
                    )}
                    {checked[service.serviceId] &&
                      service.products.map((product) => (
                        <span key={product.productId} className="subitem">
                          <QualificationPicker
                            label={product.productName}
                            id={product.productId}
                            form={form}
                            setForm={setForm}
                            checked={checked}
                            handleCheck={handleCheck}
                            handleForm={handleForm}
                            serviceId={service.serviceId}
                            serviceName={service.serviceName}
                            disabled={!allowed || !editing}
                          />
                        </span>
                      ))}
                  </span>
                ))}
              </div>

              {editing && (
                <Button
                  className="update"
                  title="Atualizar"
                  enabled={allowed && !saveQualificationsLoading}
                />
              )}
            </Form>

            <Divider theme={theme} />

            <div>
              <InspectorTimeline inspectorId={inspectorId} />
            </div>
          </FormsWrapper>
        </InspectorContainer>
      </Section>
    </Container>
  );
}

function InspectorData({
  avatar,
  fullName,
  crea,
  creaUf,
  cpf,
  email,
  phone,
  role,
  locale,
}) {
  return (
    <InspectorWrapper theme={theme}>
      <div>
        <img src={avatar} alt="Vistoriador" />

        <h5>{fullName}</h5>

        <h6>{role}</h6>
      </div>

      <div>
        <h5>{`CREA-${creaUf}`}:</h5>
        <h6>{crea}</h6>

        <h5>CPF:</h5>
        <h6>{cpf}</h6>

        <h5>E-mail:</h5>
        <h6>{email}</h6>

        <h5>Contato:</h5>
        <h6>{phone}</h6>

        <h5>Localidade:</h5>
        <h6>{locale}</h6>
      </div>
    </InspectorWrapper>
  );
}
