function findKeyById(object, value) {
  for (let key in object) {
    if (object[key] === value) {
      return key;
    }
  }
  return null;
}

function removeSpecialChar(text) {
  if (!text) return null;

  text = text.toLowerCase();

  const accentHexMap = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  for (let letter in accentHexMap) {
    const regexp = accentHexMap[letter];
    text = text.replace(regexp, letter);
  }

  return text;
}

function precisionMask(value) {
  const number = Number(value.replace(",", "").replace(/\./g, "")) / 100;

  let result = number ? number.toLocaleString("pt-BR") : "0,00";

  if (result.indexOf(",") !== result.length - 3 && result.includes(","))
    result += "0";

  if (!result.includes(",")) result += ",00";

  return result;
}

function handleDirSearch({ search, directories, setDirList }) {
  if (!search) return setDirList(directories);

  const result = directories.filter((item) =>
    item.text.toLowerCase().includes(search.toLowerCase())
  );

  setDirList(result);
}

const urls = {
  website: "https://www.",
  instagram: "https://www.instagram.com/",
  linkedin: "https://www.linkedin.com/in/",
};

function getDatesInterval(startDate, endDate) {
  const dates = [];

  if (!startDate || !endDate) return [];

  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dates.push(new Date(currentDate.setHours(0, 0, 0, 0)));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

function generateDateHashtable(dates) {
  let hashtable = {};

  dates.forEach((date) => {
    hashtable[date.getTime()] = true;
  });

  return hashtable;
}

const generalUtils = {
  findKeyById,
  removeSpecialChar,
  precisionMask,
  handleDirSearch,
  urls,
  getDatesInterval,
  generateDateHashtable,
};

export default generalUtils;
