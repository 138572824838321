import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Section } from "../../../../components/Section";
import { RequestDisplayer } from "../ServiceUnderReview/styles";
import { Container } from "../styles";
import { Divider } from "./styles";
import RequestInfo from "../../../../components/forms/service-form/RequestInfo";
import theme from "../../../../styles/theme";
import Reviewer from "../../../../components/forms/service-form/Reviewer";
import { ServiceContainer } from "../RequestedService/styles";
import toastUtils from "../../../../utils/toast-utils";
import serviceUtils from "../../../../utils/service-utils";
import useRequest from "../../../../hooks/api/request/useRequest";
import useRequestOptions from "../../../../hooks/api/request/useRequestOptions";
import Timeline from "../../../../components/forms/service-form/Timeline";

export default function InterruptedService() {
  const { serviceId } = useParams();
  const status = Number(useLocation().search.replace("?status=", ""));
  const { getRequestOptions } = useRequestOptions();
  const { getRequest } = useRequest();
  const [title, setTitle] = useState("Serviços/Serviços Interrompidos");
  const [options, setOptions] = useState(serviceUtils.emptyServiceOptions);
  const [requestInfo, setRequestInfo] = useState(serviceUtils.emptyService);
  const [reviewInfo, setReviewInfo] = useState(serviceUtils.emptyService);
  const [displayRequest, setDisplayRequest] = useState(false);

  useEffect(() => {
    loadRequestData();

    if (status >= serviceUtils.status.AGUARDANDO_ANALISE_INTERNA)
      loadReviewData();
  }, [status]);

  async function loadRequestData() {
    try {
      const response = await getRequestOptions();

      if (response) {
        setOptions({
          ...options,
          certificationOptions: response.certificationOptions,
          projectOptions: response.projectOptions,
        });

        const service = await getRequest({
          serviceId,
          status: serviceUtils.status.SOLICITADO,
        });

        if (service) {
          setRequestInfo(service);

          if (status < serviceUtils.status.AGUARDANDO_ANALISE_INTERNA)
            setTitle(
              `Serviços/Serviços Interrompidos/${service.id} - ${service.type.typeName} - ${service.company.label} - ${service.vessel.label}`
            );
        }
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadReviewData() {
    try {
      const response = await getRequest({
        serviceId,
        status,
      });

      if (response) {
        setReviewInfo(response);
        setTitle(
          `Serviços/Serviços Interrompidos/${response.id} - ${response.type.typeName} - ${response.company.label} - ${response.vessel.label}`
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <Section title={title}>
        <RequestDisplayer theme={theme}>
          <h5>Detalhamento da Solicitação</h5>

          {displayRequest ? (
            <AiOutlineCaretUp onClick={() => setDisplayRequest(false)} />
          ) : (
            <AiOutlineCaretDown onClick={() => setDisplayRequest(true)} />
          )}
        </RequestDisplayer>

        {displayRequest && (
          <>
            <RequestInfo info={requestInfo} options={options} />
            <Divider theme={theme} />
          </>
        )}

        <Reviewer newReview={false} requestInfo={requestInfo} status={status} />

        <ServiceContainer theme={theme}>
          <RequestInfo info={reviewInfo} options={options} />
        </ServiceContainer>

        <Divider theme={theme} />

        <ServiceContainer theme={theme}>
          <Timeline serviceId={serviceId} status={status} />
        </ServiceContainer>
      </Section>
    </Container>
  );
}
