import styled from "styled-components";

export const ProductContainer = styled.div`
  width: 100%;
  display: flex;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 30px 0 0 0;
    width: 100%;
    position: relative;
    margin-bottom: 130px;
  }

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .name {
    max-width: calc(50% - 5px);
    min-width: calc(50% - 5px);
  }

  @media (max-width: 767px) {
    .name {
      max-width: 100%;
      min-width: 100%;
    }
  }
`;
