import { useContext } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";

import UserContext from "../../../../contexts/UserContext";
import serviceUtils from "../../../../utils/service-utils";
import noPicProfile from "../../../../assets/no-pic-profile.png";
import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function Reviewer({
  newReview,
  requestInfo,
  setApplying,
  setAbandoning,
  status,
}) {
  const { userData } = useContext(UserContext);

  return (
    <Container theme={theme}>
      <h3>Responável pela Análise</h3>

      <span>
        {newReview ? (
          <IoCloseCircleSharp />
        ) : (
          <img
            src={
              requestInfo.user.avatar ? requestInfo.user.avatar : noPicProfile
            }
            alt="profile"
          />
        )}

        <p>{newReview ? "Sem Responsável" : requestInfo.user.userName}</p>

        {status < serviceUtils.status.ANALISE_INTERNA_EXECUTADA && (
          <>
            {newReview ? (
              <AiFillPlusCircle onClick={() => setApplying(true)} />
            ) : (
              <>
                {userData.user.id === requestInfo.user.userId && (
                  <AiFillMinusCircle onClick={() => setAbandoning(true)} />
                )}
              </>
            )}
          </>
        )}
      </span>
    </Container>
  );
}
