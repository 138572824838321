import useAsync from "../../useAsync";

import * as enrollmentApi from "../../../services/enrollmentApi";
import useToken from "../../useToken";

export default function useSaveEnrollment() {
  const token = useToken();

  const {
    loading: saveEnrollmentLoading,
    error: saveEnrollmentError,
    act: saveEnrollment,
  } = useAsync((data) => enrollmentApi.postEnrollment(data, token), false);

  return {
    saveEnrollmentLoading,
    saveEnrollmentError,
    saveEnrollment,
  };
}
