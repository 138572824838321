import mercurioLogo from "../../../../assets/mercurio.png";
import { Container } from "./styles";

export default function Title() {
  return (
    <Container>
      <img src={mercurioLogo} alt="Mercurio Logo" />
      <h1> SISTEMA SALVAGE </h1>
    </Container>
  );
}
