import { FiMail, FiPhone, FiPlus, FiUser } from "react-icons/fi";
import { AiFillMinusCircle } from "react-icons/ai";
import { MdOutlineClass } from "react-icons/md";
import { useState } from "react";

import Input from "../Input";
import Label from "../Label";
import { Divider } from "../../../../pages/Home/Enrollment/Company/styles";
import { AddButton, Row } from "../../../../pages/Home/Enrollment/styles";
import { Container } from "./styles";
import theme from "../../../../styles/theme";
import Select from "../Select";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import useDeleteContact from "../../../../hooks/api/company/useDeleteContact";
import DeleteItem from "../DeleteItem";

export default function Contact({
  contactId,
  contacts,
  setContacts,
  index,
  disabled,
  types,
  setAddingType,
}) {
  const [deleting, setDeleting] = useState(false);
  const { deleteContact } = useDeleteContact();

  function handleChange({ name, value, index }) {
    const result = [...contacts];

    if (name === "phone")
      result[index] = {
        ...result[index],
        [name]: enrollmentUtils.phoneMask(value),
      };
    else result[index] = { ...result[index], [name]: value };

    setContacts(result);
  }

  return (
    <Container theme={theme}>
      {index > 0 && (
        <div className="divider">
          <Divider theme={theme} />
          {!disabled && <AiFillMinusCircle onClick={() => setDeleting(true)} />}
        </div>
      )}
      <h3>{`Contato ${contacts.length > 1 ? index + 1 : ""}`}</h3>

      <Row>
        <span className="type">
          <Label id="type" text="Tipo de Contato*" />
          <Select
            id="type"
            placeholder="Selecione..."
            icon={MdOutlineClass}
            enabled={!disabled}
            list={types}
            selected={contacts[index].type}
            form={contacts}
            setForm={setContacts}
            handleForm={handleChange}
            index={index}
          />
        </span>

        <span className="add-type">
          <AddButton
            type="button"
            theme={theme}
            disabled={disabled}
            onClick={() => setAddingType(true)}
          >
            <FiPlus />
          </AddButton>
        </span>
      </Row>

      <Row>
        <span className="email">
          <Label id="email" text="E-mail" />
          <Input
            placeholder="E-mail"
            type="email"
            id="email"
            value={contacts[index].email}
            icon={FiUser}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="role">
          <Label id="role" text="Cargo" />
          <Input
            placeholder="Cargo"
            type="text"
            id="role"
            value={contacts[index].role}
            icon={FiMail}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value.toUpperCase(),
                index,
              })
            }
          />
        </span>
      </Row>

      <Row className="desktop">
        <span className="contactName">
          <Label id="contact" text="Nome*" />
          <Input
            id="contact"
            placeholder="Nome"
            type="text"
            icon={FiUser}
            disabled={disabled}
            required
            value={contacts[index].contact}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="phone">
          <Label id="phone" text="Número*" />
          <Input
            id="phone"
            placeholder="Telefone"
            type="tel"
            icon={FiPhone}
            disabled={disabled}
            required
            value={contacts[index].phone}
            pattern="^\(\d{2}\)\s(?:9)?\d{4}-\d{4}$"
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="extention">
          <Label id="extention" text="Ramal" />
          <Input
            id="extention"
            placeholder="Ramal"
            type="text"
            icon={FiPhone}
            disabled={disabled}
            value={contacts[index].extention}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      <Row className="mobile">
        <span className="contactName">
          <Label id="contact" text="Nome*" />
          <Input
            id="contact"
            placeholder="Nome"
            type="text"
            icon={FiUser}
            disabled={disabled}
            required
            value={contacts[index].contact}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      <Row className="mobile">
        <span className="phone">
          <Label id="phone" text="Número*" />
          <Input
            id="phone"
            placeholder="Telefone"
            type="tel"
            icon={FiPhone}
            disabled={disabled}
            required
            value={contacts[index].phone}
            pattern="^\(\d{2}\)\s(?:9)?\d{4}-\d{4}$"
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="extention">
          <Label id="extention" text="Ramal" />
          <Input
            id="extention"
            placeholder="Ramal"
            type="text"
            icon={FiPhone}
            disabled={disabled}
            value={contacts[index].extention}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      <Row>
        <span className="obs">
          <Label id="obs" text="Observação (opcional)" />
          <Input
            id="obs"
            placeholder="Observação"
            type="text"
            disabled={disabled}
            value={contacts[index].obs}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      {deleting && (
        <DeleteItem
          itemId={contactId}
          text="Tem certeza que deseja excluir este contato?"
          list={contacts}
          setList={setContacts}
          index={index}
          deleting={deleting}
          setDeleting={setDeleting}
          action={deleteContact}
        />
      )}
    </Container>
  );
}
