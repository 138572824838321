import styled from "styled-components";

export const VesselContainer = styled.div`
  width: 100%;
  display: flex;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 30px 0 0 0;
    width: 100%;
    position: relative;
    margin-bottom: 130px;
  }

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .flag,
  .enginePower {
    max-width: calc(50% - 215px);
    min-width: calc(50% - 215px);
  }

  .registration,
  .deadweight {
    max-width: calc(50% - 5px);
    min-width: calc(50% - 5px);
  }

  .vessel-obs {
    height: 170px;
  }

  @media (max-width: 767px) {
    margin-top: 20px;

    h3 {
      font-size: 22px;
    }

    form > div > div {
      flex-direction: column;

      & > span {
        margin-left: 0;
        width: 100%;
        max-width: 100% !important;
      }

      .year {
        .select {
          width: 100%;
        }
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 30px 0;
`;

export const Unit = styled.p`
  position: absolute;
  right: 10px;
  bottom: 2px;
  font-size: 18px;
  color: ${({ theme }) => theme.COLORS.GRAY_300};
`;
