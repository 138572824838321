import { useLocation, useNavigate } from "react-router-dom";
import { RxIdCard } from "react-icons/rx";
import { RiShipLine } from "react-icons/ri";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

import {
  Divider,
  Form,
  FormsWrapper,
  HorizontalDivider,
  Row,
} from "../../../../pages/Home/Services/styles";
import Label from "../Label";
import Select from "../Select";
import { Textarea } from "../Textarea";
import theme from "../../../../styles/theme";
import CheckBox from "../CheckBox";
import ProductPicker from "../ProductPicker";
import Button from "../Button";
import TextSelect from "../TextSelect";
import { ProductSearch } from "../../../../pages/Home/styles";
import { useEffect, useState } from "react";
import useInspectionChecklists from "../../../../hooks/api/report/useInspectionChecklists";
import toastUtils from "../../../../utils/toast-utils";
import Loader from "../../../Loader";
import { ProductsReportsWrapper } from "./styles";
import ReportPicker from "../ReportPicker";
import serviceUtils from "../../../../utils/service-utils";
import requestUtils from "../../../../utils/request-utils";

export default function RequestForm({
  handleSubmit,
  handleCheck,
  checkProducts,
  handleForm,
  form,
  setForm,
  allowed,
  companyList,
  vesselList,
  options,
  setOptions,
  allOptions,
  checked,
  setChecked,
  newRequest,
  checklists,
  setChecklists,
  type,
}) {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { getInspectionChecklists, inspectionChecklistsLoading } =
    useInspectionChecklists();
  const isRequest =
    pathname.includes("solicitados") || pathname.includes("comercial");
  const [searchs, setSearchs] = useState({ certification: "", project: "" });
  const [products, setProducts] = useState({
    certifications: [],
    projects: [],
  });
  const [checkedReports, setCheckedReports] = useState({});

  useEffect(() => {
    if (type === serviceUtils.types.ANALISE_INTERNA && !allowed) checkReports();
  }, [form.reports]);

  useEffect(() => {
    const certifications = [];
    const projects = [];

    allOptions.certificationOptions.services.forEach((service) => {
      service.products.forEach((product) =>
        certifications.push({
          ...product,
          serviceId: service.serviceId,
          serviceName: service.serviceName,
        })
      );
    });

    allOptions.projectOptions.services.forEach((service) => {
      service.products.forEach((product) =>
        projects.push({
          ...product,
          serviceId: service.serviceId,
          serviceName: service.serviceName,
        })
      );
    });

    setProducts({ certifications, projects });
  }, [allOptions]);

  useEffect(() => {
    handleSearch({
      search: searchs.certification,
      productList: products.certifications,
      activity: "certificationOptions",
    });
  }, [searchs.certification]);

  useEffect(() => {
    handleSearch({
      search: searchs.project,
      productList: products.projects,
      activity: "projectOptions",
    });
  }, [searchs.project]);

  useEffect(() => {
    if (
      type === serviceUtils.types.ANALISE_INTERNA &&
      !allowed &&
      (Object.keys(checked).length === 0 || !form.reports[0])
    )
      return;

    loadChecklists();
  }, [form.products, checked]);

  function handleSearch({ search, productList, activity }) {
    const filteredProducts = productList.filter(
      (product) =>
        product.productName.toLowerCase().includes(search.toLowerCase()) ||
        product.productId.toString().includes(search) ||
        product.serviceName.toLowerCase().includes(search.toLowerCase())
    );

    let filteredServices = {};

    filteredProducts.forEach((product) => {
      filteredServices[product.serviceId] = true;
    });

    const filteredOptions = allOptions[activity].services.filter((option) => {
      return filteredServices[option.serviceId] ? true : false;
    });

    filteredOptions.forEach((option) => {
      if (
        allOptions[activity].services.find(
          (service) => service.serviceId === option.serviceId
        ).products.length !== 1
      )
        setChecked((prevChecked) => ({
          ...prevChecked,
          [option.serviceId]: option.serviceName,
        }));
    });

    setOptions((prevOptions) => ({
      ...prevOptions,
      [activity]: { ...prevOptions[activity], services: filteredOptions },
    }));

    if (!search) checkProducts();
  }

  async function loadChecklists() {
    const reports = form.reports.filter((report) => checked[report.productId]);

    setForm((prevForm) => ({ ...prevForm, reports }));

    let newCheckedReports = {};

    reports.forEach((report) => {
      if (checkedReports[report.formId])
        newCheckedReports[report.formId] = checkedReports[report.formId];
    });

    setCheckedReports(newCheckedReports);

    const params = {
      inspectionId: 0,
      nav: form.vessel.navigationType,
      prod: form.products.map((product) => product.productId).join(","),
    };
    let result = {};

    try {
      if (!params.nav || !params.prod) {
        setChecklists(result);
        return;
      }

      const response = await getInspectionChecklists(params);

      if (response) {
        form.products.forEach((product) => {
          result = {
            ...result,
            [product.productId]: response.filter(
              (checklist) => checklist.productId === product.productId
            ),
          };
        });

        setChecklists(result);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function checkReports() {
    let toCheck = {};

    form.reports.forEach((report) => {
      toCheck[report.formId] = report.formName;
    });

    setCheckedReports(toCheck);
  }

  return (
    <FormsWrapper>
      <Form className="serviceForm" onSubmit={handleSubmit}>
        <div className="request-data">
          <div>
            <h3>Serviço Solicitado</h3>
            <Row>
              <span className="company">
                <Label id="company" text="Cliente/Empresa*" />
                <TextSelect
                  id="company"
                  placeholder="Selecione..."
                  icon={RxIdCard}
                  enabled={allowed && isRequest}
                  list={companyList}
                  selected={form.company}
                  required
                  form={form}
                  setForm={setForm}
                  handleForm={handleForm}
                />
              </span>
            </Row>

            <Row>
              <span className="vessel">
                <Label id="vessel" text="Embarcação*" />
                <Select
                  id="vessel"
                  placeholder="Selecione..."
                  icon={RiShipLine}
                  enabled={allowed && form.company.value && isRequest}
                  list={vesselList.filter(
                    (vessel) => vessel.companyId === form.company.value
                  )}
                  selected={form.vessel}
                  required
                  form={form}
                  setForm={setForm}
                  handleForm={handleForm}
                />
              </span>
            </Row>

            <Row>
              <span className="textarea">
                <Label id="obs" text="Observações*" />
                <Textarea
                  id="obs"
                  placeholder="Observações..."
                  disabled={!allowed}
                  value={form.obs}
                  required
                  form={form}
                  onChange={(e) =>
                    handleForm({
                      name: e.target.id,
                      value: e.target.value,
                    })
                  }
                />
              </span>
            </Row>
          </div>

          <Divider theme={theme} />

          <div>
            <h3>Certificado(s) Solicitado(s)</h3>

            {allowed && (
              <ProductSearch
                type="text"
                placeholder="Procurar..."
                onChange={(e) =>
                  setSearchs((prevSearch) => ({
                    ...prevSearch,
                    certification: e.target.value,
                  }))
                }
                theme={theme}
              />
            )}

            {options.certificationOptions.services.map((service, index) => (
              <span key={index}>
                {service.products.length > 1 ? (
                  <CheckBox
                    activityId={options.certificationOptions.activityId}
                    label={service.serviceName}
                    id={service.serviceId}
                    form={checked}
                    handleCheck={handleCheck}
                    serviceId={service.serviceId}
                    serviceName={service.serviceName}
                    disabled={!allowed || !form.vessel.value}
                  />
                ) : (
                  <ProductPicker
                    label={service.products[0].productName}
                    id={service.products[0].productId}
                    form={form}
                    setForm={setForm}
                    checked={checked}
                    handleCheck={handleCheck}
                    handleForm={handleForm}
                    serviceId={service.serviceId}
                    serviceName={service.serviceName}
                    disabled={!allowed || !form.vessel.value}
                  />
                )}
                {checked[service.serviceId] &&
                  service.products.map((product, index) => (
                    <span key={index} className="subitem">
                      <ProductPicker
                        label={product.productName}
                        id={product.productId}
                        form={form}
                        setForm={setForm}
                        checked={checked}
                        handleCheck={handleCheck}
                        handleForm={handleForm}
                        serviceId={service.serviceId}
                        serviceName={service.serviceName}
                        disabled={!allowed || !form.vessel.value}
                      />
                    </span>
                  ))}
              </span>
            ))}
          </div>

          <Divider theme={theme} />

          <div>
            <h3>Projeto(s) Solicitado(s)</h3>

            {allowed && (
              <ProductSearch
                type="text"
                placeholder="Procurar..."
                onChange={(e) =>
                  setSearchs((prevSearch) => ({
                    ...prevSearch,
                    project: e.target.value,
                  }))
                }
                theme={theme}
              />
            )}

            {options.projectOptions.services.map((service, index) => (
              <span key={index}>
                {service.products.length > 1 ? (
                  <CheckBox
                    activityId={options.projectOptions.activityId}
                    label={service.serviceName}
                    id={service.serviceId}
                    form={checked}
                    handleCheck={handleCheck}
                    serviceId={service.serviceId}
                    serviceName={service.serviceName}
                    disabled={!allowed || !form.vessel.value}
                  />
                ) : (
                  <ProductPicker
                    label={service.products[0].productName}
                    id={service.products[0].productId}
                    form={form}
                    setForm={setForm}
                    checked={checked}
                    handleCheck={handleCheck}
                    handleForm={handleForm}
                    serviceId={service.serviceId}
                    serviceName={service.serviceName}
                    disabled={!allowed || !form.vessel.value}
                  />
                )}

                {checked[service.serviceId] &&
                  service.products.map((product, index) => (
                    <span key={index} className="subitem">
                      <ProductPicker
                        label={product.productName}
                        id={product.productId}
                        form={form}
                        setForm={setForm}
                        checked={checked}
                        handleCheck={handleCheck}
                        handleForm={handleForm}
                        serviceId={service.serviceId}
                        serviceName={service.serviceName}
                        disabled={!allowed || !form.vessel.value}
                      />
                    </span>
                  ))}
              </span>
            ))}
          </div>
        </div>

        <HorizontalDivider theme={theme} />

        <div className="reports">
          <h3>Relatório(s) Associado(s)</h3>

          {inspectionChecklistsLoading ? (
            <Loader theme={theme} />
          ) : (
            <>
              {requestUtils
                .clearDuplicates(form.products)
                .map((product, index) => (
                  <ReportList
                    key={index}
                    productId={product.productId}
                    productName={product.productName}
                    checklists={checklists}
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                    checked={checkedReports}
                    setChecked={setCheckedReports}
                  />
                ))}
            </>
          )}
        </div>

        <Button
          className="submit"
          title={form.id ? "Concluir análise" : "Enviar para análise"}
          enabled={allowed}
        />

        {newRequest && (
          <Button
            className="cancel"
            title="Cancelar"
            enabled={allowed}
            type="button"
            onClick={() => navigate(-1)}
          />
        )}
      </Form>
    </FormsWrapper>
  );
}

function ReportList({
  productId,
  productName,
  checklists,
  form,
  setForm,
  handleForm,
  checked,
  setChecked,
}) {
  const [displayReports, setDisplayReports] = useState(false);

  function handleCheck({ name, value }) {
    let result = [...form.reports];

    if (checked[value]) {
      result = result.filter((report) => report.formId !== value);

      setChecked({ ...checked, [value]: false });
    } else {
      result = [
        ...result,
        {
          productId,
          formId: value,
          obs: "",
        },
      ];

      setChecked({ ...checked, [value]: name });
    }

    setForm({ ...form, reports: result });
  }

  return (
    <>
      <ProductsReportsWrapper theme={theme}>
        <span>
          <h5>{productName}</h5>
          {displayReports ? (
            <AiOutlineCaretUp onClick={() => setDisplayReports(false)} />
          ) : (
            <AiOutlineCaretDown onClick={() => setDisplayReports(true)} />
          )}
        </span>
        {displayReports && (
          <>
            {checklists[productId]?.length === 0 ? (
              <p>Não há relatórios cadastrados para este serviço ainda.</p>
            ) : (
              <>
                {checklists[productId]?.map((checklist, index) => (
                  <ReportPicker
                    key={index}
                    label={checklist.name.replace("LISTA DE VERIFICAÇÃO", "")}
                    id={checklist.id}
                    form={form}
                    setForm={setForm}
                    checked={checked}
                    handleCheck={handleCheck}
                    handleForm={handleForm}
                    disabled={false}
                  />
                ))}
              </>
            )}
          </>
        )}
      </ProductsReportsWrapper>
    </>
  );
}
