import styled from "styled-components";

export const Textarea = styled.textarea`
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  margin: 10px 0 10px 10px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.COLORS.GRAY_100 : theme.COLORS.WHITE};
  background: transparent;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
  border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
  font-size: 14px;
  resize: vertical;

  &:placeholder {
    color: ${({ theme }) => theme.COLORS.GRAY_300};
  }

  &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
    border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
    outline: 0;
  }

  &:autofill {
    box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
    color: ${({ theme }) => theme.COLORS.WHITE}!important;
    border-radius: 0 10px 10px 0;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900}
      inset;
    -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
  }

  @media (max-width: 767px) {
    width: calc(100% - 30px);
    font-size: 12px;
    width: calc(100% - 25px);
  }
`;
