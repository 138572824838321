import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function Button({ title, loading = false, ...rest }) {
  return (
    <Container
      type="submit"
      disabled={loading}
      theme={theme}
      value={title}
      {...rest}
    />
  );
}
