import styled from "styled-components";
import logoImg from "../../assets/vertical2.png";
import formBackgroundImg from "../../assets/SALVAGE-MASTER.jpg";
export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Form = styled.form`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 660px;
  background: linear-gradient(
      ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT},
      ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT}
    ),
    url(${formBackgroundImg}) no-repeat;
  background-size: cover;
  box-shadow: 2px 0 5px ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT};
  z-index: 1;
  position: relative;

  h1 {
    font-size: 48px;
    color: ${({ theme }) => theme.COLORS.WHITE};
    margin-bottom: 0;
  }

  h2 {
    font-size: 24px;
    margin-top: 48px;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  h5 {
    font-size: 20px;
    margin: 20px 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  p {
    font-size: 16px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    width: 100%;
    margin: 5px 0;
    line-height: 20px;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.COLORS.WHITE};
    margin: 10px 0;
  }

  a:hover {
    text-decoration: underline;
  }

  span {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0;

    p {
      margin-bottom: 20px;
    }

    input {
      width: 48%;
    }

    input:nth-child(2) {
      background-color: ${({ theme }) => theme.COLORS.GRAY};
      border: solid 1px ${({ theme }) => theme.COLORS.GRAY_300};
      color: ${({ theme }) => theme.COLORS.GRAY_100};
    }
  }

  & > div {
    position: initial;
    box-shadow: none;
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 34px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${logoImg}) no-repeat center center;
  background-color: ${({ theme }) => theme.COLORS.WHITE};

  @media (max-width: 1023px) {
    display: none;
  }
`;
