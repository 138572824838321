import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function Label({ id, text }) {
  return (
    <Container theme={theme}>
      <label htmlFor={id}>{text}</label>
    </Container>
  );
}
