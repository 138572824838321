import useAsync from "../../useAsync";

import * as productApi from "../../../services/productApi";
import useToken from "../../useToken";

export default function useProjects() {
  const token = useToken();

  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
    act: getProjects,
  } = useAsync((data) => productApi.getProjects(data, token), false);

  return {
    projects,
    projectsLoading,
    projectsError,
    getProjects,
  };
}
