import theme from "../../../../styles/theme";
import { Container } from "./styles";

export function Textarea({ value, ...rest }) {
  return (
    <Container theme={theme} disabled={rest.disabled}>
      <textarea value={value} {...rest} />
    </Container>
  );
}
