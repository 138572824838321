import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import useChecklist from "../../../../hooks/api/report/useChecklist";
import useFillChecklist from "../../../../hooks/api/inspection/useFillChecklist";
import useChecklistAnswers from "../../../../hooks/api/report/useChecklistAnswers";
import toastUtils from "../../../../utils/toast-utils";
import { ChecklistWrapper } from "./styles";
import theme from "../../../../styles/theme";
import Loader from "../../../../components/Loader";
import ReportInfo from "../../../../components/forms/report-form/ReportInfo";
import ChecklistSection from "../../../../components/forms/report-form/ChecklistSection";
import { Divider, Edit } from "../../../../pages/Home/Reports/styles";
import Button from "../Button";
import Label from "../../inspection-form/Label";
import DateTimePicker from "../../../DateTimePicker";
import { BsCalendarEvent } from "react-icons/bs";
import { Row } from "../../../../pages/Home/Inspections/styles";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../../../contexts/UserContext";
import ChecklistSummary from "../ChecklistSummary";

export default function Checklist({
  formId,
  enabled,
  title,
  setTitle,
  checklist,
  setChecklist,
  inspectionId,
  inspectionDate,
  setInspectionDate,
  answers,
  setAnswers,
  summary,
  setSummary,
  stakeholders,
}) {
  const path = useLocation().pathname + useLocation().search;
  const searchParams = new URLSearchParams(useLocation().search);
  const readOnly = JSON.parse(searchParams.get("readOnly"));
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { getChecklist, checklistLoading } = useChecklist();
  const { fillChecklist, fillChecklistLoading } = useFillChecklist();
  const { getChecklistAnswers, checklistAnswersLoading } =
    useChecklistAnswers();
  const [newAnswer, setNewAnswer] = useState(
    JSON.parse(searchParams.get("new"))
  );
  const [options, setOptions] = useState([]);
  const [summaryOptions, setSummaryOptions] = useState([]);
  const [info, setInfo] = useState([]);
  const [allowed, setAllowed] = useState(false);
  const [editing, setEditing] = useState(false);
  const [extraItems, setExtraItems] = useState([]);

  useEffect(() => {
    loadChecklistData();
  }, []);

  useEffect(() => {
    loadInfo();

    if (!newAnswer) loadExtraItems();
  }, [checklist, newAnswer]);

  useEffect(() => {
    if (newAnswer) setEditing(true);
  }, [newAnswer]);

  async function loadChecklistData() {
    try {
      const response = await getChecklist(formId);

      if (response) {
        setChecklist(response.formData);

        setOptions(response.itemStatusList);

        setSummaryOptions(response.sectionStatusList);

        setTitle(
          `${title}/${response.formData.code} (${response.formData.obs})`
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadInfo() {
    setInfo([
      { label: "Lista", value: checklist.name },
      {
        label: "Documento de Referência",
        value: checklist.normam.code,
      },
      {
        label: "Serviço",
        value: `${checklist.product.acronym} - ${checklist.product.name}`,
      },
      {
        label: "Tipo de Navegação",
        value: checklist.navigationType.name,
      },
    ]);

    let list = {};
    let summaryList = {};

    if (newAnswer || readOnly) {
      setAllowed(true);

      checklist.sections.forEach((section) => {
        summaryList[section.id] = {
          summaryId: 0,
          name: section.name,
          sectionId: section.id,
          rev: "-",
          status: { label: "", value: 0 },
        };

        section?.items?.forEach((item, index) => {
          const isSubitem =
            item.description[1] === ")" || item.description[0] === "-";

          const hasSubitem =
            section.items[index + 1]?.code[
              section.items[index + 1]?.code.length - 1
            ] === "1" && !isSubitem;

          if (!hasSubitem)
            list[item.id] = {
              answerId: 0,
              code: "",
              section: { id: section.id, name: section.name },
              status: { label: "", value: 0 },
              obs: "",
              pictures: [],
            };
        });
      });
    } else {
      try {
        const response = await getChecklistAnswers({
          inspectionId,
          formId,
        });

        if (response) {
          list = response.items;

          summaryList = response.summary;

          setInspectionDate({ date: response.date });

          setAllowed(
            userData.enrrolmentId === stakeholders?.coordinatorId ||
              userData.enrrolmentId === stakeholders?.inpectorId
          );
        }
      } catch (error) {
        toastUtils.toaster({
          message: error.response.data,
          type: toastUtils.type.error,
          position: toastUtils.position.topCenter,
          theme: toastUtils.theme.colored,
        });
      }
    }

    setAnswers(list);
    setSummary(summaryList);
  }

  function loadExtraItems() {
    const extraAnswers = Object.keys(answers)
      .filter((key) => answers[key].code === "COMP")
      .map((key) => ({
        code: "COMP",
        description: "ITEM COMPLEMENTAR",
        id: key,
        obs: answers[key].obs,
        reference: "",
        sectionId: answers[key].section.id,
      }));

    setExtraItems(extraAnswers);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const body = {
      inspectionId: Number(inspectionId),
      formId: checklist.id,
      productId: checklist.product.id,
      date: inspectionDate.date,
      summary: Object.keys(summary).map((key) => ({
        summaryId: summary[key].summaryId,
        sectionId: summary[key].sectionId,
        rev: summary[key].rev === "-" ? null : summary[key].rev,
        statusId: summary[key].status.value,
      })),
      items: Object.keys(answers).map((key) => ({
        answerId: answers[key].answerId,
        sectionId: answers[key].section.id,
        itemId: answers[key].code === "COMP" ? null : Number(key),
        statusId: answers[key].status.value,
        obs: answers[key].obs,
        pictures: answers[key].pictures,
      })),
    };

    try {
      await fillChecklist(body);

      toastUtils.toaster({
        message: "Checklist salvo com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      setNewAnswer(false);
      navigate(path.replace("new=true", "new=false"));
      setEditing(false);
      loadInfo();
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function checkDemands(sectionId, form) {
    const section = checklist.sections.find((item) => item.id === sectionId);
    let warning = 0;

    section.items.forEach((item) => {
      if (form[item.id]?.status.label === "EM EXIGÊNCIA") warning++;
    });

    setSummary({ ...summary, [sectionId]: { ...summary[sectionId], warning } });
  }

  return (
    <>
      {checklistLoading || checklistAnswersLoading ? (
        <Loader />
      ) : (
        <ChecklistWrapper theme={theme}>
          <div>
            <h3>Dados da Lista de Verificação</h3>

            <ReportInfo info={info} />
          </div>

          <Divider theme={theme} />

          <form onSubmit={handleSubmit}>
            {inspectionDate && (
              <>
                <div>
                  <Row>
                    <span className="date">
                      <Label id="date" text="Data da Vistoria*" />
                      <DateTimePicker
                        id="date"
                        type="datetime"
                        icon={BsCalendarEvent}
                        required
                        value={dayjs(inspectionDate.date)
                          .locale("pt-br")
                          .format("DD/MM/YYYY - HH:mm")}
                        form={inspectionDate}
                        setForm={setInspectionDate}
                      />
                    </span>
                  </Row>
                </div>

                <Divider theme={theme} />
              </>
            )}

            <div className="checklist-container">
              {!newAnswer && allowed && !readOnly && (
                <Edit
                  theme={theme}
                  onClick={(e) => {
                    e.preventDefault();

                    if (editing) loadInfo();
                    else if (!allowed) {
                      toastUtils.toaster({
                        message:
                          "Você não tem permissão para editar os dados desta empresa!",
                        type: toastUtils.type.error,
                        position: toastUtils.position.topCenter,
                        theme: toastUtils.theme.colored,
                      });

                      return;
                    }

                    setEditing(!editing);
                  }}
                >
                  {editing ? "Cancelar" : "Editar"}
                </Edit>
              )}

              <h3>Relatório</h3>

              <div className="summary">
                <ChecklistSummary
                  sections={checklist.sections}
                  options={summaryOptions}
                  answers={summary}
                  setAnswers={setSummary}
                  enabled={enabled && editing}
                />
              </div>

              <Divider theme={theme} />

              <h3>Items</h3>
              {Object.keys(answers).length !== 0 && (
                <div className="checklist">
                  {checklist.sections.map((section, index) => (
                    <ChecklistSection
                      key={index}
                      section={section}
                      options={options}
                      answers={answers}
                      setAnswers={setAnswers}
                      enabled={enabled && editing}
                      newAnswer={newAnswer}
                      checkDemands={checkDemands}
                      extraItems={extraItems}
                      setExtraItems={setExtraItems}
                    />
                  ))}
                </div>
              )}
            </div>

            {enabled && editing && (
              <>
                <Button
                  className="submit"
                  title="Salvar"
                  enabled={enabled && editing && !fillChecklistLoading}
                />

                {newAnswer && (
                  <Button
                    className="cancel"
                    title="Apagar tudo"
                    enabled={enabled && editing && !fillChecklistLoading}
                    type="button"
                    onClick={loadInfo}
                  />
                )}
              </>
            )}
          </form>
        </ChecklistWrapper>
      )}
    </>
  );
}
