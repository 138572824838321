import useAsync from "../../useAsync";
import * as userApi from "../../../services/userApi";
import useToken from "../../useToken";

export default function useUpdateUser() {
  const token = useToken();

  const {
    loading: updateUserLoading,
    error: updateUserError,
    act: updateUser,
  } = useAsync(
    (data, userId) => userApi.updateUser(data, userId, token),
    false
  );

  return {
    updateUserLoading,
    updateUserError,
    updateUser,
  };
}
