import { AiFillMinusCircle, AiOutlineCalendar } from "react-icons/ai";
import { useState } from "react";

import Input from "../Input";
import Label from "../Label";
import { Divider } from "../../../../pages/Home/Enrollment/Company/styles";
import { Row } from "../../../../pages/Home/Enrollment/styles";
import { Container } from "./styles";
import theme from "../../../../styles/theme";
import Select from "../Select";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import DeleteItem from "../DeleteItem";
import { Unit } from "../../../../pages/Home/Enrollment/Vessel/styles";
import generalUtils from "../../../../utils/general-utils";
import { SiTurbosquid } from "react-icons/si";
import useDeleteEngine from "../../../../hooks/api/vessel/useDeleteEngine";

export default function Engine({
  engineId,
  engines,
  setEngines,
  index,
  disabled,
}) {
  const [deleting, setDeleting] = useState(false);
  const { deleteEngine } = useDeleteEngine();
  const years = enrollmentUtils.generateYearOptions();

  function handleChange({ name, value, index }) {
    const result = [...engines];

    result[index] = { ...result[index], [name]: value };

    setEngines(result);
  }

  return (
    <Container theme={theme}>
      {index > 0 && (
        <div className="divider">
          <Divider theme={theme} />
          {!disabled && <AiFillMinusCircle onClick={() => setDeleting(true)} />}
        </div>
      )}
      <h3>{`Motor ${engines.length > 1 ? index + 1 : ""}`}</h3>

      <Row>
        <span className="engineManufacturer">
          <Label id="engineManufacturer" text="Fabricante" />
          <Input
            id="engineManufacturer"
            placeholder="Fabricante"
            type="text"
            icon={SiTurbosquid}
            disabled={disabled}
            value={engines[index].engineManufacturer}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="engineModel">
          <Label id="engineModel" text="Modelo" />
          <Input
            id="engineModel"
            placeholder="Modelo"
            type="text"
            icon={SiTurbosquid}
            disabled={disabled}
            value={engines[index].engineModel}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      <Row>
        <span className="engineNumber">
          <Label id="engineNumber" text="Número" />
          <Input
            id="engineNumber"
            placeholder="Número"
            type="text"
            icon={SiTurbosquid}
            disabled={disabled}
            value={engines[index].engineNumber}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="enginePower">
          <Label id="enginePower" text="Potência Propulsiva" />
          <Input
            id="enginePower"
            placeholder="Potência Propulsiva"
            type="text"
            icon={SiTurbosquid}
            disabled={disabled}
            value={engines[index].enginePower}
            pattern={"^^\\d{1,3}(?:\\.\\d{3})*(?:,\\d{2})?$"}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: generalUtils.precisionMask(e.target.value),
                index,
              })
            }
          />

          <Unit theme={theme}>kW</Unit>
        </span>

        <span className="year">
          <Label id="engineYear" text="Ano de Fabricação" />
          <Select
            id="engineYear"
            placeholder="Selecione..."
            icon={AiOutlineCalendar}
            enabled={!disabled}
            list={years}
            selected={engines[index].engineYear}
            form={engines}
            setForm={setEngines}
            handleForm={handleChange}
            index={index}
          />
        </span>
      </Row>

      {deleting && (
        <DeleteItem
          itemId={engineId}
          text="Tem certeza que deseja excluir este motor?"
          list={engines}
          setList={setEngines}
          index={index}
          deleting={deleting}
          setDeleting={setDeleting}
          action={deleteEngine}
        />
      )}
    </Container>
  );
}
