import dayjs from "dayjs";
import { useState } from "react";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { ProductComment } from "../ProductPicker/styles";

import { Info, Container } from "./styles";
import {
  Divider,
  FormsWrapper,
  Row,
} from "../../../../pages/Home/Services/styles";
import theme from "../../../../styles/theme";
import serviceUtils from "../../../../utils/service-utils";

export default function RequestInfo({ info, options }) {
  const certifications = info.products.filter(
    (product) => product.activityId === options.certificationOptions.activityId
  );
  const projects = info.products.filter(
    (product) => product.activityId === options.projectOptions.activityId
  );

  return (
    <FormsWrapper>
      <Container theme={theme}>
        <h3>
          {info.status.statusId >= serviceUtils.status.ANALISE_INTERNA_EXECUTADA
            ? "Detalhes da Análise Interna"
            : "Detalhes da Solicitação"}
        </h3>
        <Row>
          <Info theme={theme}>
            <h5>Código da Solicitação: </h5>
            <p>{info.id}</p>
          </Info>
        </Row>

        <Row>
          <Info theme={theme}>
            <h5>Status da Solicitação: </h5>
            <p>{info.status.statusName}</p>
          </Info>
        </Row>

        <Row>
          <Info theme={theme}>
            <h5>
              {info.status.statusId === serviceUtils.status.SOLICITADO
                ? "Solicitante:"
                : "Responável:"}
            </h5>
            <p>{info.user.userName}</p>
          </Info>
        </Row>

        <Row>
          <Info theme={theme}>
            <h5>
              {info.status.statusId === serviceUtils.status.SOLICITADO
                ? "Data da Solicitação:"
                : "Última Atualização:"}
            </h5>
            <p>
              {dayjs(info.createdAt)
                .locale("pt-br")
                .format("DD/MM/YYYY - HH:mm")}
            </p>
          </Info>
        </Row>

        <Row>
          <Info theme={theme}>
            <h5>Cliente/Empresa: </h5>
            <p>{info.company.label}</p>
          </Info>
        </Row>

        <Row>
          <Info theme={theme}>
            <h5>Embarcação: </h5>
            <p>{info.vessel.label}</p>
          </Info>
        </Row>

        <Row>
          <Info theme={theme}>
            <h5>Observações: </h5>
            <p>{info.obs}</p>
          </Info>
        </Row>
      </Container>

      <Divider theme={theme} />

      <Container theme={theme}>
        <h3>{`Serviços ${
          info.status.statusId > serviceUtils.status.SOLICITADO
            ? "Autorizados"
            : "Solicitados"
        }`}</h3>

        {certifications.length === 0 && projects.length === 0 ? (
          <h6>
            Análise interna em andamento. Após a conclusão, os serviços
            autorizados serão listados aqui.
          </h6>
        ) : (
          <>
            {certifications.length > 0 && (
              <>
                <h4>Certificações: </h4>

                {certifications.map((product, index) => (
                  <ProductInfo key={index} product={product} />
                ))}
              </>
            )}

            {projects.length > 0 && (
              <>
                <h4>Projetos: </h4>

                {projects.map((product, index) => (
                  <ProductInfo key={index} product={product} />
                ))}
              </>
            )}
          </>
        )}
      </Container>
    </FormsWrapper>
  );
}

function ProductInfo({ product }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Row>
        {open ? (
          <BiSolidUpArrow onClick={() => setOpen(!open)} />
        ) : (
          <BiSolidDownArrow onClick={() => setOpen(!open)} />
        )}
        <Info theme={theme} className="product">
          <h5>{`${product.productName}: `}</h5>
          <p>{product.report.reportName || "Relatório não informado"}</p>
        </Info>
      </Row>

      {open && (
        <ProductComment
          theme={theme}
          type="text"
          placeholder="Nenhuma observação..."
          value={product.obs}
          onChange={() => {
            return;
          }}
          disabled
        />
      )}
    </>
  );
}
