import useAsync from "../../useAsync";

import * as authenticationApi from "../../../services/authenticationApi";

export default function useSignUp() {
  const {
    loading: signUpLoading,
    error: signUpError,
    act: signUp,
  } = useAsync((data) => authenticationApi.signUp(data), false);

  return {
    signUpLoading,
    signUpError,
    signUp,
  };
}
