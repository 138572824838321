import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  width: inherit;
  overflow: hidden;
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  position: absolute;
  left: 0;
  top: 0;

  label {
    width: 100%;
    font-size: 18px;
    line-height: 32px;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
