import useAsync from "../../useAsync";

import * as requestApi from "../../../services/requestApi";
import useToken from "../../useToken";

export default function useRequests() {
  const token = useToken();

  const {
    data: requests,
    loading: requestsLoading,
    error: requestsError,
    act: getRequests,
  } = useAsync((data) => requestApi.getRequests(data, token), false);

  return {
    requests,
    requestsLoading,
    requestsError,
    getRequests,
  };
}
