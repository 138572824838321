import api from "./api";

export async function getInspectors({ skip, take }, token) {
  const response = await api.get(
    `/inspection/inspector/many?skip=${skip}&take=${take}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getInspectorData(userId, token) {
  const response = await api.get(`/inspection/inspector/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspectionRequests(token) {
  const response = await api.get("/inspection/requests", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspections({ order, statusId, skip, take }, token) {
  const response = await api.get(
    `/inspection/many/${statusId}?order=${order}&skip=${skip}&take=${take}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function upsertQualifications(body, token) {
  const response = await api.post("/inspection/qualifications", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspectionData(inspectionId, token) {
  const response = await api.get(`/inspection/${inspectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function scheduleInspection(body, token) {
  const response = await api.post("/inspection/schedule", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspectionHistory(inspectionId, token) {
  const response = await api.get(`/inspection/history/${inspectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspectorInspections(inspectorId, token) {
  const response = await api.get(
    `/inspection/inspector/calendar/${inspectorId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getInspectionFilters(token) {
  const response = await api.get("/inspection/filters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspectionDetails(det, token) {
  const response = await api.get(`/inspection/details?det=${det}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteInspectionDetails({ inspectionId, det }, token) {
  const response = await api.delete(
    `/inspection/details/${inspectionId}?det=${det}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getInspectionDetail({ inspectionId, formId }, token) {
  const response = await api.get(
    `/inspection/detail?inspectionId=${inspectionId}&formId=${formId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function fillChecklist(body, token) {
  const response = await api.post("/report/checklist/fill", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspectionsByResponsibles(token) {
  const response = await api.get("/inspection/by/responsibles/many", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
