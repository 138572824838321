import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useDailyReports() {
  const token = useToken();

  const {
    data: dailyReports,
    loading: dailyReportsLoading,
    error: dailyReportsError,
    act: getDailyReports,
  } = useAsync((data) => reportApi.getDailyReports(data, token), false);

  return {
    dailyReports,
    dailyReportsLoading,
    dailyReportsError,
    getDailyReports,
  };
}
