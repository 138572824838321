import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useDailyReport() {
  const token = useToken();

  const {
    data: dailyReport,
    loading: dailyReportLoading,
    error: dailyReportError,
    act: getDailyReport,
  } = useAsync((data) => reportApi.getDailyReport(data, token), false);

  return {
    dailyReport,
    dailyReportLoading,
    dailyReportError,
    getDailyReport,
  };
}
