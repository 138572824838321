import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useSaveQualifications() {
  const token = useToken();

  const {
    loading: saveQualificationsLoading,
    error: saveQualificationsError,
    act: saveQualifications,
  } = useAsync(
    (data) => inspectionApi.upsertQualifications(data, token),
    false
  );

  return {
    saveQualificationsLoading,
    saveQualificationsError,
    saveQualifications,
  };
}
