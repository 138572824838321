import ServiceList from "./ServiceList";
import { Section } from "../../../components/Section";

export default function ServicesUnderReview() {
  const types = "1,2";
  const allowed = false;

  return (
    <Section title="Serviços/Análise Interna">
      <ServiceList allowed={allowed} types={types} />
    </Section>
  );
}
