import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

import { Background } from "../styles";

import theme from "../../../styles/theme";
import LoadingMessage from "../../../components/forms/authentication-form/LoadingMessage";
import useActivateAccount from "../../../hooks/api/authentication/useActivateAccount";
import toastUtils from "../../../utils/toast-utils";

export default function AccountActivation() {
  const navigate = useNavigate();
  const location = useLocation();
  const activationKey = new URLSearchParams(location.search).get("key");
  const userId = new URLSearchParams(location.search).get("userId");
  const { activateAccount, activateAccountLoading } = useActivateAccount();

  useEffect(() => {
    async function getActivation() {
      try {
        await activateAccount({ userId, key: activationKey });

        toastUtils.toaster({
          message: "Conta ativada com sucesso!",
          type: toastUtils.type.success,
          position: toastUtils.position.topLeft,
          theme: toastUtils.theme.colored,
        });

        navigate("/");
      } catch (error) {
        if (error.response.status === 409) navigate("/");
        else navigate(`/sign-up/confirmacao/${userId}`);

        toastUtils.toaster({
          message: error.response.data,
          type: toastUtils.type.error,
          position: toastUtils.position.topLeft,
          theme: toastUtils.theme.colored,
        });
      }
    }

    getActivation();
  }, []);

  return (
    <>
      <LoadingMessage
        loading={activateAccountLoading}
        message="Validando e-mail"
      />

      <Background theme={theme} />
    </>
  );
}
