import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionDetails() {
  const token = useToken();

  const {
    data: inspectionDetails,
    loading: inspectionDetailsLoading,
    error: inspectionDetailsError,
    act: getInspectionDetails,
  } = useAsync(
    (data) => inspectionApi.getInspectionDetails(data, token),
    false
  );

  return {
    inspectionDetails,
    inspectionDetailsLoading,
    inspectionDetailsError,
    getInspectionDetails,
  };
}
