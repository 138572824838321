import useAsync from "../../useAsync";

import * as vesselApi from "../../../services/vesselApi";
import useToken from "../../useToken";

export default function useSaveVessel() {
  const token = useToken();

  const {
    loading: saveVesselLoading,
    error: saveVesselError,
    act: saveVessel,
  } = useAsync((data) => vesselApi.postVessel(data, token), false);

  return {
    saveVesselLoading,
    saveVesselError,
    saveVessel,
  };
}
