import api from "./api";

export async function signUp({ name, email, password, confirmPassword }) {
  const response = await api.post("/auth/sign-up", {
    name,
    email,
    password,
    confirmPassword,
  });
  return response.data;
}

export async function signIn({ email, password }) {
  const response = await api.post("/auth/sign-in", {
    email,
    password,
  });
  return response.data;
}

export async function signOut(userId) {
  const response = await api.put(`/auth/sign-out/${userId}`);
  return response.data;
}

export async function changeEmail({ userId, email }) {
  const response = await api.put(`/auth/change-email/${userId}`, {
    email,
  });
  return response.data;
}

export async function sendConfirmationEmail(userId) {
  const response = await api.get(`/auth/confirm-email/${userId}`);
  return response.data;
}

export async function activateAccount({ userId, key }) {
  const response = await api.put(`/auth/activate-account/${userId}`, {
    key,
  });
  return response.data;
}

export async function sendPasswordChangeEmail(email) {
  const response = await api.get(`/auth/change-password?email=${email}`);
  return response.data;
}

export async function changePasssword({
  userId,
  key,
  password,
  confirmPassword,
}) {
  const response = await api.put(`/auth/change-password/${userId}`, {
    key,
    password,
    confirmPassword,
  });
  return response.data;
}

/* export async function updateUser(body, userId, token) {
  const response = await api.put(`/auth/user/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getUserData(userId, token) {
  const response = await api.get(`/auth/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
} */
