import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Section } from "../../../components/Section";
import generalUtils from "../../../utils/general-utils";
import { Container } from "./styles";
import Directories from "../../../components/Directories";
import theme from "../../../styles/theme";

export default function Inspections() {
  const search = useLocation().search.replace("?search=", "");
  const directories = [
    { text: "VISTORIADORES", route: "vistoriadores" },
    { text: "AGENDADAS", route: "agendadas" },
    { text: "REALIZADAS", route: "realizadas" },
  ];
  const [dirList, setDirList] = useState(directories);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  return (
    <Section title="Vistorias">
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
