import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspections() {
  const token = useToken();

  const {
    data: inspections,
    loading: inspectionsLoading,
    error: inspectionsError,
    act: getInspections,
  } = useAsync((data) => inspectionApi.getInspections(data, token), false);

  return {
    inspections,
    inspectionsLoading,
    inspectionsError,
    getInspections,
  };
}
