import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  color: ${({ theme }) => theme.COLORS.GRAY_300};
  margin-bottom: 14px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  position: absolute;
  left: 0;
  bottom: 0;

  input {
    height: 56px;
    width: 100%;
    text-overflow: ellipsis;

    padding: 12px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_100 : theme.COLORS.WHITE};
    background: transparent;
    border: 0;
    font-size: 18px;

    &:placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_300};
    }

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &:autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 10px 10px 0;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 10px 10px 0;
    }
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  svg {
    margin-left: 16px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
  }

  .react-datepicker {
    width: ${({ type }) => (type === "datetime" ? "327.3px" : "241px")};
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  }

  .react-datepicker__time-list-item {
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    border-right: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  }

  .react-datepicker__time-list {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    width: 9px;
    top: 4px;
    border-color: ${({ theme }) => theme.COLORS.WHITE};
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.COLORS.ORANGE} !important;
    color: ${({ theme }) => theme.COLORS.WHITE} !important;
  }

  .react-datepicker__day,
  .react-datepicker__time-list-item,
  .react-datepicker__time-container,
  .react-datepicker__time .react-datepicker__time-box,
  .ul.react-datepicker__time-list {
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};

    &:hover {
      color: ${({ theme }) => theme.COLORS.BACKGROUND_900} !important;
      background-color: ${({ theme }) => theme.COLORS.GRAY_100} !important;
    }
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day--disabled:hover {
    color: ${({ theme }) => theme.COLORS.GRAY_200} !important;
    background-color: transparent !important;
  }

  .react-datepicker-time__caption {
    font-size: 16px;
    font-weight: 700;
  }

  .react-datepicker__triangle {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .react-datepicker-time__input,
  .react-datepicker__year-option,
  .react-datepicker__navigation--years {
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  }

  .react-datepicker__navigation--years-upcoming {
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .react-datepicker__navigation--years-previous {
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .react-datepicker__year-option:hover {
    .react-datepicker__navigation--years-upcoming {
      border-bottom: 10px solid ${({ theme }) => theme.COLORS.BACKGROUND_900};
    }

    .react-datepicker__navigation--years-previous {
      border-top: 10px solid ${({ theme }) => theme.COLORS.BACKGROUND_900};
    }
  }

  .react-datepicker__year-dropdown-container,
  .react-datepicker__month-read-view {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;

    input {
      height: 46px;
      padding: 12px;
      font-size: 14px;
    }

    svg {
      margin-left: 12px;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }
  }
`;
