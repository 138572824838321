import InfiniteScroll from "react-infinite-scroller";
import { TailSpin } from "react-loader-spinner";

import { ScrollLoader } from "./styles";
import theme from "../../styles/theme";

export default function ListWrapper({
  children,
  offset,
  list,
  loadList,
  loading,
}) {
  return (
    <InfiniteScroll
      className="infinite-scroll"
      pageStart={0}
      hasMore
      initialLoad={false}
      loader={
        <ScrollLoader key={0} rendered={loading}>
          <TailSpin
            color={theme.COLORS.GRAY_100}
            animationDuration="0.75"
            visible
          />
        </ScrollLoader>
      }
      loadMore={async () => {
        if (list.length % offset !== 0) return;
        loadList();
      }}
    >
      {children}
    </InfiniteScroll>
  );
}
