import { Calendar, dayjsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useEffect, useState, useRef } from "react";
import { BiFilter } from "react-icons/bi";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Section } from "../../../components/Section";
import {
  Container,
  EventWrapper,
  LoaderWrapper,
  ParamsWrapper,
} from "./styles";
import theme from "../../../styles/theme";
import useEvents from "../../../hooks/api/calendar/useEvents";
import useSaveEvent from "../../../hooks/api/calendar/useSaveEvent";
import useUsersData from "../../../hooks/api/user/useUsersData";
import toastUtils from "../../../utils/toast-utils";
import calendarUtils from "../../../utils/calendar-utils";
import Select from "../../../components/forms/calendar-form/Select";
import enrollmentUtils from "../../../utils/enrollment-utils";
import FilterSelector from "../../../components/FilterSelector";
import EventInfo from "../../../components/forms/calendar-form/EventInfo";
import ConfirmationMessage from "../../../components/ConfirmationMessage";
import useDeleteEvent from "../../../hooks/api/calendar/useDeleteEvent";
import Loader from "../../../components/Loader";

export default function CalendarPage() {
  const localizer = dayjsLocalizer(dayjs);
  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const { getEvents, eventsLoading } = useEvents();
  const { getUsersData } = useUsersData();
  const { saveEvent } = useSaveEvent();
  const { deleteEvent } = useDeleteEvent();
  const [eventList, setEventList] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("month");
  const [displayParams, setDisplayParams] = useState(
    calendarUtils.calendarEmptyDisplayParams
  );
  const [displayFilters, setDisplayFilters] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [guestList, setGuestList] = useState([]);
  const [dropping, setDropping] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const prevYearRef = useRef(null);
  const prevViewRef = useRef(null);

  dayjs.locale("pt-br");

  useEffect(() => {
    loadGuestList();
  }, []);

  useEffect(() => {
    loadEvents();
  }, [displayParams]);

  useEffect(() => {
    handleYearChange();
  }, [currentDate]);

  async function loadEvents() {
    const currentYear = currentDate.getFullYear();

    try {
      const response = await getEvents({
        currentYear,
        state:
          displayParams.state.value === "N/I" ? "" : displayParams.state.value,
        type: displayParams.type.value,
      });

      if (response) {
        setEventList(
          response.map((event) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
          }))
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadGuestList() {
    const params = {
      order: "NOME",
      skip: 0,
      take: 1000,
    };

    try {
      const response = await getUsersData(params);

      if (response) setGuestList(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleYearChange() {
    const currentYear = currentDate.getFullYear();

    if (prevYearRef.current !== currentYear) {
      loadEvents();

      prevYearRef.current = currentYear;
    }
  }

  function handleSelectEvent(event) {
    prevViewRef.current = currentView;

    setSelectedEvent(event);
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleEventDrop({ event, start, end }) {
    handleSubmit({ ...event, start, end });

    setDropping(false);
  }

  function handleSelectSlot({ start, end }) {
    setSelectedEvent({
      id: 0,
      table: "EVENTO",
      tableId: 0,
      title: "",
      start,
      end,
      color: "GRAY_300",
      type: "generic",
      allDay: false,
      location: "",
      obs: "",
      guests: [],
    });
  }

  async function handleSubmit(event) {
    const body = {
      id: event.tableId,
      title: event.title,
      start: event.start,
      end: event.allDay ? event.start : event.end,
      allDay: event.allDay,
      location: event.location,
      obs: event.obs,
      guests: event.guests.map((guest) => guest.id),
    };

    try {
      await saveEvent(body);

      setSelectedEvent(null);
      loadEvents();

      toastUtils.toaster({
        message: "Evento salvo com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleDeleteEvent(event) {
    try {
      await deleteEvent(event.tableId);

      toastUtils.toaster({
        message: "Evento excluído com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      setSelectedEvent(null);
      setDeleting(false);
      loadEvents();
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  const eventStyle = (event) => ({
    style: {
      backgroundColor: theme.COLORS[event.color],
    },
  });

  const messages = {
    allDay: "Dia inteiro",
    previous: "<",
    next: ">",
    today: "Hoje",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    agenda: "Agenda",
    date: "Data",
    time: "Hora",
    event: "Evento",
    noEventsInRange: "Não há eventos agendados nestes dias.",
  };

  localizer.formats.monthHeaderFormat = (date, culture, localizer) => {
    const monthName = localizer.format(date, "MMMM", culture);
    const year = localizer.format(date, "YYYY", culture);
    return `${
      monthName.charAt(0).toUpperCase() + monthName.slice(1)
    } de ${year}`;
  };

  localizer.formats.dayRangeHeaderFormat = (
    { start, end },
    culture,
    localizer
  ) => {
    let startMonth = localizer.format(start, "MMM", culture);
    let endMonth = localizer.format(end, "MMM", culture);
    const startYear = localizer.format(start, "YYYY", culture);
    const endYear = localizer.format(end, "YYYY", culture);

    let header = "";

    if (startYear !== endYear) {
      header = `${
        startMonth.charAt(0).toUpperCase() + startMonth.slice(1)
      }. ${startYear} - ${
        endMonth.charAt(0).toUpperCase() + endMonth.slice(1)
      }. ${endYear}`;
    } else if (startMonth !== endMonth) {
      header = `${
        localizer.format(start, "MMM", culture).charAt(0).toUpperCase() +
        startMonth.slice(1)
      }. - ${
        localizer.format(end, "MMM", culture).charAt(0).toUpperCase() +
        endMonth.slice(1)
      }. ${startYear}`;
    } else {
      startMonth = localizer.format(start, "MMMM", culture);
      endMonth = localizer.format(end, "MMMM", culture);
      header = `${
        startMonth.charAt(0).toUpperCase() + startMonth.slice(1)
      } de ${startYear}`;
    }
    return header;
  };

  localizer.formats.dayHeaderFormat = (date, culture, localizer) => {
    const day = localizer.format(date, "dddd", culture);
    const monthName = localizer.format(date, "MMMM", culture);
    const year = localizer.format(date, "YYYY", culture);
    return `${day.charAt(0).toUpperCase() + day.slice(1)}, ${localizer.format(
      date,
      "D",
      culture
    )} de ${monthName.charAt(0).toUpperCase() + monthName.slice(1)} de ${year}`;
  };

  return (
    <Section title="Agenda">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="type"
            icon={BiFilter}
            placeholder={"Tipo"}
            enabled={true}
            list={calendarUtils.eventFilters}
            selected={displayParams.type}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="state"
            icon={BiFilter}
            placeholder={"Estado"}
            enabled={true}
            list={enrollmentUtils.states}
            selected={displayParams.state}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>
        <DragAndDropCalendar
          localizer={localizer}
          defaultDate={currentDate}
          defaultView={currentView}
          events={eventList}
          startAccessor="start"
          endAccessor="end"
          messages={messages}
          eventPropGetter={eventStyle}
          components={{
            month: { event: EventComponent },
            week: { event: EventComponent },
          }}
          views={["month", "week", "day", "agenda"]}
          draggableAccessor={(event) => event.type === "generic"}
          selectable
          onNavigate={(date) => setCurrentDate(date)}
          onView={(view) => setCurrentView(view)}
          onEventDrop={(data) => setDropping(data)}
          onEventResize={(data) => setDropping(data)}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
        />

        {eventsLoading && (
          <LoaderWrapper theme={theme}>
            <Loader theme={theme} className="absolute" />
          </LoaderWrapper>
        )}

        {selectedEvent && (
          <EventInfo
            event={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            guestList={guestList}
            handleSubmit={handleSubmit}
            setDeleting={setDeleting}
          />
        )}

        {dropping !== false && (
          <ConfirmationMessage
            text="Tem certeza que deseja alterar este evento?"
            action={handleEventDrop}
            params={dropping}
            confirming={dropping}
            setConfirming={setDropping}
          />
        )}

        {deleting !== false && (
          <ConfirmationMessage
            text="Tem certeza que deseja excluir este evento?"
            action={handleDeleteEvent}
            params={deleting}
            confirming={deleting}
            setConfirming={setDeleting}
          />
        )}
      </Container>
    </Section>
  );
}

function EventComponent({ event }) {
  return (
    <EventWrapper theme={theme}>
      <div className="title">{event.title}</div>
      {!event.allDay && (
        <div className="time">
          {dayjs(event.start).format("HH:mm")} -{" "}
          {dayjs(event.end).format("HH:mm")}
        </div>
      )}
    </EventWrapper>
  );
}
