import { useParams } from "react-router-dom";

import { Section } from "../../../components/Section";
import Companies from "./Company/Companies";

export default function Suppliers() {
  const { typeId } = useParams();

  return (
    <Section title="Cadastro/Fornecedores">
      <Companies typeId={typeId} />
    </Section>
  );
}
