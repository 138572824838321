function clearDuplicates(list) {
  const readIds = {};

  return list.filter((product) => {
    if (readIds[product.productId]) {
      return false;
    } else {
      readIds[product.productId] = true;
      return true;
    }
  });
}

const requestUtils = {
  clearDuplicates,
};

export default requestUtils;
