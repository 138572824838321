import styled from "styled-components";

export const Container = styled.div`
  display: ${({ addingType }) => (addingType ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 15;
  background-color: ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT_100};
  align-items: center;
  justify-content: center;

  input[type="submit"] {
    display: flex !important;
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  form {
    width: 100%;
    margin: 0;
  }

  textarea {
    height: 150px;
    width: 100%;

    padding: 12px;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    border: 0;
    font-size: 18px;
    border-radius: 10px;
    resize: none;

    &:placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_300};
    }

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &:autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 10px 10px 0;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 10px 10px 0;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  padding-left: 16px;
  margin: auto;
  padding: 30px;
  background-color: ${({ theme }) => theme.COLORS.GRAY};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  position: relative;
`;

export const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: transparent;
  border: 0;
  top: 10px;
  right: 10px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  svg:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;
