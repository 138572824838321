import { IoCloseCircle } from "react-icons/io5";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useState } from "react";

import {
  AddButton,
  CloseButton,
  Container,
  DeletePicture,
  FormatWarning,
  PictureList,
  PicturePreview,
  PictureWrapper,
  Wrapper,
} from "./styles";
import theme from "../../../../styles/theme";
import Button from "../Button";
import toastUtils from "../../../../utils/toast-utils";

export default function AddPicture({
  answers,
  setAnswers,
  addingPhoto,
  setAddingPhoto,
  id,
  enabled,
}) {
  const [pictures, setPictures] = useState([...answers[id].pictures]);
  const [preview, setPreview] = useState(null);

  function handleFileChange(e) {
    Array.from(e.target.files).forEach((file) => {
      if (file) {
        const fileType = file.type;

        if (!fileType.startsWith("image/")) {
          return toastUtils.toaster({
            message: `O arquivo "${file.name}" não foi selecionado, pois não é uma imagem.`,
            type: toastUtils.type.warning,
            position: toastUtils.position.topCenter,
            theme: toastUtils.theme.colored,
          });
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          setPictures((prevPictures) => [
            ...prevPictures,
            { id: 0, file: reader.result },
          ]);
        };
        reader.readAsDataURL(file);
      }
    });
  }

  function deletePicture(index) {
    const newPictures = [
      ...pictures.slice(0, index),
      ...pictures.slice(index + 1),
    ];

    setPictures(newPictures);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: {
        ...prevAnswers[id],
        pictures,
      },
    }));

    setAddingPhoto(false);
  }

  function handleCancel() {
    setPictures(answers[id].pictures);
    setAddingPhoto(false);
  }

  return (
    <Container theme={theme} addingPhoto={addingPhoto}>
      <Wrapper theme={theme}>
        <CloseButton theme={theme} onClick={handleCancel} type="button">
          <IoCloseCircle />
        </CloseButton>

        <h5>
          {enabled
            ? "Carregar imagens do dispositivo..."
            : "Evidência(s) da Vistoria"}
        </h5>

        {enabled && (
          <FormatWarning theme={theme}>
            Formatos permitidos: jpeg, jpg, heic e png.
          </FormatWarning>
        )}

        <PictureList theme={theme}>
          {pictures.map((picture, index) => (
            <Picture
              key={index}
              id={picture.id}
              source={picture.file}
              index={index}
              deletePicture={deletePicture}
              setPreview={setPreview}
              enabled={enabled}
            />
          ))}

          {enabled && (
            <AddButton htmlFor="add-button" theme={theme}>
              <FaPlus />
              <input
                id="add-button"
                type="file"
                name={id}
                accept="image/jpeg,image/jpg,image/heic,image/png"
                multiple
                onChange={handleFileChange}
                disabled={!enabled}
              />
            </AddButton>
          )}
        </PictureList>

        {enabled && (
          <span>
            <Button
              className="cancel"
              title="Cancelar"
              type="button"
              onClick={handleCancel}
            />
            <Button
              className="confirm"
              title="Confirmar"
              editing={true}
              type="button"
              onClick={handleSubmit}
            />
          </span>
        )}
      </Wrapper>

      {preview && <Preview picture={preview} setPreview={setPreview} />}
    </Container>
  );
}

function Picture({ source, index, deletePicture, setPreview, enabled }) {
  return (
    <PictureWrapper theme={theme}>
      <img
        src={source}
        alt="Evidência de vistoria"
        onClick={() => setPreview(source)}
      />
      {enabled && (
        <DeletePicture
          type="button"
          disabled={!enabled}
          theme={theme}
          onClick={() => deletePicture(index)}
        >
          <FaMinus />
        </DeletePicture>
      )}
    </PictureWrapper>
  );
}

function Preview({ picture, setPreview }) {
  return (
    <PicturePreview>
      <div>
        <CloseButton
          theme={theme}
          onClick={() => setPreview(null)}
          type="button"
        >
          <IoCloseCircle />
        </CloseButton>

        <img src={picture} alt="Evidência de vistoria" />
      </div>
    </PicturePreview>
  );
}
