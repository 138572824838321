import useAsync from "../../useAsync";

import * as companyApi from "../../../services/companyApi";
import useToken from "../../useToken";

export default function useCompany() {
  const token = useToken();

  const {
    data: company,
    loading: companyLoading,
    error: companyError,
    act: getCompany,
  } = useAsync((data) => companyApi.getCompanyData(data, token), false);

  return {
    company,
    companyLoading,
    companyError,
    getCompany,
  };
}
