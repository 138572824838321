import useAsync from "../../useAsync";

import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionRequests() {
  const token = useToken();

  const {
    data: inspectionRequests,
    loading: inspectionRequestsLoading,
    error: inspectionRequestsError,
    act: getInspectionRequests,
  } = useAsync(() => inspectionApi.getInspectionRequests(token), false);

  return {
    inspectionRequests,
    inspectionRequestsLoading,
    inspectionRequestsError,
    getInspectionRequests,
  };
}
