import useAsync from "../../useAsync";

import * as vesselApi from "../../../services/vesselApi";
import useToken from "../../useToken";

export default function useVesselFilters() {
  const token = useToken();

  const {
    data: vesselFilters,
    loading: vesselFiltersLoading,
    error: vesselFiltersError,
    act: getVesselFilters,
  } = useAsync(() => vesselApi.getVesselFilters(token), false);

  return {
    vesselFilters,
    vesselFiltersLoading,
    vesselFiltersError,
    getVesselFilters,
  };
}
