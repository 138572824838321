import useAsync from "../../useAsync";

import * as companyApi from "../../../services/companyApi";
import useToken from "../../useToken";

export default function useDeleteContact() {
  const token = useToken();

  const {
    loading: deleteContactLoading,
    error: deleteContactError,
    act: deleteContact,
  } = useAsync((data) => companyApi.deleteContact(data, token), false);

  return {
    deleteContactLoading,
    deleteContactError,
    deleteContact,
  };
}
