import { BsPlusCircleFill } from "react-icons/bs";
import { IoChevronForward } from "react-icons/io5";
import { CgReorder } from "react-icons/cg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import theme from "../../../../styles/theme";
import { AddListItem, Container, ListItem } from "../../styles";
import useCompanies from "../../../../hooks/api/company/useCompanies";
import toastUtils from "../../../../utils/toast-utils";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import ListWrapper from "../../../../components/ListWrapper";
import useCompanyTypes from "../../../../hooks/api/company/useCompanyTypes";
import generalUtils from "../../../../utils/general-utils";
import Select from "../../../../components/forms/enrollment-form/Select";
import { ParamsWrapper } from "../styles";

export default function Companies({ typeId }) {
  const navigate = useNavigate();
  const responseLength = 20;
  const { getCompanies, companiesLoading } = useCompanies();
  const { getCompanyTypes } = useCompanyTypes();
  const [companyTypes, setCompanyTypes] = useState({});
  const [companies, setCompanies] = useState([]);
  const [order, setOrder] = useState({ order: { label: "", value: 0 } });
  const [filteredList, setFilteredList] = useState([]);
  const search = useLocation().search.replace("?search=", "");

  useEffect(() => {
    loadCompanies();
    loadCompanyTypes();
  }, []);

  useEffect(() => {
    orderCompanies();
  }, [order]);

  useEffect(() => {
    handleCompanySearch();
  }, [search, companies]);

  function handleCompanySearch() {
    if (!search) return setFilteredList(companies);
    const newList = companies.filter(
      (company) =>
        company.tradeName.toLowerCase().includes(search.toLowerCase()) ||
        company.legalName.toLowerCase().includes(search.toLowerCase()) ||
        company.cnpj.includes(search)
    );
    setFilteredList(newList);
  }

  async function loadCompanies() {
    const skip = companies.length;
    const take = responseLength;

    try {
      const response = await getCompanies({
        order: order.order.value ? order.order.value : "",
        skip,
        take,
        typeId,
      });

      if (response) {
        setCompanies(companies.concat(response));
        setFilteredList(companies.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadCompanyTypes() {
    try {
      const response = await getCompanyTypes();

      if (response) setCompanyTypes(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderCompanies() {
    const skip = 0;
    const take = responseLength;

    try {
      const response = await getCompanies({
        order: order.order.value ? order.order.value : "",
        skip,
        take,
        typeId,
      });

      if (response) {
        setCompanies(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleRoute() {
    let route = "painel/cadastro/";

    const companyType = generalUtils.removeSpecialChar(
      generalUtils.findKeyById(companyTypes, Number(typeId))
    );

    route += companyType;

    return route;
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  return (
    <Container theme={theme}>
      <ParamsWrapper>
        <Select
          id="order"
          icon={CgReorder}
          placeholder={"Ordenar por..."}
          enabled={true}
          list={enrollmentUtils.companyOrderList}
          selected={order.order}
          form={order}
          setForm={setOrder}
          handleForm={handleForm}
        />
      </ParamsWrapper>

      <AddListItem
        theme={theme}
        onClick={() => navigate(`/${handleRoute()}/novo?typeId=${typeId}`)}
      >
        <BsPlusCircleFill />
        <h5>Adicionar Empresa</h5>
      </AddListItem>

      <ListWrapper
        offset={responseLength}
        list={companies}
        loadList={loadCompanies}
        loading={companiesLoading}
      >
        {filteredList.map((company, index) => (
          <CompanyWrapper
            key={index}
            id={company.id}
            name={company.tradeName}
            handleRoute={handleRoute}
          />
        ))}
      </ListWrapper>
    </Container>
  );
}

function CompanyWrapper({ id, name, handleRoute }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`/${handleRoute()}/${id}`)}>
      <div>
        <h5>{name}</h5>
        <IoChevronForward />
      </div>
    </ListItem>
  );
}
