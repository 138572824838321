import { useContext } from "react";

import UserContext from "../../../contexts/UserContext";
import ServiceList from "./ServiceList";
import { Section } from "../../../components/Section";

export default function RequestedServices() {
  const types = "1";
  const { userData } = useContext(UserContext);
  const allowed =
    userData.department.label === "COMERCIAL" ||
    userData.department.label === "DIRETORIA";

  return (
    <Section title="Serviços/Serviços Solicitados">
      <ServiceList allowed={allowed} types={types} />
    </Section>
  );
}
