import theme from "../../../../styles/theme";
import { BoxWrapper } from "./styles";

export default function CheckBox({
  label,
  form,
  handleCheck,
  id,
  disabled,
  serviceId,
  productId,
  serviceName,
  activityId,
  className,
  obs,
}) {
  const checked = form[id] ? true : false;

  function handleChange() {
    handleCheck({
      name: label,
      value: id,
      productId,
      serviceId,
      serviceName,
      activityId,
      obs: obs || "",
    });
  }

  return (
    <BoxWrapper
      theme={theme}
      checked={checked}
      disabled={disabled}
      className={className}
    >
      <input
        type="checkbox"
        value={checked}
        onChange={handleChange}
        disabled={disabled}
        checked={checked}
      />

      <label>{label}</label>
    </BoxWrapper>
  );
}
