import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectors() {
  const token = useToken();

  const {
    data: inspectors,
    loading: inspectorsLoading,
    error: inspectorsError,
    act: getInspectors,
  } = useAsync((data) => inspectionApi.getInspectors(data, token), false);

  return {
    inspectors,
    inspectorsLoading,
    inspectorsError,
    getInspectors,
  };
}
