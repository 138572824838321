import { BsPlusCircleFill } from "react-icons/bs";
import { IoChevronForward } from "react-icons/io5";
import { BiFilter } from "react-icons/bi";
import { CgReorder } from "react-icons/cg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import theme from "../../../../styles/theme";
import { AddListItem, Container, ListItem } from "../../styles";
import toastUtils from "../../../../utils/toast-utils";
import { ParamsWrapper } from "../styles";
import Select from "../../../../components/forms/enrollment-form/Select";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import Loader from "../../../../components/Loader";
import useCertifications from "../../../../hooks/api/product/useCertifications";
import { Section } from "../../../../components/Section";
import FilterSelector from "../../../../components/FilterSelector";

export default function Companies() {
  const navigate = useNavigate();
  const search = useLocation().search.replace("?search=", "");
  const { getCertifications, certificationsLoading } = useCertifications();
  const [certifications, setCertifications] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(enrollmentUtils.productEmptyFilters);
  const [displayParams, setDisplayParams] = useState(
    enrollmentUtils.productEmptyDisplayParams
  );
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadCertifications();
  }, [displayParams.order]);

  useEffect(() => {
    handleFilter();
    handleCertificationSearch();
  }, [search, certifications, displayParams.service]);

  function handleCertificationSearch() {
    if (!search) return handleFilter();

    const newList = certifications.filter(
      (certification) =>
        certification.name
          .toLowerCase()
          .includes(search.toString().toLowerCase()) ||
        certification.id.toString().includes(search.toString())
    );
    setFilteredList(newList);
  }

  async function loadCertifications() {
    try {
      const response = await getCertifications(
        displayParams.order.value ? displayParams.order.value : ""
      );

      if (response) {
        setCertifications(response.certifications);
        setFilteredList(response.certifications);
        setFilters({
          ...filters,
          services: filters.services.concat(response.services),
        });
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleFilter() {
    let result = certifications;

    if (displayParams.service.value)
      result = result.filter(
        (certification) =>
          certification.serviceId === displayParams.service.value
      );

    setFilteredList(result);
  }

  return (
    <Section title="Cadastro/Produtos/Certificações">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <Select
            id="order"
            icon={CgReorder}
            placeholder={"Ordenar por..."}
            enabled={true}
            list={enrollmentUtils.productOrderList}
            selected={displayParams.order}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
          />

          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="service"
            icon={BiFilter}
            placeholder={"Serviço"}
            enabled={true}
            list={filters.services}
            selected={displayParams.service}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        <AddListItem theme={theme} onClick={() => navigate("novo")}>
          <BsPlusCircleFill />
          <h5>Adicionar Produto</h5>
        </AddListItem>

        {certificationsLoading ? (
          <Loader />
        ) : (
          <>
            {filteredList.map((certification, index) => (
              <CertificationWrapper
                key={index}
                id={certification.id}
                name={certification.name}
              />
            ))}
          </>
        )}
      </Container>
    </Section>
  );
}

function CertificationWrapper({ id, name }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}`)}>
      <div>
        <h5>{`${id} - ${name}`}</h5>
        <IoChevronForward />
      </div>
    </ListItem>
  );
}
