import { FiUser, FiLink } from "react-icons/fi";
import { AiFillMinusCircle } from "react-icons/ai";
import { useState } from "react";

import Input from "../Input";
import Label from "../Label";
import { Divider } from "../../../../pages/Home/Enrollment/Company/styles";
import { Row } from "../../../../pages/Home/Enrollment/styles";
import { Container } from "./styles";
import theme from "../../../../styles/theme";
import DeleteItem from "../DeleteItem";
import useDeleteCredential from "../../../../hooks/api/company/useDeleteCredential";
import PasswordInput from "../../PasswordInput";

export default function RestrictAccess({
  credentialId,
  credentials,
  setCredentials,
  index,
  disabled,
}) {
  const [deleting, setDeleting] = useState(false);
  const { deleteCredential } = useDeleteCredential();

  function handleChange({ name, value, index }) {
    const result = [...credentials];

    result[index] = { ...result[index], [name]: value };

    setCredentials(result);
  }

  return (
    <Container theme={theme}>
      <div className="divider">
        <Divider theme={theme} />
        {!disabled && <AiFillMinusCircle onClick={() => setDeleting(true)} />}
      </div>

      <h3>{`Acesso Restrito ${credentials.length > 1 ? index + 1 : ""}`}</h3>

      <Row className="desktop">
        <span className="link">
          <Label id="link" text="Link de Acesso" />
          <Input
            placeholder="Link de Acesso"
            type="text"
            id="link"
            value={credentials[index].link}
            icon={FiLink}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="username">
          <Label id="username" text="Usuário" />
          <Input
            placeholder="Usuário"
            type="text"
            id="username"
            value={credentials[index].username}
            icon={FiUser}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="password">
          <Label id="password" text="Senha" />
          <PasswordInput
            placeholder="Senha"
            id="password"
            value={credentials[index].password}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      <Row className="mobile">
        <span className="link">
          <Label id="link" text="Link de Acesso" />
          <Input
            placeholder="Link de Acesso"
            type="text"
            id="link"
            value={credentials[index].link}
            icon={FiLink}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      <Row className="mobile">
        <span className="username">
          <Label id="username" text="Usuário" />
          <Input
            placeholder="Usuário"
            type="text"
            id="username"
            value={credentials[index].username}
            icon={FiUser}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>

        <span className="password">
          <Label id="password" text="Senha" />
          <PasswordInput
            placeholder="Senha"
            id="password"
            value={credentials[index].password}
            disabled={disabled}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      <Row>
        <span className="obs">
          <Label id="obs" text="Observação (opcional)" />
          <Input
            id="obs"
            placeholder="Observação"
            type="text"
            disabled={disabled}
            value={credentials[index].obs}
            onChange={(e) =>
              handleChange({
                name: e.target.id,
                value: e.target.value,
                index,
              })
            }
          />
        </span>
      </Row>

      {deleting && (
        <DeleteItem
          itemId={credentialId}
          text="Tem certeza que deseja excluir estas credenciais?"
          list={credentials}
          setList={setCredentials}
          index={index}
          deleting={deleting}
          setDeleting={setDeleting}
          action={deleteCredential}
        />
      )}
    </Container>
  );
}
