import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useDailyReportFilters() {
  const token = useToken();

  const {
    data: dailyReportFilters,
    loading: dailyReportFiltersLoading,
    error: dailyReportFiltersError,
    act: getDailyReportFilters,
  } = useAsync(() => reportApi.getDailyReportFilters(token), false);

  return {
    dailyReportFilters,
    dailyReportFiltersLoading,
    dailyReportFiltersError,
    getDailyReportFilters,
  };
}
