import { FiMail } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { useState } from "react";

import { Background, Container, Form } from "../styles";
import { Input } from "../../../components/forms/authentication-form/Input";
import { Button } from "../../../components/forms/authentication-form/Button";
import theme from "../../../styles/theme";
import useSignIn from "../../../hooks/api/authentication/useSignIn";
import UserContext from "../../../contexts/UserContext";
import toastUtils from "../../../utils/toast-utils";
import useEnrollment from "../../../hooks/api/enrollment/useEnrollment";
import PasswordInput from "../../../components/forms/PasswordInput";
import Title from "../../../components/forms/authentication-form/Title";

export default function SignIn() {
  const { getEnrollment } = useEnrollment();
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);
  const { signIn } = useSignIn();
  const [typingPassword, setTypingPassword] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await signIn(form);

      setUserData(response);

      const enrollment = (await getEnrollment(response.user.id, response.token))
        .enrollment;

      if (enrollment)
        setUserData({
          ...response,
          enrollmentId: enrollment.enrollmentId,
          permission: enrollment.permission,
          department: enrollment.department,
          role: enrollment.role,
          fullName: enrollment.name,
        });

      navigate("/painel/inicio");
    } catch (error) {
      if (error.response.status === 403) {
        const user = error.response.data.user;

        navigate(`/sign-up/confirmacao/${user.id}?email=${user.email}`);

        toastUtils.toaster({
          message: error.response.data.message,
          type: toastUtils.type.error,
          position: toastUtils.position.topLeft,
          theme: toastUtils.theme.colored,
        });
      } else {
        toastUtils.toaster({
          message: error.response.data,
          type: toastUtils.type.error,
          position: toastUtils.position.topLeft,
          theme: toastUtils.theme.colored,
        });

        setForm({ ...form, password: "" });
      }
    }
  }

  return (
    <Container theme={theme}>
      <Form theme={theme} onSubmit={handleSubmit}>
        <Title />

        <h2> Faça seu login </h2>

        <Input
          placeholder="E-mail"
          type="email"
          name="email"
          value={form.email}
          required
          icon={FiMail}
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
        />

        <PasswordInput
          placeholder="Senha"
          name="password"
          value={form.password}
          required
          onFocus={() => setTypingPassword(true)}
          onBlur={() => setTypingPassword(false)}
          onChange={(e) =>
            handleForm({
              name: e.target.name,
              value: e.target.value,
            })
          }
          minLength="8"
          pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
          typingPassword={typingPassword}
          password={form.password}
        />
        <Button title="Entrar" />

        <Link to="/alterar-senha/email">Esqueci minha senha</Link>
        <Link to="/sign-up">Primeiro login? Faça o cadastro aqui</Link>
      </Form>
      <Background theme={theme} />
    </Container>
  );
}
