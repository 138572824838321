import styled from "styled-components";

export const Container = styled.div`
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
  background-color: ${({ theme, isEmpty }) =>
    isEmpty ? theme.COLORS.ORANGE : theme.COLORS.BLUE};
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.COLORS.WHITE};
    margin: 0 !important;
    margin-right: 2px !important;
  }

  @media (max-width: 767px) {
    width: 20px !important;
    height: 20px;

    svg {
      width: 12px;
      height: 12px;
      margin-right: 0 !important;
    }
  }
`;
