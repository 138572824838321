import useAsync from "../../useAsync";

import * as requestApi from "../../../services/requestApi";
import useToken from "../../useToken";

export default function useSaveRequest() {
  const token = useToken();

  const {
    loading: saveRequestLoading,
    error: saveRequestError,
    act: saveRequest,
  } = useAsync((data) => requestApi.postRequest(data, token), false);

  return {
    saveRequestLoading,
    saveRequestError,
    saveRequest,
  };
}
