import useAsync from "../../useAsync";

import * as calendarApi from "../../../services/calendarApi";
import useToken from "../../useToken";

export default function useSaveEvent() {
  const token = useToken();

  const {
    loading: saveEventLoading,
    error: saveEventError,
    act: saveEvent,
  } = useAsync((data) => calendarApi.postEvent(data, token), false);

  return {
    saveEventLoading,
    saveEventError,
    saveEvent,
  };
}
