import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .divider {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
      margin-left: 10px;

      &:hover {
        color: ${({ theme }) => theme.COLORS.WHITE};
      }
    }
  }
`;
