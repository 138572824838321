import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import { Container } from "../styles";

export default function PerformedInspections() {
  return (
    <Section title="Vistorias/Realizadas">
      <Container theme={theme}></Container>
    </Section>
  );
}
