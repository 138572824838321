import styled from "styled-components";

export const Container = styled.div`
  display: ${({ editingEmail }) => (editingEmail ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  width: 550px;
  height: 268px;
  padding-left: 16px;
  margin-bottom: 10px;
  padding: 30px;
  position: absolute;
  left: 55px;
  top: calc(50% - 134px);
  background-color: ${({ theme }) => theme.COLORS.GRAY};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  z-index: 2;

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  form {
    width: 100%;
  }
`;
