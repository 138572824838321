import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;

  h4 {
    font-size: 22px;
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  h5 {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    white-space: nowrap;
  }

  h6 {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  svg {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    cursor: pointer;
    font-size: 14px;
    margin: 10px 0 0 10px;
  }

  input {
    margin-left: 10px;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    svg {
      font-size: 12px;
      margin: 8px 0 0 10px;
    }

    input {
      font-size: 14px;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
      margin: 20px 0;
    }
  }
`;

export const Info = styled.span`
  display: flex !important;
  flex-direction: row !important;
  align-items: baseline;
  justify-content: space-between;
  height: auto !important;
  min-height: 50px;
  white-space: break-spaces !important;
  margin-bottom: 5px;

  p {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 0 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_200};
    max-width: 80% !important;
    display: flex;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    text-align: justify;
  }

  @media (max-width: 767px) {
    min-height: 40px;

    h5 {
      font-size: 14px;
      line-height: 28px;
    }

    p {
      font-size: 14px;
    }
  }
`;
